import { Button, HStack, Input } from '@chakra-ui/react';
import React, { useState } from 'react';

interface DateRangePickerProps {
    start?: Date;
    end?: Date;
    onChange: (start: Date, end: Date) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({ start, end, onChange }) => {
    const [startDate, setStartDate] = useState(start || new Date());
    const [endDate, setEndDate] = useState(end || new Date());

    const onChangeDate = (date: Date, isStart: boolean) => {
        if (isStart) {
            setStartDate(date);
        } else {
            setEndDate(date);
        }
    }

    return (
        <HStack>
            <Input
                size='sm'
                type='date'
                value={startDate.toISOString().split('T')[0]}
                onChange={e => onChangeDate(new Date(e.target.value), true)} />
            { ' - ' }
            <Input
                size='sm'
                type='date'
                value={endDate.toISOString().split('T')[0]}
                onChange={e => onChangeDate(new Date(e.target.value), false)}/>
            <Button variant='black' onClick={() => onChange(startDate, endDate)}>apply</Button>
        </HStack>
    );
};

export default DateRangePicker;