import ViewContainer from '../../components/View/ViewContainer';
import React, { useContext } from 'react';
import { AccountRole } from '../../graphql/generated';
import { AuthContext } from '../../components/Providers/AuthProvider';
import MemberHome from './MemberHome';
import ReviewerHome from './ReviewerHome';
import ApplicantHome from './ApplicantHome';

const Home = () => {
    const { account } = useContext(AuthContext);

    const isSeniorReviewer = account?.role === AccountRole.SENIOR_REVIEWER;
    const isAdmin = account?.role === AccountRole.ADMIN;
    const isStaff = account?.role === AccountRole.STAFF;
    const isApplicant = account?.role === AccountRole.APPLICANT;

    return (
        <ViewContainer maxW={!isApplicant ? '100vw' : '90vw'}>
            { isApplicant && <ApplicantHome /> }
            { isSeniorReviewer && <ReviewerHome /> }
            { (isStaff || isAdmin) && <MemberHome /> }
        </ViewContainer>
    );
}

export default Home;
