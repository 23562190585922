import { AbsoluteCenter, Stack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import ViewContainer from '../../components/View/ViewContainer';
import { useKpisLazyQuery } from '../../graphql/generated';
import Overlay from '../../components/View/Overlay';
import DateRangePicker from '../../components/Input/DatepickerRange';
import Funnel from './Funnel';
import Shepherds from './Shepherds';
import MonthlyDeals from './MonthlyDeals';
import moment from 'moment';

const Kpi = () => {
    const [from, setFrom] = useState(moment().subtract(1, 'year').toDate());
    const [to, setTo] = useState(new Date());
    const [fetchKpi, { data, loading, error }] = useKpisLazyQuery();

    const onChangeDate = (start: Date, end: Date) => {
        setFrom(start);
        setTo(end);
        fetchKpi({
            variables: {
                from,
                to
            }
        });
    }

    useEffect(() => {
        fetchKpi({
            variables: {
                from,
                to
            }
        });
    }, [from, to]);

    return (
        <ViewContainer maxW='100vw'>
            { error &&
                <AbsoluteCenter>
                    <Text color='red'>{ error.message }</Text>
                </AbsoluteCenter>
            }
            <Overlay show={loading && !error} />
            <DateRangePicker start={from} end={to} onChange={onChangeDate} />
            { data &&
                <Stack gap={4}>
                    <MonthlyDeals data={data.kpis.deals} />
                    <Funnel data={data.kpis.funnel} />
                    <Shepherds data={data.kpis.shepherds} />
                </Stack>
            }
        </ViewContainer>
    );
}

export default Kpi;