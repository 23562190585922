import React, { useContext, useEffect } from 'react';
import ViewContainer from '../../components/View/ViewContainer';
import ReviewFormDisclaimer from './ReviewFormDisclaimer';
import SeniorReviewForm from './SeniorReviewForm';
import { useAccountApplicationReviewQuery } from '../../graphql/generated';
import { Divider } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../components/Providers/AuthProvider';
import { PATHS } from '../../config';

const SeniorReview = () => {
    const navigate = useNavigate();
    const { account } = useContext(AuthContext);
    const { applicationId } = useParams();

    useEffect(() => {
        if (account?.role !== 'SENIOR_REVIEWER') {
            navigate(PATHS.HOME)
        }
    }, [account]);

    const { data: previousReview, loading, refetch } = useAccountApplicationReviewQuery({
        variables: {
            applicationId: Number(applicationId)
        }
    });

    useEffect(() => {
        refetch();
    }, [applicationId]);

    return (
        <ViewContainer maxW='980px'>
            <ReviewFormDisclaimer />
            <Divider my={2} />
            { !loading && applicationId &&
                <SeniorReviewForm
                    applicationId={Number(applicationId)}
                    seniorReview={previousReview?.accountApplicationReview}
                    refetch={refetch} />
            }
        </ViewContainer>
    )
}

export default SeniorReview;
