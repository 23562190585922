import React from 'react';
import { FormHelperText } from '@chakra-ui/react';

interface IHelperText {
    children: React.ReactNode;
    [x: string]: any;
}

const HelperText: React.FC<IHelperText> = ({ children, ...props }) => (
    <FormHelperText fontSize='sm' color='gray.500' my={2} {...props}>
        { children }
    </FormHelperText>
);

export default HelperText;
