import { Badge } from '@chakra-ui/react';
import { ApplicationPhase } from '../../graphql/generated';

const ApplicationPhaseBadge = ({ phase }: { phase: ApplicationPhase }) => (
    <Badge
        bg={'applicationPhase.' + phase}
        color='white'
        fontSize='0.8em'
        py={1}
        w='8em'
        textAlign='center'
        userSelect='none'>
        {phase.split('_').join(' ')}
    </Badge>
);

export default ApplicationPhaseBadge;
