import { Icon } from '@chakra-ui/react';
import React from 'react';
import { FaLongArrowAltUp } from 'react-icons/fa';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6';
import { ProjectPriority } from '../../graphql/generated';

const PRIORITY_MAP = {
    HIGH: <Icon color='red' as={FaLongArrowAltUp} size={22} title='High'/>,
    MEDIUM: <Icon color='orange' as={FaArrowUp} size={22} title='Medium' />,
    LOW: <Icon color='green' as={FaArrowDown} size={22} title='Low' />,
}
const PriorityIcon: React.FC<{ priority: ProjectPriority }> = ({ priority }) => {
    return PRIORITY_MAP[priority];
}

export default PriorityIcon;
