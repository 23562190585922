import { IFile } from '../components/Input/FileUploadInput';
import { API_URL } from '../config';

interface IUploadFiles {
    [paths: string]: IFile[]
}

export const uploadFiles = async (files: File[], paths: string[], applicationId: string): Promise<IUploadFiles> => {
    const formData = new FormData();
    files.forEach((file, index) => {
        formData.append('files', file);
        formData.append('paths', paths[index]);
    });
    formData.append('applicationId', applicationId);
    const options = {
        method: 'POST',
        headers: { Accept: 'application/json' },
        body: formData,
    };

    const response = await fetch(`${API_URL}/uploadFiles`, options);
    if (response.ok) {
        const uploadedFiles: IFile[] = await response.json();

        const uploadedFilesByPath: { [path: string]: IFile[] } = {};
        uploadedFiles.forEach(file => {
            const path = paths.find(p => file.url.includes(p));
            if (path) {
                if (!uploadedFilesByPath[path]) {
                    uploadedFilesByPath[path] = [];
                }
                uploadedFilesByPath[path].push(file);
            }
        });
        return uploadedFilesByPath;
    }
    return {};
};