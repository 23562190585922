const formatDealType = (txt: string) => {
    switch (txt) {
        case 'EQUITY_DEAL':
            return 'Equity deal'
        case 'IP_NFT_IPT_ASSET':
            return 'IP-NFT/IPT Asset'
        case 'SPIN_OUT':
            return 'Spinout'
            break;
        case 'OTHER':
            return 'OTHER'
        default:
            return '';
    }
}

export default formatDealType;
