import { HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { ApplicationQuery } from '../graphql/generated';
import React, { useState } from 'react';
import FileList from './FileList';
import { FaFolder } from 'react-icons/fa6';

type Files = NonNullable<ApplicationQuery['application']['dataroom']>['files'];
interface FoldersProps {
    files: Files;
}

const Folders: React.FC<FoldersProps> = ({ files }) => {
    const [activeFolder, setActiveFolder] = useState<string | null>(null);
    const folders: { [key: string]: Files } = files.reduce((acc: { [key: string]: Files }, file) => {
        if (acc[file.path]) {
            acc[file.path].push(file);
        } else {
            acc[file.path] = [file];
        }
        return acc;
    }, {});

    const foldersS = Object
        .keys(folders)
        .map(f =>
            <HStack
                cursor='pointer'
                gap={2}
                key={`folder-${f}`}
                onClick={() => setActiveFolder(f)}
                _hover={{ color: 'blue.500', fontWeight: 'bold' }}>
                <Icon as={FaFolder} />
                <Text>
                    {f}
                </Text>
            </HStack>
        )
    return (
        <Stack textAlign='left'>
            { files.length === 0 &&
                <Text textAlign='center' color='gray'>
                    No files uploaded
                </Text>
            }
            { !activeFolder && foldersS }
            { activeFolder &&
                <>
                    <Text
                        as='a'
                        cursor='pointer'
                        _hover={{ color: 'blue.500', fontWeight: 'bold' }}
                        onClick={() => setActiveFolder(null)}>
                        /...
                    </Text>
                    <FileList files={folders[activeFolder]} isLink />
                </>
            }
        </Stack>
    )
}

export default Folders;
