import React, { createContext, useState } from 'react';
import { Account, AccountRole, PingQuery, usePingQuery } from '../../graphql/generated';
import { PATHS } from '../../config';

interface IAuthContext {
    isLoggedIn: boolean;
    account: Account | null;
    setAuthValues: (account: Account | null, isLoggedIn: boolean) => void;
}

export const AuthContext = createContext<IAuthContext>({
    isLoggedIn: false,
    account: null,
    setAuthValues: () => undefined,
});

const PUBLIC_PATHS = [
    '/apply',
    '/login',
    '/register',
    '/forgot-password',
    '/reset'
]

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [account, setAccount] = useState<any | null>(null);

    usePingQuery({
        skip: PUBLIC_PATHS.includes(window.location.pathname),
        onCompleted(data: PingQuery) {
            if (data && data.ping) {
                setIsLoggedIn(true);
                setAccount(data.ping)
            }
            const path = window.location.pathname;

            if (data.ping === null) {
                if (!PUBLIC_PATHS.includes(path)) {
                    window.location.href = PATHS.LOGIN;
                }
            } else {
                if (!data.ping?.emailConfirmed && path !== PATHS.CONFIRM_EMAIL && path!== PATHS.LOGIN) {
                    window.location.href = PATHS.CONFIRM_EMAIL;
                }
                if (data.ping?.role !== AccountRole.APPLICANT
                    && !data.ping?.isOnboarded
                    && path !== PATHS.ONBOARD
                    && path !== PATHS.CONFIRM_EMAIL
                ) {
                    window.location.href = PATHS.ONBOARD;
                }
                if (path === PATHS.LOGIN || path === PATHS.REGISTER) {
                    window.location.href = PATHS.HOME;
                }
            }
            return;
        }
    });

    const setAuthValues = (account: Account | null, isLoggedIn: boolean) => {
        setAccount(account);
        setIsLoggedIn(isLoggedIn);
        if (!isLoggedIn && window.location.pathname !== PATHS.LOGIN) {
            window.location.href = PATHS.LOGIN;
        }
    }

    return (
        <AuthContext.Provider value={{
            isLoggedIn,
            account,
            setAuthValues,
        }}>
            { children }
        </AuthContext.Provider>
    )
}

export default AuthProvider;
