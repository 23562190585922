import { ResponsiveBar } from '@nivo/bar';
import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { KpisQuery } from '../../graphql/generated';
import moment from 'moment';

const transformDataForLineChart = (data: any[]) => {
    return data.map(deals => {
        return {
            month: moment(deals.date).format('MMM YY'),
            deals: deals.count,
        };
    });
};


const MonthlyDeals = ({ data }: { data:  KpisQuery['kpis']['deals'] }) => {
    return (
        <Box w='100%' h={400} p={3} pt={1} pb={10} borderWidth={1}>
            <Text fontWeight='bold' color='gray.700'>
                Deals (created)
            </Text>
            <ResponsiveBar
                data={transformDataForLineChart(data)}
                layout='vertical'
                groupMode='grouped'
                keys={[
                    'deals',
                ]}
                indexBy='month'
                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'nivo' }}
                defs={[
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: '#38bcb2',
                        size: 4,
                        padding: 1,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: '#eed312',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                    }
                ]}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 45,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: 32,
                    truncateTickAt: 8
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'count',
                    legendPosition: 'middle',
                    legendOffset: -40,
                    truncateTickAt: 0
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                role='deals' />
        </Box>
    )
}

export default MonthlyDeals;
