import {
    Box,
    Button,
    Center,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    useToast
} from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import z from 'zod';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PATHS } from '../../config';
import { useResetPasswordMutation, useValidateResetTokenQuery } from '../../graphql/generated';
import ViewContainer from '../../components/View/ViewContainer';

const ResetPwSchema = z.object({
    password: z.string().min(5),
    repeatPassword: z.string().min(5),
});
type Schema = z.infer<typeof ResetPwSchema>;

const Reset = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const [params] = useSearchParams();
    const [resetPassword, { loading: loadingReset }] = useResetPasswordMutation();
    const { data, error, loading } = useValidateResetTokenQuery({
        variables: {
            token: params.get('token') ?? ''
        },
        skip: !params.get('token'),
    });

    const resetForm = useForm<Schema>({
        mode: 'onChange',
        resolver: zodResolver(ResetPwSchema),
    });
    const { control, formState: { errors }, watch } = resetForm;

    useEffect(() => {
        const token = params.get('token');
        if (!token) {
            navigate(PATHS.LOGIN);
        }
    }, [params]);

    const onResetPassword = async () => {
        const response = await resetPassword({
            variables: {
                token: params.get('token') ?? '',
                newPassword: resetForm.getValues('password')
            }
        });
        if (response.data?.resetPassword) {
            toast({
                title: 'Password reset',
                description: 'Your password has been reset',
                status: 'success',
                duration: 9000,
                position: 'top',
                isClosable: true,
            })
        }
        navigate(PATHS.LOGIN);
    }

    const pw = watch('password');
    const pw2 = watch('repeatPassword');

    return (
        <ViewContainer>
            { !loading && error && (
                <Center mt={40}>
                    <Heading color='red.600'>
                        { error.message }
                    </Heading>
                </Center>
            ) }

            { !loading && !error && data && data.validateResetPasswordToken &&
                <Center flexDirection='column'>
                    <FormProvider {...resetForm}>
                        <FormControl isRequired isInvalid={!!errors?.password}>
                            <FormLabel>New password</FormLabel>
                            <Controller
                                control={control}
                                render={({ field: { onChange, value }}: any) => (
                                    <>
                                        <Input
                                            autoComplete='off'
                                            bg='gray.50'
                                            h={10}
                                            onChange={onChange}
                                            placeholder='Password'
                                            size='sm'
                                            type='password'
                                            value={value} />
                                        <FormErrorMessage>
                                            { errors?.password?.message }
                                        </FormErrorMessage>
                                    </>
                                )}
                                name='password'
                                defaultValue='' />
                        </FormControl>
                        <FormControl isRequired isInvalid={!!errors?.repeatPassword}>
                            <FormLabel>Repeat Password</FormLabel>
                            <Controller
                                control={control}
                                render={({ field: { onChange, value }}: any) => (
                                    <>
                                        <Input
                                            autoComplete='off'
                                            bg='gray.50'
                                            h={10}
                                            onChange={onChange}
                                            placeholder='Repeat'
                                            size='sm'
                                            type='password'
                                            value={value} />
                                        <FormErrorMessage>
                                            { errors?.repeatPassword?.message }
                                        </FormErrorMessage>
                                    </>
                                )}
                                name='repeatPassword'
                                defaultValue='' />
                        </FormControl>
                    </FormProvider>
                    <Box mt={2}>
                        { pw && pw2 && pw !== pw2 && (
                            <Heading size='xxs' color='red.400'>
                                Passwords do not match
                            </Heading>
                        ) }
                    </Box>
                    <Button
                        size='2xl'
                        colorScheme='purple'
                        onClick={onResetPassword}
                        mt={10}
                        isLoading={loadingReset}
                        isDisabled={!pw || !pw2 || pw !== pw2 || loadingReset}>
                        Reset password
                    </Button>
                </Center>
            }
        </ViewContainer>
    )
}

export default Reset;
