import { Box, Icon } from '@chakra-ui/react';
import { FaStar } from 'react-icons/fa6';
import React from 'react';

const StarScore = ({ rating, size = 6 }: { rating: number, size?: number }) => (
    <Box>
        {[...Array(5)].map((_, index) => (
            <Icon
                key={`star-${index}`}
                as={FaStar}
                color={index < rating ? 'yellow.500' : 'gray'}
                opacity={index < rating ? 1 : 0.5}
                boxSize={size}
                mr={0}/>
        ))}
    </Box>
);

export default StarScore;
