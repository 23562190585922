import {
    Box,
    Button, Divider,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Icon,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Spacer,
    Stack,
    Text,
    useToast
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Select as MultiSelect } from 'chakra-react-select';
import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MdArrowForward, MdRemove } from 'react-icons/md';
import { z } from 'zod';
import CrmContact from '../../../components/CrmContact';
import PriorityIcon from '../../../components/Icon/PriorityIcon';
import {
    Account,
    ApplicationQuery,
    DealStage,
    DealType,
    ProjectPriority,
    useUpdateApplicationMutation,
} from '../../../graphql/generated';
import { STAGE_TO_PHASE, VALID_TRANSITIONS } from '../../../utils/validTransitions';
import FormInput from '../../../components/Input/FormInput';
import SearchAccount from '../../../components/SearchAccount';
import formatEnum from '../../../utils/formatEnum';
import { GiSheep } from 'react-icons/gi';
import { LuHardHat } from 'react-icons/lu';
import HelperText from '../../../components/Input/HelperText';
import formatDealType from '../../../utils/formatDealType';

type ApplicationQ = ApplicationQuery['application'];
type QueryDealType = ApplicationQ['dealTypes'][number];

interface Props {
    application: ApplicationQ;
    isOpen: boolean;
    onClose: () => void;
    fields?: string[];
}

const WorkingGroupMemberSchema = z.object({
    isShepherd: z.boolean(),
    account: z.object({
        id: z.number(),
        email: z.string().email(),
        contact: z.object({
            id: z.number(),
            fullName: z.string().nullable(),
            email: z.string().email(),
        }).nullable()
    })
});

const schema = z.object({
    dealStage: z.string().nullable(),
    dealTypes: z.array(z.string()).nullable(),
    priority: z.string().nullable(),
    discourseUrl: z.string().url().nullable().refine(url => {
        return !url || url?.startsWith('https://gov.vitadao.com/t/')
    }, 'Format: https://gov.vitadao.com/t/...'),
    snapshotUrl: z.string().url().nullable().refine(url => {
        return !url || url?.startsWith('https://snapshot.org/#/vote.vitadao.eth/proposal/0x')
    }, 'Format: https://snapshot.org/#/vote.vitadao.eth/proposal/0x...'),
    workingGroup: z.array(WorkingGroupMemberSchema).max(2, { message: 'Max 2 WG members' }).nullable()
});

export type FormSchema = z.infer<typeof schema>;

const UpdateApplicationModal: React.FC<Props> = ({ application, isOpen, onClose, fields = []  }) => {
    const toast = useToast();

    const form = useForm<FormSchema>({
        resolver: zodResolver(schema),
        defaultValues: {
            dealTypes: application.dealTypes.map((dt: QueryDealType) => dt.dealType),
            dealStage: application.dealStage,
            priority: application.priority,
            discourseUrl: application.discourseUrl,
            snapshotUrl: application.snapshotUrl,
            workingGroup: application.workingGroup
                ? application.workingGroup.filter(member => member.connected)
                : []
        }
    });
    const { register, handleSubmit, reset, formState: { errors } } = form;

    useEffect(() => {
        reset({
            dealTypes: application.dealTypes.map((dt: QueryDealType) => dt.dealType),
            dealStage: application.dealStage,
            priority: application.priority,
            discourseUrl: application.discourseUrl,
            snapshotUrl: application.snapshotUrl,
            workingGroup: application.workingGroup
                ? application.workingGroup.filter(member => member.connected)
                : []
        });
    }, [application, reset]);

    const [updateApplication, { loading: updateLoading }] = useUpdateApplicationMutation({
        update: (cache, { data: updatedData }) => {
            cache.modify({
                fields: {
                    application(existingApplicationRef, { toReference }) {
                        return updatedData
                            ? toReference(updatedData.updateApplication)
                            : existingApplicationRef;
                    },
                },
            });
        },
        onError: error => {
            toast({
                title: 'Error updating application',
                description: error.message,
                status: 'error',
                position: 'top',
                duration: 5000,
                isClosable: true,
            });
        }
    });

    const onSubmit = async (formData: FormSchema) => {
        await updateApplication({
            variables: {
                input: {
                    ...formData,
                    workingGroup: formData.workingGroup
                        ? formData.workingGroup.map((wg: any) => (
                            { accountId: wg.account.id, isShepherd: wg.isShepherd }))
                        : null,
                    applicationId: application.id,
                    dealStage: formData.dealStage as DealStage,
                    dealTypes: formData.dealTypes as DealType[] ?? [],
                    priority: formData.priority as ProjectPriority,
                },
            },
        });
        onClose();
    };

    const formStage = form.watch('dealStage');
    const formPriority = form.watch('priority');
    const formDealTypes = form.watch('dealTypes');
    const formWg = form.watch('workingGroup');
    const onAddWgMember = useCallback((account: Account) => {
        const adjustedMember = {
            isShepherd: formWg?.length === 0,
            account: {
                id: account.id,
                contact: account.contact
                    ? { ...account.contact, fullName: account.contact.fullName ?? null }
                    : null,
                email: account.email ?? null,
            },
            contact: {
                ...account.contact,
                fullName: account.contact?.fullName ?? null
            }
        };
        const pastWg = formWg ?? [];
        form.setValue('workingGroup', [...pastWg, adjustedMember]);
    }, [formWg]);

    const onRemoveWgMember = useCallback((wg: Account) => {
        if (!formWg) {
            return;
        }
        const newWg = formWg
            .filter(w => w.account.id !== wg.id);
        form.setValue('workingGroup', newWg);
    }, [formWg]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormProvider {...form}>
                        <ModalHeader>Update Application</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Stack spacing={4}>
                                <FormControl
                                    isInvalid={!!errors.dealStage}
                                    display={fields?.includes('dealStage') ? '' : 'none'}>
                                    <FormLabel size='sm' textAlign='center'>Deal Stage</FormLabel>
                                    <Select {...register('dealStage')}>
                                        <option value={application.dealStage} style={{textAlign: 'center'}}>
                                            {application.dealStage.split('_').join(' ')}
                                        </option>
                                        {VALID_TRANSITIONS[application.dealStage as DealStage]
                                            .map((stage: DealStage) => (
                                                <option key={stage} value={stage} style={{textAlign: 'center'}}>
                                                    {stage.split('_').join(' ')}
                                                </option>
                                            ))}
                                    </Select>
                                    <HStack fontSize='sm' color='gray' mt={1}  justify='space-between'>
                                        <Text>
                                            { formatEnum(application.phase) }
                                        </Text>
                                        <Icon as={MdArrowForward} mx={1} />
                                        <Text>
                                            {STAGE_TO_PHASE[formStage as DealStage].split('_').join(' ') }
                                        </Text>
                                    </HStack>
                                </FormControl>
                                <FormControl
                                    isInvalid={!!errors.priority}
                                    display={fields?.includes('priority') ? '' : 'none'}>
                                    <FormLabel size='sm' textAlign='center'>Priority</FormLabel>
                                    <HStack justify='space-evenly'>
                                        <Spacer />
                                        {Object
                                            .values(ProjectPriority)
                                            .map(priority => (
                                                <Button
                                                    key={priority}
                                                    size='lg'
                                                    bg={priority === formPriority ? 'blue.100' : 'gray.50'}
                                                    variant={priority === formPriority ? 'solid' : 'outline'}
                                                    onClick={() => form.setValue('priority', priority)}>
                                                    <PriorityIcon priority={priority} />
                                                </Button>
                                            ))
                                        }
                                        <Spacer />
                                    </HStack>
                                </FormControl>
                                <FormControl
                                    isInvalid={!!errors.dealTypes}
                                    display={fields?.includes('dealTypes') ? '' : 'none'}>
                                    <FormLabel size='sm' textAlign='center'>Deal Types</FormLabel>
                                    <MultiSelect
                                        {...register('dealTypes')}
                                        isMulti
                                        name='dealTypes'
                                        defaultValue={
                                            formDealTypes?.map((dt: string) => ({
                                                label: formatDealType(dt),
                                                value: dt
                                            }))
                                        }
                                        options={
                                            Object
                                                .values(DealType)
                                                .map((type: DealType) => ({
                                                    label: formatDealType(type),
                                                    value: type,
                                                }))
                                        }
                                        closeMenuOnSelect={false}
                                        onChange={selectedOptions => {
                                            form.setValue(
                                                'dealTypes',
                                                selectedOptions.map(option => option.value)
                                            );
                                        }} />
                                </FormControl>
                                <FormControl
                                    isInvalid={!!errors.discourseUrl}
                                    display={fields?.includes('urls') ? '' : 'none'}>
                                    <FormLabel size='sm' textAlign='center'>Discourse</FormLabel>
                                    <FormInput
                                        placeholder='https://gov.vitadao.com/..'
                                        isDisabled={
                                            !!application.discourseUrl
                                            || application.dealStage !== DealStage.PENDING_TEAM_ASSIGNMENT
                                        }
                                        defaultValue={application.discourseUrl}
                                        form={form}
                                        name='discourseUrl'/>
                                    <HelperText mb='4'>
                                        Can update if stage is &apos;PENDING TEAM ASSIGNMENT&apos;
                                    </HelperText>
                                    <FormControl
                                        isInvalid={!!errors.snapshotUrl}
                                        display={fields?.includes('urls') ? '' : 'none'}>
                                        <FormLabel size='sm' textAlign='center'>Snapshot</FormLabel>
                                        <FormInput
                                            placeholder='https://snapshot.org/#/vote.vitadao.eth/proposal/0x00...'
                                            isDisabled={
                                                !!application.snapshotUrl
                                                || application.dealStage !== DealStage.PENDING_SENIOR_REVIEWS
                                            }
                                            defaultValue={application.snapshotUrl}
                                            form={form}
                                            name='snapshotUrl' />
                                        <HelperText mb='4'>
                                            Can update if stage is &apos;PENDING SENIOR REVIEWS&apos;
                                        </HelperText>
                                    </FormControl>
                                </FormControl>
                                <FormControl
                                    isInvalid={!!errors.workingGroup}
                                    display={fields?.includes('workingGroup') ? '' : 'none'}>
                                    <FormLabel size='sm' textAlign='center'>Shepherd & Working Group</FormLabel>
                                    <SearchAccount
                                        isFlat
                                        onSelect={onAddWgMember}
                                        ignore={[
                                            ...formWg?.map(wg => wg.account.id) ?? []
                                        ]} />
                                    <FormErrorMessage>{errors?.workingGroup?.message}</FormErrorMessage>
                                    <Divider my={2}/>

                                    { !formWg  &&
                                        <Text size='sm' color='gray.400' p={2}>No group selected</Text>
                                    }
                                    <Box mt={4}>
                                        { formWg && formWg.length > 0 && formWg
                                            .map((wg, idx: number) => {
                                                if (!wg) {
                                                    return null;
                                                }
                                                return (
                                                    <CrmContact
                                                        key={`contact-${wg.account.id}-${idx}`}
                                                        extraIcon={
                                                            <Icon ml={1} as={ wg.isShepherd ? GiSheep : LuHardHat} />
                                                        }
                                                        contact={wg.account.contact}
                                                        extension={
                                                            <IconButton
                                                                borderWidth={1}
                                                                aria-label='remove'
                                                                size='xs'
                                                                variant='personInverse'
                                                                icon={<MdRemove />}
                                                                onClick={() => onRemoveWgMember(wg.account)} />
                                                        } />
                                                )
                                            })
                                        }
                                    </Box>
                                </FormControl>
                            </Stack>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='black' type='submit' isLoading={updateLoading} w='full'>Update</Button>
                        </ModalFooter>
                    </FormProvider>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default UpdateApplicationModal;
