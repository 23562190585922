import { Flex, Spinner } from '@chakra-ui/react';
import React from 'react';

const Overlay = ({ show = false }: { show?: boolean }) => (
    <Flex
        display={show ? 'flex' : 'none'}
        position='fixed'
        top='0'
        left='0'
        right='0'
        bottom='0'
        zIndex='overlay'
        backgroundColor='rgba(255, 255, 255, 0.7)'
        justifyContent='center'
        alignItems='center'>
        <Spinner size='xl' color='blue.500' />
    </Flex>
);

export default Overlay;
