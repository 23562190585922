import { Badge } from '@chakra-ui/react';
import { ProjectPriority } from '../../graphql/generated';

const PriorityBadge = ({ priority }: { priority: ProjectPriority }) => {
    const priorityColor = priority === ProjectPriority.HIGH
        ? 'red'
        : priority === ProjectPriority.MEDIUM
            ? 'orange'
            : 'green';
    return <Badge colorScheme={priorityColor}>{priority}</Badge>;
}

export default PriorityBadge;
