type UnknownArrayOrObject = unknown[] | Record<string, unknown>;

export const dirtyValues = (
    dirtyFields: UnknownArrayOrObject | boolean,
    allValues: UnknownArrayOrObject
): UnknownArrayOrObject => {
    // NOTE: Recursive function.

    // If *any* item in an array was modified, the entire array must be submitted, because there's no
    // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
        return allValues;
    }

    return Object.fromEntries(
        Object.keys(dirtyFields).map(key => [
            key,
            // Check if key exists in allValues before accessing it
            allValues && key in allValues
                // @ts-expect-error https://github.com/react-hook-form/react-hook-form/issues/656
                ? dirtyValues(dirtyFields[key], allValues[key])
                : undefined
        ])
    );
};

export const nullifyEmptyStringsAndObjects = (inputObject: Record<string, any>): Record<string, any> | null => {
    const result: Record<string, any> = {};

    for (const [key, value] of Object.entries(inputObject)) {
        if (value === null || (typeof value === 'object' && Object.keys(value).length > 0)) {
            // Recursively process nested objects
            const processedValue = typeof value === 'object' ? nullifyEmptyStringsAndObjects(value) : value;

            if (processedValue !== null) {
                // Only add to result if the processed value is not null
                result[key] = processedValue;
            }
        } else if (value !== '') {
            // Keep non-empty values
            result[key] = value;
        }
    }

    return Object.keys(result).length > 0 ? result : null;
};
