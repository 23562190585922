import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
    Button,
    Center,
    Container,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Image,
    Input,
    Link,
    Stack,
    Text,
    useToast
} from '@chakra-ui/react';
import { useRegisterMutation } from '../../graphql/generated';
import LogoType from '../../assets/logos/logotype.svg';
import { PATHS } from '../../config';
import { useNavigate } from 'react-router-dom';

const schema = z.object({
    email: z.string().email(),
    password: z.string().min(4, { message: 'Password must be at least 4 characters long' }),
    fullName: z.string().nullish()
});

type FormSchema = z.infer<typeof schema>;

const RegisterView = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm<FormSchema>({
        resolver: zodResolver(schema),
    });

    const [submitRegister, { data, loading }] = useRegisterMutation({
        onError: error => {
            toast({
                title: error.message,
                status: 'error',
                duration: 5000,
                position: 'top',
                isClosable: true,
            })
        }
    });

    const onSubmit = async (formData: FormSchema) => {
        const { data } = await submitRegister({
            variables: {
                input: {
                    email: formData.email,
                    password: formData.password,
                    fullName: formData.fullName
                }
            }
        });
        if (data) {
            toast({
                title: 'Registration successful',
                description: 'Please check your email for a confirmation link.',
                status: 'success',
                duration: 5000,
                position: 'top',
                isClosable: true,
            })
            navigate(PATHS.LOGIN);
        }
    };

    return (
        <Container maxW='1280px' py={10}>
            <Center w='100%'>
                <Flex direction='column' alignItems='center' maxW={520} w='40vw'>
                    <Image src={LogoType} w={250} />
                    { !data &&
                        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                            <Stack mt={10} w='100%' gap={3} boxShadow='lg' p={10}>
                                <FormControl id='email' isInvalid={!!errors.email}>
                                    <FormLabel>Email address</FormLabel>
                                    <Input type='email' {...register('email')} placeholder='xyz@vitadao.com'/>
                                    <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                                </FormControl>
                                <FormControl id='password' isInvalid={!!errors.password}>
                                    <FormLabel>Password</FormLabel>
                                    <Input type='password' {...register('password')} placeholder='*****' />
                                    <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
                                </FormControl>
                                <FormControl id='password' isInvalid={!!errors.password}>
                                    <FormLabel>Full Name</FormLabel>
                                    <Input {...register('fullName')} placeholder='Inigo Montoya' />
                                    <FormErrorMessage>{errors.fullName?.message}</FormErrorMessage>
                                </FormControl>
                                <Flex>
                                    <Link href={PATHS.LOGIN}>Login</Link>
                                </Flex>
                                <Button isLoading={loading} mt={4} variant='black' type='submit' w='100%'>
                                    Register
                                </Button>
                            </Stack>
                        </form>
                    }
                    { data &&
                        <Stack>
                            <Heading size='sm'>
                                You need to confirm your email before you can login.
                            </Heading>
                            <Text>
                                Please check your email for a confirmation link including spam folder.
                            </Text>
                        </Stack>
                    }
                </Flex>
            </Center>
        </Container>
    );
}

export default RegisterView;