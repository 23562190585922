import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Stack,
    useToast
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { ApplicationQuery, useUpdateApplicationMutation } from '../../../graphql/generated';

type ApplicationQ = ApplicationQuery['application'];

interface Props {
    application: ApplicationQ;
    isOpen: boolean;
    onClose: () => void;
}

const schema = z.object({
    dataRoomUrl: z.string()
});

export type FormSchema = z.infer<typeof schema>;

const DataroomLinkModal: React.FC<Props> = ({ application, isOpen, onClose }) => {
    const toast = useToast();

    const form = useForm<FormSchema>({
        resolver: zodResolver(schema),
        defaultValues: {
            dataRoomUrl: application.dataroom?.dataRoomUrl ?? '',
        }
    });
    const { handleSubmit, reset, formState: { errors } } = form;

    useEffect(() => {
        reset({
            dataRoomUrl: application.dataroom?.dataRoomUrl ?? '',
        });
    }, [application, reset]);

    const [updateApplication, { loading }] = useUpdateApplicationMutation({
        onError: error => {
            toast({
                title: 'Could not post update',
                description: error.message,
                status: 'error',
                position: 'top',
                duration: 5000,
                isClosable: true,
            });
        }
    });

    const onSubmit = async (formData: FormSchema) => {
        await updateApplication({
            variables: {
                input: {
                    ...formData,
                    applicationId: application.id
                },
            },
        });
        onClose();
    };


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormProvider {...form}>
                        <ModalCloseButton />
                        <ModalBody>
                            <Stack spacing={4}>
                                <FormControl isInvalid={!!errors.dataRoomUrl}>
                                    <FormLabel size='sm' textAlign='center'>
                                        Update Data Room URL
                                    </FormLabel>
                                    <Input
                                        {...form.register('dataRoomUrl')}
                                        name='dataRoomUrl'
                                        placeholder='Google Drive, etcURL' />
                                    {errors.dataRoomUrl &&
                                        <FormErrorMessage>{errors.dataRoomUrl.message}</FormErrorMessage>}
                                </FormControl>
                            </Stack>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='black' type='submit' isLoading={loading} w='full'>Update</Button>
                        </ModalFooter>
                    </FormProvider>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default DataroomLinkModal;
