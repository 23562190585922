const Table = {
    variants: {
        striped: {
            tbody: {
                tr: {
                    '&:nth-of-type(odd)': {
                        backgroundColor: 'blue.50',
                    },
                },
            },
        },
    },
};

export default Table;