import ViewContainer from '../../components/View/ViewContainer';
import { AbsoluteCenter, Text } from '@chakra-ui/react';

const NotOnboarded = () => (
    <ViewContainer>
        <AbsoluteCenter>
            <Text fontWeight='bold'>
                Your account has not been onboarded by the Administrator/Steward
            </Text>
            <Text>
                Please contact them to onboard your account and select an appropriate role.
            </Text>
        </AbsoluteCenter>
    </ViewContainer>
);

export default NotOnboarded;
