import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Stack,
    Text
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { ApplicationQuery, } from '../../../graphql/generated';
import FileUploadInput from '../../../components/Input/FileUploadInput';
import { apolloClient } from '../../../config';
import FileList from '../../../components/FileList';
import { uploadFiles } from '../../../utils/uploadFiles';

type ApplicationQ = ApplicationQuery['application'];

interface Props {
    application: ApplicationQ;
    isOpen: boolean;
    onClose: () => void;
}

const EditFiles: React.FC<Props> = ({ application, isOpen, onClose }) => {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [wgFiles, setWgFiles] = useState<File[]>([]);

    async function uploadFilesForApplication(applicationId: number) {
        if (wgFiles.length > 0) {
            const uploadedFilesByPath = await uploadFiles(
                wgFiles,
                wgFiles.map(() => 'workingGroup'),
                applicationId.toString()
            );
            const uploadedFiles = Object.values(uploadedFilesByPath).flat();
            const existingFiles = application.dataroom?.files ?? [];
            apolloClient.cache.modify({
                id: apolloClient.cache.identify({ __typename: 'Application', id: applicationId }),
                fields: {
                    dataroom(existingDataroom) {
                        return {
                            ...existingDataroom,
                            files: [...existingFiles, ...uploadedFiles]
                        };
                    }
                }
            });
            return uploadedFilesByPath
        }
        return {};
    }

    const onSubmit = async () => {
        setSubmitting(true);
        await uploadFilesForApplication(application.id);
        setSubmitting(false);
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                    <Stack spacing={5} p={2}>
                        <Text size='sm' textAlign='center' my={1}>
                            Upload Application Documents
                        </Text>
                        <FileUploadInput
                            onFilesSelected={files => setWgFiles([...wgFiles, ...files])}/>
                        <FileList
                            size='lg'
                            files={wgFiles}
                            onRemove={fl => setWgFiles(wgFiles.filter(f => f !== fl))} />
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant='black'
                        onClick={async () => await onSubmit()}
                        isLoading={submitting}
                        w='full'>
                        Upload
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default EditFiles;
