import {
    Button,
    Divider,
    Flex,
    Icon,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spacer,
    Text
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../Providers/AuthProvider';
import LogoType from '../../assets/logos/logotype.svg';
import { AccountRole, useLogoutMutation } from '../../graphql/generated';
import { GrLogout } from 'react-icons/gr';
import { PATHS } from '../../config';

const Header = () => {
    const { account, setAuthValues } = useContext(AuthContext);
    const [logout] = useLogoutMutation({
        onCompleted: () => {
            setAuthValues(null, false);
        }
    });

    return (
        <>
            <Flex w='100%' h={50} p={4} mb={10} mt={account && !account.emailConfirmed ? 30 : 0}>
                <NavLink to={PATHS.HOME}>
                    <Image src={LogoType} w={250} />
                </NavLink>
                <Spacer />
                <Flex dir='row' gap={3}>
                    <Text userSelect='none'>
                        {account && account.emailConfirmed && (
                            <Menu>
                                <MenuButton as={Button} variant='ghost'>
                                    {account.contact?.fullName ?? 'Menu'}
                                </MenuButton>
                                <MenuList zIndex={99999}>
                                    <MenuItem as={NavLink} to={PATHS.ACCOUNT} userSelect='none'>
                                        Account
                                    </MenuItem>
                                    <MenuItem
                                        as={NavLink}
                                        to={PATHS.CRM}
                                        userSelect='none'
                                        display={account.role === AccountRole.ADMIN ? undefined : 'none'}>
                                        CRM
                                    </MenuItem>
                                    <MenuItem
                                        as={NavLink}
                                        to={PATHS.KPI}
                                        userSelect='none'
                                        display={account.role === AccountRole.ADMIN ? undefined : 'none'}>
                                        KPI
                                    </MenuItem>
                                    <Divider my={1} />
                                    <MenuItem onClick={() => logout()} color='red' >
                                        <Icon as={GrLogout} mr={2} />
                                        Logout
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        )}
                    </Text>
                </Flex>
            </Flex>
        </>
    )
}

export default Header;
