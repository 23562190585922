import React, { useState } from 'react';
import { Box, Icon, Text } from '@chakra-ui/react';
import { FaStar } from 'react-icons/fa6';
import HelperText from './HelperText';
import { InputMaybe } from '../../graphql/generated';

interface StarReviewProps {
    value: number | undefined | InputMaybe<number>;
    onChange?: (value: number) => void;
    helperText?: string;
    readonly? : boolean;
}
const StarInput: React.FC<StarReviewProps> = ({ value, onChange, helperText, readonly }) => {
    const [rating, setRating] = useState<number>(value ?? 0);
    const [hoverIndex, setHoverIndex] = useState<number>(0);

    const handleStarClick = (starIndex: number) => {
        if (!onChange || readonly) {
            return;
        }
        if (starIndex === rating - 1) {
            setRating(0);
            onChange(0);
        } else {
            setRating(starIndex + 1);
            setHoverIndex(0);
            onChange(starIndex + 1);
        }
    };

    return (
        <Box my={1}>
            <Box>
                {[...Array(5)].map((_, index) => (
                    <Icon
                        key={index}
                        as={FaStar}
                        color={index < rating ? 'yellow.500' : (hoverIndex >= index + 1 ? 'yellow.500' : 'gray.400')}
                        opacity={index < rating ? 1 : (hoverIndex >= index + 1 ? 0.5 : 1)}
                        boxSize={6}
                        mr={2}
                        cursor='pointer'
                        onClick={() => handleStarClick(index)}
                        onMouseEnter={() => {
                            if (!readonly && index >= rating) setHoverIndex(index + 1);
                        }}
                        onMouseLeave={() => {
                            if (!readonly && index >= rating) setHoverIndex(0);
                        }}/>
                ))}
            </Box>
            <Text fontSize='xs' color='gray.500' userSelect='none'>
                Provide a score between 1-5 (1: Unacceptable, 5: Outstanding)
            </Text>
            <HelperText mt={2} fontSize='sm'>{helperText}</HelperText>
        </Box>
    );
};

export default StarInput;
