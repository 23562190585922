import React from 'react';
import moment from 'moment/moment';
import { ApplicationsQuery, useApplicationReviewsLazyQuery } from '../../../graphql/generated';
import { Box, Button, HStack, Icon, Spacer, Stack, Text, useDisclosure } from '@chakra-ui/react';
import formatStringEllipsis from '../../../utils/formatStringEllipsis';
import DealStageBadge from '../../../components/Badge/DealStageBadge';
import formatCurrency from '../../../utils/formatCurrency';
import { FaExternalLinkAlt } from 'react-icons/fa';
import ReviewsPreviewModal from './ReviewsPreviewModal';

type Application = ApplicationsQuery['applications']['applications'][number];

interface IApplicationEntry {
    application: Application;
}

const ApplicationEntry: React.FC<IApplicationEntry> = ({ application }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [fetchReviews, { data }] = useApplicationReviewsLazyQuery();

    const onFetchReviews = async () => {
        await fetchReviews({ variables: { applicationId: application.id } });
        onOpen();
    }

    return (
        <>
            <Stack borderWidth={1} my={1} p={1} key={`applicant-app-${application.id}`}>
                <Stack gap={0}>
                    <Text color='gray.900' fontWeight='bold'>
                        #{application.id} { formatStringEllipsis(application.title, 50) }
                    </Text>
                    <Text size='xs' color='gray.400'>
                        last update { moment.utc(application.updatedAt).fromNow() }
                    </Text>
                </Stack>
                <Box maxW={120}>
                    <DealStageBadge stage={application.dealStage} />
                </Box>
                <Stack>
                    <Text
                        as='a'
                        color={application.discourseUrl ? 'blue.500' : 'gray.400'}
                        cursor={!application.discourseUrl ? 'not-allowed' : 'pointer'}
                        href={application.discourseUrl ?? ''}
                        size='sm'
                        target='_blank'>
                        Discourse <Icon as={FaExternalLinkAlt} />
                    </Text>
                    <Text
                        as='a'
                        color={application.discourseUrl ? 'blue.500' : 'gray.400'}
                        cursor={!application.snapshotUrl ? 'not-allowed' : 'pointer'}
                        href={application.snapshotUrl ?? ''}
                        size='sm'
                        target='_blank'>
                        Snapshot <Icon as={FaExternalLinkAlt} />
                    </Text>
                </Stack>
                <HStack>
                    <Text>
                        ASK: { formatCurrency(application.fundingRequired) }
                    </Text>
                    <Spacer/>
                    <Button
                        size='xs'
                        w={90}
                        colorScheme='blue'
                        variant='outline'
                        onClick={onFetchReviews}>
                        Reviews
                    </Button>
                </HStack>
            </Stack>
            <ReviewsPreviewModal
                reviews={data?.applicationReviews ?? []}
                onClose={onClose}
                isOpen={isOpen} />
        </>
    );
}

export default ApplicationEntry;
