import { Colors } from '@chakra-ui/react';
import { ApplicationPhase, DealStage } from '../graphql/generated';

const gray300 = '#D0D5DD';
const green400 = '#48BB78';
const green600 = '#2F855A';
const yellow400 = '#ECC94B';
const blue200 = '#90CDF4';
const blue300 = '#63B3ED';
const red400 = '#F56565';
const teal300 = '#4FD1C5';

const colors: Colors = {
    error: {
        900: '#7A271A',
        800: '#912018',
        700: '#B42318',
        600: '#D92D20',
        500: '#F04438',
        400: '#F97066',
        300: '#FDA29B',
        200: '#FECDCA',
        100: '#FEE4E2',
        50: '#FEF3F2',
    },
    gray: {
        900: '#101828',
        800: '#1D2939',
        700: '#344054',
        600: '#475467',
        500: '#667085',
        400: '#98A2B3',
        300: '#D0D5DD',
        200: '#EAECF0',
        100: '#F2F4F7',
        50: '#F9FAFB',
        main: '#2D2D2D',
    },
    rose: {
        900: '#89123E',
        800: '#A11043',
        700: '#C01048',
        600: '#E31B54',
        500: '#F63D68',
        400: '#FD6F8E',
        300: '#FEA3B4',
        200: '#FECDD6',
        100: '#FFE4E8',
        50: '#FFF1F3',
    },
    red: {
        main: '#E54D42',
    },
    green: {
        main: '#02CC88',
        100: '#D1FADF',
        200: '#A6F4C5',
        700: '#087443'
    },
    borderColor: '#D0D5DD',
    link: '#2662D7',
    primary: '#1D2939',
    secondary: '#656E91',
    textColor: 'gray.800',
    dealStage: {
        // live states
        [DealStage.DISCORD_VOTE_LIVE]: green400,
        [DealStage.DISCOURSE_VOTE_LIVE]: green400,
        [DealStage.SNAPSHOT_VOTE_LIVE]: green400,
        // in progress states
        [DealStage.PENDING_PITCH_WG]: yellow400,
        [DealStage.PENDING_SENIOR_REVIEWS]: yellow400,
        [DealStage.PENDING_FUNDING]: yellow400,
        [DealStage.PENDING_TEAM_ASSIGNMENT]: yellow400,
        // new or frozen
        [DealStage.ARCHIVED]: blue200,
        [DealStage.NEW]: blue300,
        // success states
        [DealStage.DISCORD_VOTE_PASSED]: green600,
        [DealStage.DISCOURSE_VOTE_PASSED]: green600,
        [DealStage.SNAPSHOT_VOTE_PASSED]: green600,
        [DealStage.FUNDED]: green600,
        // failure states
        [DealStage.SNAPSHOT_VOTE_FAILED]: red400,
        [DealStage.DISCOURSE_VOTE_FAILED]: red400,
        [DealStage.DISCORD_VOTE_FAILED]: red400,
        'REJECTED': red400,
    },
    applicationPhase: {
        [ApplicationPhase.PHASE_0]: gray300,
        [ApplicationPhase.PHASE_1]: blue300,
        [ApplicationPhase.PHASE_2]: teal300,
        [ApplicationPhase.PHASE_3]: yellow400,
        [ApplicationPhase.PHASE_4]: green400,
    }
};

export default colors;
