import { Button, Card, CardBody, CardFooter, Heading, HStack, Spacer, Stack, Tag, Text } from '@chakra-ui/react';
import { ApplicationsQuery, MyReviewsQuery } from '../../../graphql/generated';
import React from 'react';
import formatStringEllipsis from '../../../utils/formatStringEllipsis';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

interface ApplicationCardProps {
    application: ApplicationsQuery['applications']['applications'][number];
    review?: MyReviewsQuery['myReviews'][number] | null;
}

const FUNDING_LIMIT = 100_000;

const ApplicationCard: React.FC<ApplicationCardProps> = ({ application, review }) => {
    const navigate = useNavigate();
    return (
        <Card direction={{ base: 'column', sm: 'row' }} overflow='hidden' variant='outline' mb={1}>
            <Stack>
                <CardBody>
                    <Heading size='xs'>{ application.title }</Heading>
                    <Tag size='sm'>
                        Deal Size { application.fundingRequired <= FUNDING_LIMIT ? ' <= $100K' : ' > $100K' }
                    </Tag>
                    <Text py='2'>
                        { formatStringEllipsis(application.synopsis, 100) }
                    </Text>
                </CardBody>

                <CardFooter pb={1}>
                    <Stack w='100%'>
                        <HStack w='100%'>
                            <Button size='xs' onClick={() => navigate(`/application/${application.id}`)}>
                                View Application
                            </Button>
                            <Spacer />
                            <Button size='xs' variant='black' onClick={() => navigate(`/review/${application.id}`)}>
                                Review
                            </Button>
                        </HStack>
                        { review &&
                            <Text size='xs' color='gray' textAlign='right'>
                                { moment.utc(review.updatedAt).fromNow() }
                            </Text>
                        }
                    </Stack>
                </CardFooter>
            </Stack>
        </Card>
    )
}

export default ApplicationCard;
