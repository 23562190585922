import { ApplicationPhase, DealStage } from '../graphql/generated';

export const VALID_TRANSITIONS: { [key in DealStage]: DealStage[] } = {
    [DealStage.ARCHIVED]: [],
    [DealStage.NEW]: [DealStage.ARCHIVED],
    [DealStage.DISCORD_VOTE_LIVE]: [],
    [DealStage.DISCORD_VOTE_PASSED]: [DealStage.PENDING_TEAM_ASSIGNMENT, DealStage.ARCHIVED],
    [DealStage.DISCORD_VOTE_FAILED]: [],
    [DealStage.PENDING_TEAM_ASSIGNMENT]: [DealStage.ARCHIVED],
    [DealStage.DISCOURSE_VOTE_LIVE]: [DealStage.DISCOURSE_VOTE_PASSED, DealStage.DISCOURSE_VOTE_FAILED],
    [DealStage.DISCOURSE_VOTE_PASSED]: [DealStage.PENDING_PITCH_WG, DealStage.ARCHIVED],
    [DealStage.DISCOURSE_VOTE_FAILED]: [],
    [DealStage.PENDING_PITCH_WG]: [DealStage.PENDING_SENIOR_REVIEWS],
    [DealStage.PENDING_SENIOR_REVIEWS]: [],
    [DealStage.SNAPSHOT_VOTE_LIVE]: [DealStage.SNAPSHOT_VOTE_PASSED, DealStage.SNAPSHOT_VOTE_FAILED],
    [DealStage.SNAPSHOT_VOTE_PASSED]: [DealStage.PENDING_FUNDING],
    [DealStage.SNAPSHOT_VOTE_FAILED]: [],
    [DealStage.PENDING_FUNDING]: [DealStage.FUNDED],
    [DealStage.FUNDED]: []
};

export const STAGE_TO_PHASE: { [key in DealStage]: ApplicationPhase } = {
    [DealStage.ARCHIVED]: ApplicationPhase.PHASE_0,
    [DealStage.NEW]: ApplicationPhase.PHASE_1,
    [DealStage.DISCORD_VOTE_LIVE]: ApplicationPhase.PHASE_1,
    [DealStage.DISCORD_VOTE_PASSED]: ApplicationPhase.PHASE_1,
    [DealStage.DISCORD_VOTE_FAILED]: ApplicationPhase.PHASE_0,
    [DealStage.PENDING_TEAM_ASSIGNMENT]: ApplicationPhase.PHASE_1,
    [DealStage.DISCOURSE_VOTE_LIVE]: ApplicationPhase.PHASE_2,
    [DealStage.DISCOURSE_VOTE_PASSED]: ApplicationPhase.PHASE_2,
    [DealStage.DISCOURSE_VOTE_FAILED]: ApplicationPhase.PHASE_0,
    [DealStage.PENDING_PITCH_WG]: ApplicationPhase.PHASE_2,
    [DealStage.PENDING_SENIOR_REVIEWS]: ApplicationPhase.PHASE_2,
    [DealStage.SNAPSHOT_VOTE_LIVE]: ApplicationPhase.PHASE_3,
    [DealStage.SNAPSHOT_VOTE_PASSED]: ApplicationPhase.PHASE_3,
    [DealStage.SNAPSHOT_VOTE_FAILED]: ApplicationPhase.PHASE_0,
    [DealStage.PENDING_FUNDING]: ApplicationPhase.PHASE_4,
    [DealStage.FUNDED]: ApplicationPhase.PHASE_4
}
