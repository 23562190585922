import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Icon, IconProps } from '@chakra-ui/react';

interface LinkIconProps {
    href: string | null | undefined;
    fontSize?: string;
}

const LinkIcon: React.FC<LinkIconProps | IconProps> = ({ href, ...props }) => {
    if (!href) {
        return null;
    }
    return (
        <Icon
            fontSize={props?.fontSize || 'sm'}
            cursor='pointer'
            as={FaExternalLinkAlt}
            onClick={() => window.open(href, '_')}
            {...props} />
    )
}

export default LinkIcon;