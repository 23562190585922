import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    useToast,
    Container,
    Center,
    Image,
    Flex,
    Stack,
    Link,
    Spacer
} from '@chakra-ui/react';
import { useLoginMutation } from '../../graphql/generated';
import { useNavigate } from 'react-router-dom';
import LogoType from '../../assets/logos/logotype.svg';
import { PATHS } from '../../config';
import { AuthContext } from '../../components/Providers/AuthProvider';

const schema = z.object({
    email: z.string().email(),
    password: z.string().min(4, { message: 'Password must be at least 4 characters long' }),
});

type FormSchema = z.infer<typeof schema>;

const LoginView = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const { setAuthValues } = useContext(AuthContext);
    const { register, handleSubmit, formState: { errors } } = useForm<FormSchema>({
        resolver: zodResolver(schema),
    });

    const [login, { loading }] = useLoginMutation({
        onError: error => {
            toast({
                title: error.message,
                status: 'error',
                duration: 5000,
                position: 'top',
                isClosable: true,
            })
        }
    });

    const onSubmit = async (formData: FormSchema) => {
        const { data } = await login({
            variables: {
                input: {
                    email: formData.email,
                    password: formData.password,
                }
            }
        });
        if (data) {
            setAuthValues(data.login, true);
            navigate(PATHS.HOME);
        }
    };

    return (
        <Container maxW='1280px' py={10}>
            <Center w='100%'>
                <Flex direction='column' alignItems='center' maxW={520} w='40vw'>
                    <Image src={LogoType} w={250} />
                    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                        <Stack mt={10} w='100%' gap={3} boxShadow='lg' p={10}>
                            <FormControl id='email' isInvalid={!!errors.email}>
                                <FormLabel>Email address</FormLabel>
                                <Input type='email' {...register('email')} placeholder='xyz@vitadao.com'/>
                                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl id='password' isInvalid={!!errors.password}>
                                <FormLabel>Password</FormLabel>
                                <Input type='password' {...register('password')} placeholder='*****' />
                                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
                            </FormControl>
                            <Flex>
                                <Link href={PATHS.REGISTER}>Register</Link>
                                <Spacer />
                                <Link href={PATHS.FORGOT_PASSWORD}>Forgot password?</Link>
                            </Flex>
                            <Button isLoading={loading} mt={4} variant='black' type='submit' w='100%'>
                                Login
                            </Button>
                        </Stack>
                    </form>
                </Flex>
            </Center>
        </Container>
    );
}

export default LoginView;