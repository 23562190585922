/* eslint-disable */
import { z } from 'zod';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: Date; output: Date };
  Json: { input: any; output: any };
  LocalTime: { input: any; output: any };
};

export type Account = {
  __typename?: 'Account';
  contact?: Maybe<Contact>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  email: Scalars['String']['output'];
  emailConfirmed?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  isOnboarded?: Maybe<Scalars['Boolean']['output']>;
  preference?: Maybe<Preference>;
  role?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  walletAddress?: Maybe<Scalars['String']['output']>;
};

export type AccountEnumInput = {
  equals?: InputMaybe<AccountRole>;
  in?: InputMaybe<Array<InputMaybe<AccountRole>>>;
};

export type AccountFilterInput = {
  role?: InputMaybe<AccountEnumInput>;
};

export type AccountOrderInput = {
  id?: InputMaybe<SortOrder>;
  isOnboarded?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
};

export enum AccountRole {
  ADMIN = 'ADMIN',
  APPLICANT = 'APPLICANT',
  OTHER = 'OTHER',
  SENIOR_REVIEWER = 'SENIOR_REVIEWER',
  STAFF = 'STAFF',
}

export type Answer = {
  __typename?: 'Answer';
  question: Question;
  text: Scalars['String']['output'];
};

export type AnswerInput = {
  questionId: Scalars['Int']['input'];
  text: Scalars['String']['input'];
};

export type Application = {
  __typename?: 'Application';
  answers: Array<Answer>;
  applicant: Contact;
  applicationType: ApplicationType;
  assetDevelopmentStage?: Maybe<Scalars['String']['output']>;
  countryCode: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  dataroom?: Maybe<Dataroom>;
  dealStage: DealStage;
  dealTypes: Array<ApplicationDealTypes>;
  discordVoting?: Maybe<DiscordVoting>;
  discourseUrl?: Maybe<Scalars['String']['output']>;
  fundingRaised: Scalars['Int']['output'];
  fundingRequired: Scalars['Int']['output'];
  icdCodes?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  members?: Maybe<Array<Contact>>;
  nihCategories?: Maybe<Scalars['String']['output']>;
  nihNotCovered?: Maybe<Scalars['String']['output']>;
  patentStatus: PatentStatus;
  phase: ApplicationPhase;
  priority: ProjectPriority;
  referrer?: Maybe<Contact>;
  reviews: Array<SeniorReview>;
  snapshotUrl?: Maybe<Scalars['String']['output']>;
  synopsis: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  workingGroup?: Maybe<Array<WorkingGroupMember>>;
  workingGroupUpdates?: Maybe<Array<WorkingGroupUpdate>>;
};

export type ApplicationDealTypes = {
  __typename?: 'ApplicationDealTypes';
  application?: Maybe<Application>;
  dealType: DealType;
};

export type ApplicationFilterInput = {
  applicant?: InputMaybe<ContactFilterInput>;
  applicationType?: InputMaybe<StringFilterInput>;
  assetDevelopmentStage?: InputMaybe<StringFilterInput>;
  countryCode?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  dealStage?: InputMaybe<StringFilterInput>;
  fundingRaised?: InputMaybe<NumberFilterInput>;
  fundingRequired?: InputMaybe<NumberFilterInput>;
  id?: InputMaybe<NumberFilterInput>;
  patentStatus?: InputMaybe<StringFilterInput>;
  phase?: InputMaybe<StringFilterInput>;
  priority?: InputMaybe<StringFilterInput>;
  referrer?: InputMaybe<ContactFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type ApplicationInput = {
  answers: Array<AnswerInput>;
  applicant: ContactInput;
  assetDevelopmentStage?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  fundingRaised: Scalars['Int']['input'];
  fundingRequired: Scalars['Int']['input'];
  icdCodes?: InputMaybe<Scalars['String']['input']>;
  members?: InputMaybe<Array<ContactInput>>;
  nihCategories?: InputMaybe<Scalars['String']['input']>;
  nihNotCovered?: InputMaybe<Scalars['String']['input']>;
  patentStatus?: InputMaybe<PatentStatus>;
  referrer?: InputMaybe<ContactInput>;
  synopsis: Scalars['String']['input'];
  title: Scalars['String']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
};

export type ApplicationOrderByInput = {
  applicant?: InputMaybe<ContactOrderByInput>;
  applicationType?: InputMaybe<SortOrder>;
  countryCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dealStage?: InputMaybe<SortOrder>;
  fundingRaised?: InputMaybe<SortOrder>;
  fundingRequired?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  patentStatus?: InputMaybe<SortOrder>;
  phase?: InputMaybe<SortOrder>;
  priority?: InputMaybe<SortOrder>;
  referrer?: InputMaybe<ContactOrderByInput>;
  synopsis?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ApplicationPhase {
  PHASE_0 = 'PHASE_0',
  PHASE_1 = 'PHASE_1',
  PHASE_2 = 'PHASE_2',
  PHASE_3 = 'PHASE_3',
  PHASE_4 = 'PHASE_4',
}

export enum ApplicationType {
  INBOUND = 'INBOUND',
  REFERRAL = 'REFERRAL',
}

export type Applications = {
  __typename?: 'Applications';
  applications: Array<Application>;
  count: Scalars['Int']['output'];
};

export type Contact = {
  __typename?: 'Contact';
  account?: Maybe<Account>;
  areaOfExpertise?: Maybe<Scalars['String']['output']>;
  attachment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discordId?: Maybe<Scalars['String']['output']>;
  diversity?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  leading?: Maybe<Array<MiniApplication>>;
  location?: Maybe<Scalars['String']['output']>;
  memberOf?: Maybe<Array<MiniApplication>>;
  notes?: Maybe<Scalars['String']['output']>;
  organizations?: Maybe<Array<OrganizationAffiliation>>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  referrals?: Maybe<Array<MiniApplication>>;
  socialMedia?: Maybe<Scalars['String']['output']>;
  twitterHandle?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  vitaRoles?: Maybe<Scalars['String']['output']>;
  walletAddress?: Maybe<Scalars['String']['output']>;
  workingGroupMembership?: Maybe<Array<Maybe<ContactWorkingGroup>>>;
};

export type ContactFilterInput = {
  fullName?: InputMaybe<StringFilterInput>;
};

export type ContactInput = {
  areaOfExpertise?: InputMaybe<Scalars['String']['input']>;
  attachment?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discordId?: InputMaybe<Scalars['String']['input']>;
  diversity?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  fullName?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  socialMedia?: InputMaybe<Scalars['String']['input']>;
  vitaRoles?: InputMaybe<Scalars['String']['input']>;
};

export type ContactOrderByInput = {
  account?: InputMaybe<AccountOrderInput>;
  background?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  discordId?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  twitterHandle?: InputMaybe<SortOrder>;
};

export type ContactWhereInput = {
  account?: InputMaybe<AccountFilterInput>;
  discordId?: InputMaybe<StringFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  fullName?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<NumberFilterInput>;
  twitterHandle?: InputMaybe<StringFilterInput>;
};

export type ContactWorkingGroup = {
  __typename?: 'ContactWorkingGroup';
  contact: Contact;
  workingGroup: WorkingGroupNames;
};

export type Contacts = {
  __typename?: 'Contacts';
  contacts: Array<Contact>;
  count: Scalars['Int']['output'];
};

export type ContactsFilterInput = {
  orderBy: ContactOrderByInput;
  searchString?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactWhereInput>;
};

export type CreateContactInput = {
  areaOfExpertise?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discordId?: InputMaybe<Scalars['String']['input']>;
  diversity?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  socialMedia?: InputMaybe<Scalars['String']['input']>;
  twitterHandle?: InputMaybe<Scalars['String']['input']>;
  vitaRoles?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWgUpdateInput = {
  applicationId: Scalars['Int']['input'];
  blocking: Scalars['Boolean']['input'];
  text: Scalars['String']['input'];
};

export type Dataroom = {
  __typename?: 'Dataroom';
  createdAt: Scalars['Date']['output'];
  dataRoomUrl?: Maybe<Scalars['String']['output']>;
  files: Array<File>;
  id: Scalars['Int']['output'];
};

export type DateFilterInput = {
  equals?: InputMaybe<Scalars['Date']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  lt?: InputMaybe<Scalars['Date']['input']>;
};

export enum DealStage {
  /** Phase 0 */
  ARCHIVED = 'ARCHIVED',
  DISCORD_VOTE_FAILED = 'DISCORD_VOTE_FAILED',
  DISCORD_VOTE_LIVE = 'DISCORD_VOTE_LIVE',
  DISCORD_VOTE_PASSED = 'DISCORD_VOTE_PASSED',
  DISCOURSE_VOTE_FAILED = 'DISCOURSE_VOTE_FAILED',
  /** Phase 2 */
  DISCOURSE_VOTE_LIVE = 'DISCOURSE_VOTE_LIVE',
  DISCOURSE_VOTE_PASSED = 'DISCOURSE_VOTE_PASSED',
  FUNDED = 'FUNDED',
  /** Phase 1 */
  NEW = 'NEW',
  /** Phase 4 */
  PENDING_FUNDING = 'PENDING_FUNDING',
  PENDING_PITCH_WG = 'PENDING_PITCH_WG',
  PENDING_SENIOR_REVIEWS = 'PENDING_SENIOR_REVIEWS',
  PENDING_TEAM_ASSIGNMENT = 'PENDING_TEAM_ASSIGNMENT',
  SNAPSHOT_VOTE_FAILED = 'SNAPSHOT_VOTE_FAILED',
  /** Phase 3 */
  SNAPSHOT_VOTE_LIVE = 'SNAPSHOT_VOTE_LIVE',
  SNAPSHOT_VOTE_PASSED = 'SNAPSHOT_VOTE_PASSED',
}

export enum DealType {
  EQUITY_DEAL = 'EQUITY_DEAL',
  IP_NFT_IPT_ASSET = 'IP_NFT_IPT_ASSET',
  OTHER = 'OTHER',
  SPIN_OUT = 'SPIN_OUT',
}

export type DealsInDate = {
  __typename?: 'DealsInDate';
  count: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
};

export type DiscordVoting = {
  __typename?: 'DiscordVoting';
  createdAt?: Maybe<Scalars['Date']['output']>;
  downvotes: Scalars['Int']['output'];
  messageId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  upvotes: Scalars['Int']['output'];
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  url: Scalars['String']['output'];
};

export type FileInput = {
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type FilterInput = {
  orderBy: ApplicationOrderByInput;
  searchString?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ApplicationFilterInput>;
};

export type FloatFilterInput = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type FunnelStage = {
  __typename?: 'FunnelStage';
  count: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type KpiResponse = {
  __typename?: 'KpiResponse';
  deals: Array<DealsInDate>;
  flowFunnel: Array<FunnelStage>;
  funnel: Array<FunnelStage>;
  shepherds: Array<ShepherdStats>;
};

export type LoginPayload = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MiniApplication = {
  __typename?: 'MiniApplication';
  createdAt?: Maybe<Scalars['Date']['output']>;
  dealStage: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  phase: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  apply: Application;
  confirmEmail: Scalars['Boolean']['output'];
  createContact: Contact;
  createDiscordVoting?: Maybe<DiscordVoting>;
  createWgUpdate: WorkingGroupUpdate;
  login: Account;
  logout: Account;
  registerAccount: Account;
  resendConfirmEmail: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  root: Scalars['String']['output'];
  savePreference: Preference;
  searchAccount?: Maybe<Array<Account>>;
  sendResetPassword: Scalars['Boolean']['output'];
  updateAccount: Account;
  updateApplication: Application;
  updateContact: Contact;
  upsertReview?: Maybe<SeniorReview>;
};

export type MutationApplyArgs = {
  input: ApplicationInput;
};

export type MutationConfirmEmailArgs = {
  token: Scalars['String']['input'];
};

export type MutationCreateContactArgs = {
  input: CreateContactInput;
};

export type MutationCreateDiscordVotingArgs = {
  applicationId: Scalars['Int']['input'];
};

export type MutationCreateWgUpdateArgs = {
  input: CreateWgUpdateInput;
};

export type MutationLoginArgs = {
  input: LoginPayload;
};

export type MutationRegisterAccountArgs = {
  input: RegisterPayload;
};

export type MutationResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationSavePreferenceArgs = {
  input: SavePreferenceInput;
};

export type MutationSearchAccountArgs = {
  onlyStaff?: InputMaybe<Scalars['Boolean']['input']>;
  searchString: Scalars['String']['input'];
};

export type MutationSendResetPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationUpdateAccountArgs = {
  input: UpdateAccountPayload;
};

export type MutationUpdateApplicationArgs = {
  input: UpdateApplicationInput;
};

export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};

export type MutationUpsertReviewArgs = {
  input: SeniorReviewInput;
};

export type NumberFilterInput = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
};

export type Organization = {
  __typename?: 'Organization';
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  people?: Maybe<Array<Maybe<OrganizationAffiliation>>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type OrganizationAffiliation = {
  __typename?: 'OrganizationAffiliation';
  department?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  role: Scalars['String']['output'];
};

export type OrganizationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export enum PatentStatus {
  GRANTED = 'GRANTED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export type Preference = {
  __typename?: 'Preference';
  applicationPreference?: Maybe<Scalars['Json']['output']>;
  crmPreference?: Maybe<Scalars['Json']['output']>;
};

export enum ProjectPriority {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
}

export type Query = {
  __typename?: 'Query';
  account: Account;
  accountApplicationReview?: Maybe<SeniorReview>;
  application: Application;
  applicationReviews: Array<SeniorReview>;
  applications: Applications;
  contact: Contact;
  contacts: Contacts;
  kpis: KpiResponse;
  myReviews: Array<SeniorReview>;
  ping?: Maybe<Account>;
  review?: Maybe<SeniorReview>;
  root: Scalars['String']['output'];
  staff: Array<Account>;
  validateResetPasswordToken: Scalars['Boolean']['output'];
};

export type QueryAccountApplicationReviewArgs = {
  applicationId: Scalars['Int']['input'];
};

export type QueryApplicationArgs = {
  id: Scalars['Int']['input'];
};

export type QueryApplicationReviewsArgs = {
  applicationId: Scalars['Int']['input'];
};

export type QueryApplicationsArgs = {
  input: FilterInput;
};

export type QueryContactArgs = {
  contactId: Scalars['Int']['input'];
};

export type QueryContactsArgs = {
  input: ContactsFilterInput;
};

export type QueryKpisArgs = {
  from: Scalars['Date']['input'];
  to: Scalars['Date']['input'];
};

export type QueryReviewArgs = {
  applicationId: Scalars['Int']['input'];
  reviewerId: Scalars['Int']['input'];
};

export type QueryValidateResetPasswordTokenArgs = {
  token: Scalars['String']['input'];
};

export type Question = {
  __typename?: 'Question';
  id?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
};

export type RegisterPayload = {
  email: Scalars['String']['input'];
  fullName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export enum ReviewDealType {
  EQUITY_DEAL_UNDER_100 = 'EQUITY_DEAL_UNDER_100',
  EQUIY_DEAL_OVER_100 = 'EQUIY_DEAL_OVER_100',
  IP_NFT_IPT_ASSET_OVER_100 = 'IP_NFT_IPT_ASSET_OVER_100',
  IP_NFT_IPT_ASSET_UNDER_100 = 'IP_NFT_IPT_ASSET_UNDER_100',
}

export enum ReviewRecommendation {
  ADVANCE_FOR_VOTE = 'ADVANCE_FOR_VOTE',
  DENY_OUTRIGHT = 'DENY_OUTRIGHT',
  FOLLOW_UP = 'FOLLOW_UP',
}

export enum ReviewReturnProfile {
  MOONSHOT = 'MOONSHOT',
  NOT_AREA_OF_EXPERTISE = 'NOT_AREA_OF_EXPERTISE',
  NOT_ENOUGH_INFO = 'NOT_ENOUGH_INFO',
  QUICK_WIN = 'QUICK_WIN',
  TYPICAL = 'TYPICAL',
}

export type SavePreferenceInput = {
  applicationPreference?: InputMaybe<Scalars['Json']['input']>;
  crmPreference?: InputMaybe<Scalars['Json']['input']>;
};

export type SeniorReview = {
  __typename?: 'SeniorReview';
  applicationId: Scalars['Int']['output'];
  commercialComment?: Maybe<Scalars['String']['output']>;
  commercialScore: Scalars['Int']['output'];
  completed?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['Date']['output'];
  dealTermsComment?: Maybe<Scalars['String']['output']>;
  dealTermsScore: Scalars['Int']['output'];
  evaluationSummary?: Maybe<Scalars['String']['output']>;
  evaluationSummaryScore: Scalars['Int']['output'];
  feasibilityComment?: Maybe<Scalars['String']['output']>;
  feasibilityScore: Scalars['Int']['output'];
  hasConflict: Scalars['Boolean']['output'];
  ipDefensibilityComment?: Maybe<Scalars['String']['output']>;
  ipDefensibilityScore: Scalars['Int']['output'];
  longevityRelevanceComment?: Maybe<Scalars['String']['output']>;
  longevityRelevanceScore: Scalars['Int']['output'];
  noveltyComment?: Maybe<Scalars['String']['output']>;
  noveltyScore: Scalars['Int']['output'];
  recommendation?: Maybe<ReviewRecommendation>;
  returnProfile?: Maybe<ReviewReturnProfile>;
  reviewDealType: ReviewDealType;
  reviewer?: Maybe<Account>;
  teamExpertiseComment?: Maybe<Scalars['String']['output']>;
  teamExpertiseScore: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  wouldEndorse?: Maybe<Scalars['String']['output']>;
};

export type SeniorReviewInput = {
  applicationId: Scalars['Int']['input'];
  commercialComment?: InputMaybe<Scalars['String']['input']>;
  commercialScore?: InputMaybe<Scalars['Int']['input']>;
  dealTermsComment?: InputMaybe<Scalars['String']['input']>;
  dealTermsScore?: InputMaybe<Scalars['Int']['input']>;
  evaluationSummary?: InputMaybe<Scalars['String']['input']>;
  evaluationSummaryScore?: InputMaybe<Scalars['Int']['input']>;
  feasibilityComment?: InputMaybe<Scalars['String']['input']>;
  feasibilityRisks?: InputMaybe<Scalars['String']['input']>;
  feasibilityScore?: InputMaybe<Scalars['Int']['input']>;
  hasConflict: Scalars['Boolean']['input'];
  ipDefensibilityComment?: InputMaybe<Scalars['String']['input']>;
  ipDefensibilityScore?: InputMaybe<Scalars['Int']['input']>;
  longevityRelevanceComment?: InputMaybe<Scalars['String']['input']>;
  longevityRelevanceScore?: InputMaybe<Scalars['Int']['input']>;
  noveltyAdvantages?: InputMaybe<Scalars['String']['input']>;
  noveltyComment?: InputMaybe<Scalars['String']['input']>;
  noveltyScore?: InputMaybe<Scalars['Int']['input']>;
  recommendation?: InputMaybe<ReviewRecommendation>;
  returnProfile?: InputMaybe<ReviewReturnProfile>;
  reviewDealType?: InputMaybe<ReviewDealType>;
  teamExpertiseComment?: InputMaybe<Scalars['String']['input']>;
  teamExpertiseScore?: InputMaybe<Scalars['Int']['input']>;
  wouldEndorse?: InputMaybe<Scalars['String']['input']>;
};

export type ShepherdStats = {
  __typename?: 'ShepherdStats';
  accountId: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  fullName: Scalars['String']['output'];
  funded: Scalars['Int']['output'];
  rejected: Scalars['Int']['output'];
};

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export enum StringCaseSensitivity {
  default = 'default',
  insensitive = 'insensitive',
}

export type StringFilterInput = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  mode?: InputMaybe<StringCaseSensitivity>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Token = {
  __typename?: 'Token';
  token: Scalars['String']['output'];
};

export type UpdateAccountInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  isOnboarded?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAccountPayload = {
  fullName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateApplicationInput = {
  applicationId: Scalars['Int']['input'];
  backgroundInformation?: InputMaybe<Array<FileInput>>;
  dataRoomUrl?: InputMaybe<Scalars['String']['input']>;
  dealStage?: InputMaybe<DealStage>;
  dealTypes?: InputMaybe<Array<DealType>>;
  discourseUrl?: InputMaybe<Scalars['String']['input']>;
  presentationMaterials?: InputMaybe<Array<FileInput>>;
  priority?: InputMaybe<ProjectPriority>;
  shepherd?: InputMaybe<WgAccountInput>;
  snapshotUrl?: InputMaybe<Scalars['String']['input']>;
  supportingPatents?: InputMaybe<Array<FileInput>>;
  workingGroup?: InputMaybe<Array<WgAccountInput>>;
};

export type UpdateContactInput = {
  account?: InputMaybe<UpdateAccountInput>;
  areaOfExpertise?: InputMaybe<Scalars['String']['input']>;
  background?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discordId?: InputMaybe<Scalars['String']['input']>;
  diversity?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  socialMedia?: InputMaybe<Scalars['String']['input']>;
  twitterHandle?: InputMaybe<Scalars['String']['input']>;
  vitaRoles?: InputMaybe<Scalars['String']['input']>;
};

export type WgAccountInput = {
  accountId: Scalars['Int']['input'];
  isShepherd: Scalars['Boolean']['input'];
};

export type WorkingGroupMember = {
  __typename?: 'WorkingGroupMember';
  account: Account;
  application?: Maybe<Application>;
  connected: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isShepherd: Scalars['Boolean']['output'];
  updates?: Maybe<Array<WorkingGroupUpdate>>;
};

export enum WorkingGroupNames {
  COMMUNITY_AND_AWARENESS = 'COMMUNITY_AND_AWARENESS',
  GOVERNANCE = 'GOVERNANCE',
  LEGAL = 'LEGAL',
  LONGEVITY = 'LONGEVITY',
  OPERATIONS = 'OPERATIONS',
  TECH_AND_PRODUCT = 'TECH_AND_PRODUCT',
}

export type WorkingGroupUpdate = {
  __typename?: 'WorkingGroupUpdate';
  account: Account;
  blocking: Scalars['Boolean']['output'];
  createdAt: Scalars['Date']['output'];
  text: Scalars['String']['output'];
};

export type ApplyMutationVariables = Exact<{
  input: ApplicationInput;
}>;

export type ApplyMutation = {
  __typename?: 'Mutation';
  apply: {
    __typename?: 'Application';
    id: number;
    applicationType: ApplicationType;
    countryCode: string;
    createdAt: Date;
    dealStage: DealStage;
    fundingRaised: number;
    fundingRequired: number;
    icdCodes?: string | null;
    phase: ApplicationPhase;
    priority: ProjectPriority;
    website?: string | null;
    nihCategories?: string | null;
    nihNotCovered?: string | null;
    patentStatus: PatentStatus;
    synopsis: string;
    title: string;
    answers: Array<{
      __typename?: 'Answer';
      text: string;
      question: { __typename?: 'Question'; id?: number | null; title: string };
    }>;
    members?: Array<{
      __typename?: 'Contact';
      email: string;
      fullName?: string | null;
    }> | null;
    referrer?: {
      __typename?: 'Contact';
      email: string;
      fullName?: string | null;
      id: number;
      phoneNumber?: string | null;
    } | null;
    applicant: {
      __typename?: 'Contact';
      id: number;
      email: string;
      fullName?: string | null;
      phoneNumber?: string | null;
      organizations?: Array<{
        __typename?: 'OrganizationAffiliation';
        role: string;
        organization: {
          __typename?: 'Organization';
          description?: string | null;
          name: string;
          website?: string | null;
        };
      }> | null;
    };
  };
};

export type ConfirmEmailMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type ConfirmEmailMutation = {
  __typename?: 'Mutation';
  confirmEmail: boolean;
};

export type CreateContactMutationVariables = Exact<{
  input: CreateContactInput;
}>;

export type CreateContactMutation = {
  __typename?: 'Mutation';
  createContact: {
    __typename?: 'Contact';
    id: number;
    createdAt?: Date | null;
    email: string;
    fullName?: string | null;
    walletAddress?: string | null;
    areaOfExpertise?: string | null;
    discordId?: string | null;
    diversity?: string | null;
    phoneNumber?: string | null;
    socialMedia?: string | null;
    notes?: string | null;
    vitaRoles?: string | null;
    twitterHandle?: string | null;
  };
};

export type CreateDiscordVoteMutationVariables = Exact<{
  appId: Scalars['Int']['input'];
}>;

export type CreateDiscordVoteMutation = {
  __typename?: 'Mutation';
  createDiscordVoting?: {
    __typename?: 'DiscordVoting';
    messageId: string;
    upvotes: number;
    downvotes: number;
  } | null;
};

export type CreateWgUpdateMutationVariables = Exact<{
  input: CreateWgUpdateInput;
}>;

export type CreateWgUpdateMutation = {
  __typename?: 'Mutation';
  createWgUpdate: {
    __typename?: 'WorkingGroupUpdate';
    blocking: boolean;
    text: string;
    account: {
      __typename?: 'Account';
      id: number;
      role?: string | null;
      contact?: { __typename?: 'Contact'; fullName?: string | null } | null;
    };
  };
};

export type SendResetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type SendResetPasswordMutation = {
  __typename?: 'Mutation';
  sendResetPassword: boolean;
};

export type LoginMutationVariables = Exact<{
  input: LoginPayload;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'Account';
    id: number;
    email: string;
    role?: string | null;
    isOnboarded?: boolean | null;
    emailConfirmed?: boolean | null;
    preference?: {
      __typename?: 'Preference';
      applicationPreference?: any | null;
      crmPreference?: any | null;
    } | null;
    contact?: {
      __typename?: 'Contact';
      id: number;
      email: string;
      fullName?: string | null;
    } | null;
  };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = {
  __typename?: 'Mutation';
  logout: {
    __typename?: 'Account';
    id: number;
    email: string;
    role?: string | null;
    contact?: { __typename?: 'Contact'; id: number; email: string } | null;
  };
};

export type RegisterMutationVariables = Exact<{
  input: RegisterPayload;
}>;

export type RegisterMutation = {
  __typename?: 'Mutation';
  registerAccount: {
    __typename?: 'Account';
    id: number;
    email: string;
    role?: string | null;
    isOnboarded?: boolean | null;
    emailConfirmed?: boolean | null;
    contact?: {
      __typename?: 'Contact';
      id: number;
      email: string;
      fullName?: string | null;
    } | null;
    preference?: {
      __typename?: 'Preference';
      applicationPreference?: any | null;
      crmPreference?: any | null;
    } | null;
  };
};

export type ResendConfirmationEmailMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ResendConfirmationEmailMutation = {
  __typename?: 'Mutation';
  resendConfirmEmail: boolean;
};

export type ResetPasswordMutationVariables = Exact<{
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword: boolean;
};

export type SavePreferenceMutationVariables = Exact<{
  input: SavePreferenceInput;
}>;

export type SavePreferenceMutation = {
  __typename?: 'Mutation';
  savePreference: {
    __typename?: 'Preference';
    applicationPreference?: any | null;
    crmPreference?: any | null;
  };
};

export type SearchAccountMutationVariables = Exact<{
  searchText: Scalars['String']['input'];
  onlyStaff?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SearchAccountMutation = {
  __typename?: 'Mutation';
  searchAccount?: Array<{
    __typename?: 'Account';
    id: number;
    email: string;
    contact?: {
      __typename?: 'Contact';
      id: number;
      fullName?: string | null;
      email: string;
    } | null;
  }> | null;
};

export type UpdateAccountMutationVariables = Exact<{
  input: UpdateAccountPayload;
}>;

export type UpdateAccountMutation = {
  __typename?: 'Mutation';
  updateAccount: {
    __typename?: 'Account';
    id: number;
    email: string;
    role?: string | null;
    isOnboarded?: boolean | null;
    emailConfirmed?: boolean | null;
    contact?: {
      __typename?: 'Contact';
      id: number;
      email: string;
      fullName?: string | null;
    } | null;
  };
};

export type UpdateApplicationMutationVariables = Exact<{
  input: UpdateApplicationInput;
}>;

export type UpdateApplicationMutation = {
  __typename?: 'Mutation';
  updateApplication: {
    __typename?: 'Application';
    id: number;
    title: string;
    synopsis: string;
    website?: string | null;
    countryCode: string;
    discourseUrl?: string | null;
    snapshotUrl?: string | null;
    applicationType: ApplicationType;
    patentStatus: PatentStatus;
    dealStage: DealStage;
    phase: ApplicationPhase;
    priority: ProjectPriority;
    icdCodes?: string | null;
    nihCategories?: string | null;
    nihNotCovered?: string | null;
    fundingRequired: number;
    fundingRaised: number;
    dataroom?: {
      __typename?: 'Dataroom';
      id: number;
      dataRoomUrl?: string | null;
      files: Array<{
        __typename?: 'File';
        id: number;
        url: string;
        name: string;
        type: string;
        path: string;
      }>;
    } | null;
    dealTypes: Array<{
      __typename?: 'ApplicationDealTypes';
      dealType: DealType;
    }>;
    answers: Array<{
      __typename?: 'Answer';
      text: string;
      question: { __typename?: 'Question'; id?: number | null; title: string };
    }>;
    workingGroup?: Array<{
      __typename?: 'WorkingGroupMember';
      isShepherd: boolean;
      connected: boolean;
      account: {
        __typename?: 'Account';
        id: number;
        email: string;
        contact?: {
          __typename?: 'Contact';
          id: number;
          fullName?: string | null;
          email: string;
        } | null;
      };
    }> | null;
    applicant: {
      __typename?: 'Contact';
      id: number;
      fullName?: string | null;
      email: string;
      organizations?: Array<{
        __typename?: 'OrganizationAffiliation';
        role: string;
        organization: {
          __typename?: 'Organization';
          name: string;
          description?: string | null;
          website?: string | null;
        };
      }> | null;
    };
    members?: Array<{
      __typename?: 'Contact';
      id: number;
      fullName?: string | null;
      email: string;
      organizations?: Array<{
        __typename?: 'OrganizationAffiliation';
        role: string;
        organization: {
          __typename?: 'Organization';
          name: string;
          description?: string | null;
          website?: string | null;
        };
      }> | null;
    }> | null;
    referrer?: {
      __typename?: 'Contact';
      id: number;
      fullName?: string | null;
      email: string;
      organizations?: Array<{
        __typename?: 'OrganizationAffiliation';
        role: string;
        organization: {
          __typename?: 'Organization';
          name: string;
          description?: string | null;
          website?: string | null;
        };
      }> | null;
    } | null;
  };
};

export type UpdateContactMutationVariables = Exact<{
  input: UpdateContactInput;
}>;

export type UpdateContactMutation = {
  __typename?: 'Mutation';
  updateContact: {
    __typename?: 'Contact';
    description?: string | null;
    discordId?: string | null;
    email: string;
    fullName?: string | null;
    phoneNumber?: string | null;
    id: number;
    diversity?: string | null;
    areaOfExpertise?: string | null;
    socialMedia?: string | null;
    twitterHandle?: string | null;
    vitaRoles?: string | null;
    notes?: string | null;
    walletAddress?: string | null;
    workingGroupMembership?: Array<{
      __typename?: 'ContactWorkingGroup';
      workingGroup: WorkingGroupNames;
    } | null> | null;
    organizations?: Array<{
      __typename?: 'OrganizationAffiliation';
      role: string;
      department?: string | null;
      organization: {
        __typename?: 'Organization';
        description?: string | null;
        id: number;
        name: string;
        website?: string | null;
      };
    }> | null;
    account?: {
      __typename?: 'Account';
      id: number;
      role?: string | null;
      isOnboarded?: boolean | null;
      emailConfirmed?: boolean | null;
    } | null;
  };
};

export type UpsertReviewMutationVariables = Exact<{
  input: SeniorReviewInput;
}>;

export type UpsertReviewMutation = {
  __typename?: 'Mutation';
  upsertReview?: {
    __typename?: 'SeniorReview';
    hasConflict: boolean;
    reviewDealType: ReviewDealType;
    teamExpertiseScore: number;
    teamExpertiseComment?: string | null;
    feasibilityScore: number;
    feasibilityComment?: string | null;
    commercialScore: number;
    commercialComment?: string | null;
    noveltyScore: number;
    noveltyComment?: string | null;
    ipDefensibilityScore: number;
    ipDefensibilityComment?: string | null;
    longevityRelevanceScore: number;
    longevityRelevanceComment?: string | null;
    dealTermsScore: number;
    dealTermsComment?: string | null;
    evaluationSummaryScore: number;
    evaluationSummary?: string | null;
    returnProfile?: ReviewReturnProfile | null;
    wouldEndorse?: string | null;
    reviewer?: {
      __typename?: 'Account';
      contact?: { __typename?: 'Contact'; fullName?: string | null } | null;
    } | null;
  } | null;
};

export type AccountQueryVariables = Exact<{ [key: string]: never }>;

export type AccountQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    email: string;
    id: number;
    walletAddress?: string | null;
    contact?: { __typename?: 'Contact'; fullName?: string | null } | null;
  };
};

export type AccountApplicationReviewQueryVariables = Exact<{
  applicationId: Scalars['Int']['input'];
}>;

export type AccountApplicationReviewQuery = {
  __typename?: 'Query';
  accountApplicationReview?: {
    __typename?: 'SeniorReview';
    hasConflict: boolean;
    reviewDealType: ReviewDealType;
    teamExpertiseScore: number;
    teamExpertiseComment?: string | null;
    feasibilityScore: number;
    feasibilityComment?: string | null;
    commercialScore: number;
    commercialComment?: string | null;
    noveltyScore: number;
    noveltyComment?: string | null;
    ipDefensibilityScore: number;
    ipDefensibilityComment?: string | null;
    longevityRelevanceScore: number;
    longevityRelevanceComment?: string | null;
    dealTermsScore: number;
    dealTermsComment?: string | null;
    evaluationSummaryScore: number;
    evaluationSummary?: string | null;
    returnProfile?: ReviewReturnProfile | null;
    wouldEndorse?: string | null;
    reviewer?: {
      __typename?: 'Account';
      contact?: { __typename?: 'Contact'; fullName?: string | null } | null;
    } | null;
  } | null;
};

export type ApplicationQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type ApplicationQuery = {
  __typename?: 'Query';
  application: {
    __typename?: 'Application';
    id: number;
    applicationType: ApplicationType;
    countryCode: string;
    dealStage: DealStage;
    createdAt: Date;
    updatedAt?: Date | null;
    discourseUrl?: string | null;
    snapshotUrl?: string | null;
    fundingRequired: number;
    fundingRaised: number;
    icdCodes?: string | null;
    website?: string | null;
    synopsis: string;
    title: string;
    patentStatus: PatentStatus;
    phase: ApplicationPhase;
    priority: ProjectPriority;
    nihCategories?: string | null;
    nihNotCovered?: string | null;
    dealTypes: Array<{
      __typename?: 'ApplicationDealTypes';
      dealType: DealType;
    }>;
    discordVoting?: {
      __typename?: 'DiscordVoting';
      messageId: string;
      upvotes: number;
      downvotes: number;
    } | null;
    answers: Array<{
      __typename?: 'Answer';
      text: string;
      question: { __typename?: 'Question'; id?: number | null; title: string };
    }>;
    workingGroup?: Array<{
      __typename?: 'WorkingGroupMember';
      id: number;
      connected: boolean;
      isShepherd: boolean;
      account: {
        __typename?: 'Account';
        id: number;
        email: string;
        contact?: {
          __typename?: 'Contact';
          id: number;
          fullName?: string | null;
          email: string;
        } | null;
      };
    }> | null;
    workingGroupUpdates?: Array<{
      __typename?: 'WorkingGroupUpdate';
      text: string;
      blocking: boolean;
      createdAt: Date;
      account: {
        __typename?: 'Account';
        id: number;
        role?: string | null;
        contact?: { __typename?: 'Contact'; fullName?: string | null } | null;
      };
    }> | null;
    applicant: {
      __typename?: 'Contact';
      id: number;
      fullName?: string | null;
      email: string;
      organizations?: Array<{
        __typename?: 'OrganizationAffiliation';
        role: string;
        organization: {
          __typename?: 'Organization';
          name: string;
          description?: string | null;
          website?: string | null;
        };
      }> | null;
    };
    members?: Array<{
      __typename?: 'Contact';
      id: number;
      fullName?: string | null;
      email: string;
      organizations?: Array<{
        __typename?: 'OrganizationAffiliation';
        role: string;
        organization: {
          __typename?: 'Organization';
          name: string;
          description?: string | null;
          website?: string | null;
        };
      }> | null;
    }> | null;
    referrer?: {
      __typename?: 'Contact';
      id: number;
      fullName?: string | null;
      email: string;
      organizations?: Array<{
        __typename?: 'OrganizationAffiliation';
        role: string;
        organization: {
          __typename?: 'Organization';
          name: string;
          description?: string | null;
          website?: string | null;
        };
      }> | null;
    } | null;
    dataroom?: {
      __typename?: 'Dataroom';
      dataRoomUrl?: string | null;
      files: Array<{
        __typename?: 'File';
        name: string;
        path: string;
        type: string;
        url: string;
      }>;
    } | null;
    reviews: Array<{
      __typename?: 'SeniorReview';
      applicationId: number;
      completed?: boolean | null;
      evaluationSummaryScore: number;
      teamExpertiseScore: number;
      commercialScore: number;
      dealTermsScore: number;
      feasibilityScore: number;
      ipDefensibilityScore: number;
      hasConflict: boolean;
      updatedAt?: Date | null;
      reviewer?: {
        __typename?: 'Account';
        id: number;
        contact?: { __typename?: 'Contact'; fullName?: string | null } | null;
      } | null;
    }>;
  };
};

export type ApplicationReviewsQueryVariables = Exact<{
  applicationId: Scalars['Int']['input'];
}>;

export type ApplicationReviewsQuery = {
  __typename?: 'Query';
  applicationReviews: Array<{
    __typename?: 'SeniorReview';
    applicationId: number;
    hasConflict: boolean;
    reviewDealType: ReviewDealType;
    recommendation?: ReviewRecommendation | null;
    teamExpertiseScore: number;
    teamExpertiseComment?: string | null;
    feasibilityScore: number;
    feasibilityComment?: string | null;
    commercialScore: number;
    commercialComment?: string | null;
    noveltyScore: number;
    noveltyComment?: string | null;
    ipDefensibilityScore: number;
    ipDefensibilityComment?: string | null;
    longevityRelevanceScore: number;
    longevityRelevanceComment?: string | null;
    dealTermsScore: number;
    dealTermsComment?: string | null;
    evaluationSummaryScore: number;
    evaluationSummary?: string | null;
    returnProfile?: ReviewReturnProfile | null;
    wouldEndorse?: string | null;
    completed?: boolean | null;
    reviewer?: { __typename?: 'Account'; id: number } | null;
  }>;
};

export type ApplicationsQueryVariables = Exact<{
  input: FilterInput;
}>;

export type ApplicationsQuery = {
  __typename?: 'Query';
  applications: {
    __typename?: 'Applications';
    count: number;
    applications: Array<{
      __typename?: 'Application';
      id: number;
      title: string;
      synopsis: string;
      website?: string | null;
      countryCode: string;
      discourseUrl?: string | null;
      snapshotUrl?: string | null;
      patentStatus: PatentStatus;
      dealStage: DealStage;
      phase: ApplicationPhase;
      priority: ProjectPriority;
      icdCodes?: string | null;
      nihCategories?: string | null;
      nihNotCovered?: string | null;
      fundingRequired: number;
      fundingRaised: number;
      createdAt: Date;
      updatedAt?: Date | null;
      applicationType: ApplicationType;
      dealTypes: Array<{
        __typename?: 'ApplicationDealTypes';
        dealType: DealType;
      }>;
      workingGroup?: Array<{
        __typename?: 'WorkingGroupMember';
        id: number;
        isShepherd: boolean;
        connected: boolean;
        account: {
          __typename?: 'Account';
          id: number;
          email: string;
          contact?: {
            __typename?: 'Contact';
            id: number;
            email: string;
            fullName?: string | null;
          } | null;
        };
      }> | null;
      applicant: {
        __typename?: 'Contact';
        id: number;
        fullName?: string | null;
        email: string;
        vitaRoles?: string | null;
        areaOfExpertise?: string | null;
        organizations?: Array<{
          __typename?: 'OrganizationAffiliation';
          role: string;
          organization: {
            __typename?: 'Organization';
            name: string;
            description?: string | null;
            website?: string | null;
          };
        }> | null;
      };
      members?: Array<{
        __typename?: 'Contact';
        id: number;
        fullName?: string | null;
        email: string;
        vitaRoles?: string | null;
        areaOfExpertise?: string | null;
        organizations?: Array<{
          __typename?: 'OrganizationAffiliation';
          role: string;
          organization: {
            __typename?: 'Organization';
            name: string;
            description?: string | null;
            website?: string | null;
          };
        }> | null;
      }> | null;
      referrer?: {
        __typename?: 'Contact';
        id: number;
        fullName?: string | null;
        email: string;
        vitaRoles?: string | null;
        areaOfExpertise?: string | null;
        organizations?: Array<{
          __typename?: 'OrganizationAffiliation';
          role: string;
          organization: {
            __typename?: 'Organization';
            name: string;
            description?: string | null;
            website?: string | null;
          };
        }> | null;
      } | null;
    }>;
  };
};

export type ContactQueryVariables = Exact<{
  contactId: Scalars['Int']['input'];
}>;

export type ContactQuery = {
  __typename?: 'Query';
  contact: {
    __typename?: 'Contact';
    id: number;
    areaOfExpertise?: string | null;
    diversity?: string | null;
    description?: string | null;
    discordId?: string | null;
    email: string;
    fullName?: string | null;
    location?: string | null;
    notes?: string | null;
    phoneNumber?: string | null;
    socialMedia?: string | null;
    twitterHandle?: string | null;
    vitaRoles?: string | null;
    walletAddress?: string | null;
    updatedAt?: Date | null;
    createdAt?: Date | null;
    account?: {
      __typename?: 'Account';
      role?: string | null;
      isOnboarded?: boolean | null;
    } | null;
    organizations?: Array<{
      __typename?: 'OrganizationAffiliation';
      role: string;
      department?: string | null;
      organization: {
        __typename?: 'Organization';
        id: number;
        name: string;
        website?: string | null;
        description?: string | null;
      };
    }> | null;
    leading?: Array<{
      __typename?: 'MiniApplication';
      id: number;
      title: string;
      phase: string;
      dealStage: string;
    }> | null;
    memberOf?: Array<{
      __typename?: 'MiniApplication';
      id: number;
      title: string;
      phase: string;
      dealStage: string;
    }> | null;
    referrals?: Array<{
      __typename?: 'MiniApplication';
      id: number;
      title: string;
      phase: string;
      dealStage: string;
    }> | null;
  };
};

export type ContactsQueryVariables = Exact<{
  input: ContactsFilterInput;
}>;

export type ContactsQuery = {
  __typename?: 'Query';
  contacts: {
    __typename?: 'Contacts';
    count: number;
    contacts: Array<{
      __typename?: 'Contact';
      description?: string | null;
      discordId?: string | null;
      email: string;
      fullName?: string | null;
      phoneNumber?: string | null;
      id: number;
      socialMedia?: string | null;
      twitterHandle?: string | null;
      vitaRoles?: string | null;
      walletAddress?: string | null;
      workingGroupMembership?: Array<{
        __typename?: 'ContactWorkingGroup';
        workingGroup: WorkingGroupNames;
      } | null> | null;
      organizations?: Array<{
        __typename?: 'OrganizationAffiliation';
        role: string;
        department?: string | null;
        organization: {
          __typename?: 'Organization';
          description?: string | null;
          id: number;
          name: string;
          website?: string | null;
        };
      }> | null;
      account?: {
        __typename?: 'Account';
        id: number;
        role?: string | null;
        isOnboarded?: boolean | null;
        emailConfirmed?: boolean | null;
      } | null;
    }>;
  };
};

export type KpisQueryVariables = Exact<{
  from: Scalars['Date']['input'];
  to: Scalars['Date']['input'];
}>;

export type KpisQuery = {
  __typename?: 'Query';
  kpis: {
    __typename?: 'KpiResponse';
    deals: Array<{ __typename?: 'DealsInDate'; date: Date; count: number }>;
    funnel: Array<{ __typename?: 'FunnelStage'; name: string; count: number }>;
    shepherds: Array<{
      __typename?: 'ShepherdStats';
      accountId: number;
      fullName: string;
      count: number;
      funded: number;
      rejected: number;
    }>;
    flowFunnel: Array<{
      __typename?: 'FunnelStage';
      name: string;
      count: number;
    }>;
  };
};

export type MyReviewsQueryVariables = Exact<{ [key: string]: never }>;

export type MyReviewsQuery = {
  __typename?: 'Query';
  myReviews: Array<{
    __typename?: 'SeniorReview';
    hasConflict: boolean;
    applicationId: number;
    completed?: boolean | null;
    updatedAt?: Date | null;
  }>;
};

export type PingQueryVariables = Exact<{ [key: string]: never }>;

export type PingQuery = {
  __typename?: 'Query';
  ping?: {
    __typename?: 'Account';
    id: number;
    email: string;
    role?: string | null;
    isOnboarded?: boolean | null;
    emailConfirmed?: boolean | null;
    preference?: {
      __typename?: 'Preference';
      crmPreference?: any | null;
      applicationPreference?: any | null;
    } | null;
    contact?: {
      __typename?: 'Contact';
      id: number;
      email: string;
      fullName?: string | null;
    } | null;
  } | null;
};

export type ReviewQueryVariables = Exact<{
  applicationId: Scalars['Int']['input'];
  reviewerId: Scalars['Int']['input'];
}>;

export type ReviewQuery = {
  __typename?: 'Query';
  review?: {
    __typename?: 'SeniorReview';
    hasConflict: boolean;
    commercialScore: number;
    commercialComment?: string | null;
    dealTermsScore: number;
    dealTermsComment?: string | null;
    evaluationSummaryScore: number;
    evaluationSummary?: string | null;
    feasibilityScore: number;
    feasibilityComment?: string | null;
    ipDefensibilityScore: number;
    ipDefensibilityComment?: string | null;
    longevityRelevanceScore: number;
    longevityRelevanceComment?: string | null;
    noveltyScore: number;
    noveltyComment?: string | null;
    returnProfile?: ReviewReturnProfile | null;
    recommendation?: ReviewRecommendation | null;
    reviewDealType: ReviewDealType;
    teamExpertiseScore: number;
    teamExpertiseComment?: string | null;
    wouldEndorse?: string | null;
    reviewer?: {
      __typename?: 'Account';
      id: number;
      email: string;
      role?: string | null;
      contact?: { __typename?: 'Contact'; fullName?: string | null } | null;
    } | null;
  } | null;
};

export type StaffQueryVariables = Exact<{ [key: string]: never }>;

export type StaffQuery = {
  __typename?: 'Query';
  staff: Array<{
    __typename?: 'Account';
    walletAddress?: string | null;
    id: number;
  }>;
};

export type ValidateResetTokenQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type ValidateResetTokenQuery = {
  __typename?: 'Query';
  validateResetPasswordToken: boolean;
};

export const ApplyDocument = gql`
  mutation Apply($input: ApplicationInput!) {
    apply(input: $input) {
      id
      applicationType
      countryCode
      createdAt
      dealStage
      fundingRaised
      fundingRequired
      icdCodes
      phase
      priority
      website
      nihCategories
      nihNotCovered
      patentStatus
      synopsis
      title
      answers {
        text
        question {
          id
          title
        }
      }
      members {
        email
        fullName
      }
      referrer {
        email
        fullName
        id
        phoneNumber
      }
      applicant {
        id
        email
        fullName
        phoneNumber
        organizations {
          role
          organization {
            description
            name
            website
          }
        }
      }
    }
  }
`;
export type ApplyMutationFn = Apollo.MutationFunction<
  ApplyMutation,
  ApplyMutationVariables
>;

/**
 * __useApplyMutation__
 *
 * To run a mutation, you first call `useApplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyMutation, { data, loading, error }] = useApplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyMutation,
    ApplyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApplyMutation, ApplyMutationVariables>(
    ApplyDocument,
    options,
  );
}
export type ApplyMutationHookResult = ReturnType<typeof useApplyMutation>;
export type ApplyMutationResult = Apollo.MutationResult<ApplyMutation>;
export type ApplyMutationOptions = Apollo.BaseMutationOptions<
  ApplyMutation,
  ApplyMutationVariables
>;
export const ConfirmEmailDocument = gql`
  mutation ConfirmEmail($token: String!) {
    confirmEmail(token: $token)
  }
`;
export type ConfirmEmailMutationFn = Apollo.MutationFunction<
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables
>;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmEmailMutation,
    ConfirmEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmEmailMutation,
    ConfirmEmailMutationVariables
  >(ConfirmEmailDocument, options);
}
export type ConfirmEmailMutationHookResult = ReturnType<
  typeof useConfirmEmailMutation
>;
export type ConfirmEmailMutationResult =
  Apollo.MutationResult<ConfirmEmailMutation>;
export type ConfirmEmailMutationOptions = Apollo.BaseMutationOptions<
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables
>;
export const CreateContactDocument = gql`
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      id
      createdAt
      email
      fullName
      walletAddress
      areaOfExpertise
      discordId
      diversity
      phoneNumber
      socialMedia
      notes
      vitaRoles
      twitterHandle
    }
  }
`;
export type CreateContactMutationFn = Apollo.MutationFunction<
  CreateContactMutation,
  CreateContactMutationVariables
>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContactMutation,
    CreateContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateContactMutation,
    CreateContactMutationVariables
  >(CreateContactDocument, options);
}
export type CreateContactMutationHookResult = ReturnType<
  typeof useCreateContactMutation
>;
export type CreateContactMutationResult =
  Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<
  CreateContactMutation,
  CreateContactMutationVariables
>;
export const CreateDiscordVoteDocument = gql`
  mutation CreateDiscordVote($appId: Int!) {
    createDiscordVoting(applicationId: $appId) {
      messageId
      upvotes
      downvotes
    }
  }
`;
export type CreateDiscordVoteMutationFn = Apollo.MutationFunction<
  CreateDiscordVoteMutation,
  CreateDiscordVoteMutationVariables
>;

/**
 * __useCreateDiscordVoteMutation__
 *
 * To run a mutation, you first call `useCreateDiscordVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDiscordVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDiscordVoteMutation, { data, loading, error }] = useCreateDiscordVoteMutation({
 *   variables: {
 *      appId: // value for 'appId'
 *   },
 * });
 */
export function useCreateDiscordVoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDiscordVoteMutation,
    CreateDiscordVoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDiscordVoteMutation,
    CreateDiscordVoteMutationVariables
  >(CreateDiscordVoteDocument, options);
}
export type CreateDiscordVoteMutationHookResult = ReturnType<
  typeof useCreateDiscordVoteMutation
>;
export type CreateDiscordVoteMutationResult =
  Apollo.MutationResult<CreateDiscordVoteMutation>;
export type CreateDiscordVoteMutationOptions = Apollo.BaseMutationOptions<
  CreateDiscordVoteMutation,
  CreateDiscordVoteMutationVariables
>;
export const CreateWgUpdateDocument = gql`
  mutation CreateWgUpdate($input: CreateWgUpdateInput!) {
    createWgUpdate(input: $input) {
      account {
        id
        role
        contact {
          fullName
        }
      }
      blocking
      text
    }
  }
`;
export type CreateWgUpdateMutationFn = Apollo.MutationFunction<
  CreateWgUpdateMutation,
  CreateWgUpdateMutationVariables
>;

/**
 * __useCreateWgUpdateMutation__
 *
 * To run a mutation, you first call `useCreateWgUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWgUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWgUpdateMutation, { data, loading, error }] = useCreateWgUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWgUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWgUpdateMutation,
    CreateWgUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWgUpdateMutation,
    CreateWgUpdateMutationVariables
  >(CreateWgUpdateDocument, options);
}
export type CreateWgUpdateMutationHookResult = ReturnType<
  typeof useCreateWgUpdateMutation
>;
export type CreateWgUpdateMutationResult =
  Apollo.MutationResult<CreateWgUpdateMutation>;
export type CreateWgUpdateMutationOptions = Apollo.BaseMutationOptions<
  CreateWgUpdateMutation,
  CreateWgUpdateMutationVariables
>;
export const SendResetPasswordDocument = gql`
  mutation SendResetPassword($email: String!) {
    sendResetPassword(email: $email)
  }
`;
export type SendResetPasswordMutationFn = Apollo.MutationFunction<
  SendResetPasswordMutation,
  SendResetPasswordMutationVariables
>;

/**
 * __useSendResetPasswordMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordMutation, { data, loading, error }] = useSendResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendResetPasswordMutation,
    SendResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendResetPasswordMutation,
    SendResetPasswordMutationVariables
  >(SendResetPasswordDocument, options);
}
export type SendResetPasswordMutationHookResult = ReturnType<
  typeof useSendResetPasswordMutation
>;
export type SendResetPasswordMutationResult =
  Apollo.MutationResult<SendResetPasswordMutation>;
export type SendResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  SendResetPasswordMutation,
  SendResetPasswordMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($input: LoginPayload!) {
    login(input: $input) {
      id
      email
      role
      isOnboarded
      emailConfirmed
      preference {
        applicationPreference
        crmPreference
      }
      contact {
        id
        email
        fullName
      }
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const LogoutDocument = gql`
  mutation Logout {
    logout {
      id
      email
      role
      contact {
        id
        email
      }
    }
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options,
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const RegisterDocument = gql`
  mutation Register($input: RegisterPayload!) {
    registerAccount(input: $input) {
      id
      email
      role
      isOnboarded
      emailConfirmed
      contact {
        id
        email
        fullName
      }
      preference {
        applicationPreference
        crmPreference
      }
    }
  }
`;
export type RegisterMutationFn = Apollo.MutationFunction<
  RegisterMutation,
  RegisterMutationVariables
>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterMutation,
    RegisterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(
    RegisterDocument,
    options,
  );
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<
  RegisterMutation,
  RegisterMutationVariables
>;
export const ResendConfirmationEmailDocument = gql`
  mutation ResendConfirmationEmail {
    resendConfirmEmail
  }
`;
export type ResendConfirmationEmailMutationFn = Apollo.MutationFunction<
  ResendConfirmationEmailMutation,
  ResendConfirmationEmailMutationVariables
>;

/**
 * __useResendConfirmationEmailMutation__
 *
 * To run a mutation, you first call `useResendConfirmationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmationEmailMutation, { data, loading, error }] = useResendConfirmationEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendConfirmationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendConfirmationEmailMutation,
    ResendConfirmationEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendConfirmationEmailMutation,
    ResendConfirmationEmailMutationVariables
  >(ResendConfirmationEmailDocument, options);
}
export type ResendConfirmationEmailMutationHookResult = ReturnType<
  typeof useResendConfirmationEmailMutation
>;
export type ResendConfirmationEmailMutationResult =
  Apollo.MutationResult<ResendConfirmationEmailMutation>;
export type ResendConfirmationEmailMutationOptions = Apollo.BaseMutationOptions<
  ResendConfirmationEmailMutation,
  ResendConfirmationEmailMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($newPassword: String!, $token: String!) {
    resetPassword(newPassword: $newPassword, token: $token)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const SavePreferenceDocument = gql`
  mutation SavePreference($input: SavePreferenceInput!) {
    savePreference(input: $input) {
      applicationPreference
      crmPreference
    }
  }
`;
export type SavePreferenceMutationFn = Apollo.MutationFunction<
  SavePreferenceMutation,
  SavePreferenceMutationVariables
>;

/**
 * __useSavePreferenceMutation__
 *
 * To run a mutation, you first call `useSavePreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePreferenceMutation, { data, loading, error }] = useSavePreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SavePreferenceMutation,
    SavePreferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SavePreferenceMutation,
    SavePreferenceMutationVariables
  >(SavePreferenceDocument, options);
}
export type SavePreferenceMutationHookResult = ReturnType<
  typeof useSavePreferenceMutation
>;
export type SavePreferenceMutationResult =
  Apollo.MutationResult<SavePreferenceMutation>;
export type SavePreferenceMutationOptions = Apollo.BaseMutationOptions<
  SavePreferenceMutation,
  SavePreferenceMutationVariables
>;
export const SearchAccountDocument = gql`
  mutation SearchAccount($searchText: String!, $onlyStaff: Boolean) {
    searchAccount(searchString: $searchText, onlyStaff: $onlyStaff) {
      id
      email
      contact {
        id
        fullName
        email
      }
    }
  }
`;
export type SearchAccountMutationFn = Apollo.MutationFunction<
  SearchAccountMutation,
  SearchAccountMutationVariables
>;

/**
 * __useSearchAccountMutation__
 *
 * To run a mutation, you first call `useSearchAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSearchAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [searchAccountMutation, { data, loading, error }] = useSearchAccountMutation({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      onlyStaff: // value for 'onlyStaff'
 *   },
 * });
 */
export function useSearchAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SearchAccountMutation,
    SearchAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SearchAccountMutation,
    SearchAccountMutationVariables
  >(SearchAccountDocument, options);
}
export type SearchAccountMutationHookResult = ReturnType<
  typeof useSearchAccountMutation
>;
export type SearchAccountMutationResult =
  Apollo.MutationResult<SearchAccountMutation>;
export type SearchAccountMutationOptions = Apollo.BaseMutationOptions<
  SearchAccountMutation,
  SearchAccountMutationVariables
>;
export const UpdateAccountDocument = gql`
  mutation UpdateAccount($input: UpdateAccountPayload!) {
    updateAccount(input: $input) {
      id
      email
      role
      isOnboarded
      emailConfirmed
      contact {
        id
        email
        fullName
      }
    }
  }
`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountMutation,
    UpdateAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccountMutation,
    UpdateAccountMutationVariables
  >(UpdateAccountDocument, options);
}
export type UpdateAccountMutationHookResult = ReturnType<
  typeof useUpdateAccountMutation
>;
export type UpdateAccountMutationResult =
  Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;
export const UpdateApplicationDocument = gql`
  mutation UpdateApplication($input: UpdateApplicationInput!) {
    updateApplication(input: $input) {
      id
      title
      synopsis
      website
      countryCode
      discourseUrl
      snapshotUrl
      applicationType
      patentStatus
      dealStage
      phase
      priority
      icdCodes
      nihCategories
      nihNotCovered
      fundingRequired
      fundingRaised
      dataroom {
        id
        dataRoomUrl
        files {
          id
          url
          name
          type
          path
        }
      }
      dealTypes {
        dealType
      }
      answers {
        question {
          id
          title
        }
        text
      }
      workingGroup {
        isShepherd
        connected
        account {
          id
          email
          contact {
            id
            fullName
            email
          }
        }
      }
      applicant {
        id
        fullName
        email
        organizations {
          role
          organization {
            name
            description
            website
          }
        }
      }
      members {
        id
        fullName
        email
        organizations {
          role
          organization {
            name
            description
            website
          }
        }
      }
      referrer {
        id
        fullName
        email
        organizations {
          role
          organization {
            name
            description
            website
          }
        }
      }
    }
  }
`;
export type UpdateApplicationMutationFn = Apollo.MutationFunction<
  UpdateApplicationMutation,
  UpdateApplicationMutationVariables
>;

/**
 * __useUpdateApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationMutation, { data, loading, error }] = useUpdateApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationMutation,
    UpdateApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationMutation,
    UpdateApplicationMutationVariables
  >(UpdateApplicationDocument, options);
}
export type UpdateApplicationMutationHookResult = ReturnType<
  typeof useUpdateApplicationMutation
>;
export type UpdateApplicationMutationResult =
  Apollo.MutationResult<UpdateApplicationMutation>;
export type UpdateApplicationMutationOptions = Apollo.BaseMutationOptions<
  UpdateApplicationMutation,
  UpdateApplicationMutationVariables
>;
export const UpdateContactDocument = gql`
  mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      description
      discordId
      email
      fullName
      phoneNumber
      id
      diversity
      areaOfExpertise
      socialMedia
      twitterHandle
      vitaRoles
      notes
      walletAddress
      workingGroupMembership {
        workingGroup
      }
      organizations {
        role
        department
        organization {
          description
          id
          name
          website
        }
      }
      account {
        id
        role
        isOnboarded
        emailConfirmed
      }
    }
  }
`;
export type UpdateContactMutationFn = Apollo.MutationFunction<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContactMutation,
    UpdateContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContactMutation,
    UpdateContactMutationVariables
  >(UpdateContactDocument, options);
}
export type UpdateContactMutationHookResult = ReturnType<
  typeof useUpdateContactMutation
>;
export type UpdateContactMutationResult =
  Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;
export const UpsertReviewDocument = gql`
  mutation UpsertReview($input: SeniorReviewInput!) {
    upsertReview(input: $input) {
      hasConflict
      reviewDealType
      teamExpertiseScore
      teamExpertiseComment
      feasibilityScore
      feasibilityComment
      commercialScore
      commercialComment
      noveltyScore
      noveltyComment
      ipDefensibilityScore
      ipDefensibilityComment
      longevityRelevanceScore
      longevityRelevanceComment
      dealTermsScore
      dealTermsComment
      evaluationSummaryScore
      evaluationSummary
      returnProfile
      wouldEndorse
      reviewer {
        contact {
          fullName
        }
      }
    }
  }
`;
export type UpsertReviewMutationFn = Apollo.MutationFunction<
  UpsertReviewMutation,
  UpsertReviewMutationVariables
>;

/**
 * __useUpsertReviewMutation__
 *
 * To run a mutation, you first call `useUpsertReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertReviewMutation, { data, loading, error }] = useUpsertReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertReviewMutation,
    UpsertReviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertReviewMutation,
    UpsertReviewMutationVariables
  >(UpsertReviewDocument, options);
}
export type UpsertReviewMutationHookResult = ReturnType<
  typeof useUpsertReviewMutation
>;
export type UpsertReviewMutationResult =
  Apollo.MutationResult<UpsertReviewMutation>;
export type UpsertReviewMutationOptions = Apollo.BaseMutationOptions<
  UpsertReviewMutation,
  UpsertReviewMutationVariables
>;
export const AccountDocument = gql`
  query Account {
    account {
      email
      id
      walletAddress
      contact {
        fullName
      }
    }
  }
`;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<AccountQuery, AccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountQuery, AccountQueryVariables>(
    AccountDocument,
    options,
  );
}
export function useAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountQuery,
    AccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountQuery, AccountQueryVariables>(
    AccountDocument,
    options,
  );
}
export function useAccountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AccountQuery,
    AccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AccountQuery, AccountQueryVariables>(
    AccountDocument,
    options,
  );
}
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountSuspenseQueryHookResult = ReturnType<
  typeof useAccountSuspenseQuery
>;
export type AccountQueryResult = Apollo.QueryResult<
  AccountQuery,
  AccountQueryVariables
>;
export const AccountApplicationReviewDocument = gql`
  query AccountApplicationReview($applicationId: Int!) {
    accountApplicationReview(applicationId: $applicationId) {
      hasConflict
      reviewDealType
      teamExpertiseScore
      teamExpertiseComment
      feasibilityScore
      feasibilityComment
      commercialScore
      commercialComment
      noveltyScore
      noveltyComment
      ipDefensibilityScore
      ipDefensibilityComment
      longevityRelevanceScore
      longevityRelevanceComment
      dealTermsScore
      dealTermsComment
      evaluationSummaryScore
      evaluationSummary
      returnProfile
      wouldEndorse
      reviewer {
        contact {
          fullName
        }
      }
    }
  }
`;

/**
 * __useAccountApplicationReviewQuery__
 *
 * To run a query within a React component, call `useAccountApplicationReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountApplicationReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountApplicationReviewQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useAccountApplicationReviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountApplicationReviewQuery,
    AccountApplicationReviewQueryVariables
  > &
    (
      | { variables: AccountApplicationReviewQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountApplicationReviewQuery,
    AccountApplicationReviewQueryVariables
  >(AccountApplicationReviewDocument, options);
}
export function useAccountApplicationReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountApplicationReviewQuery,
    AccountApplicationReviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountApplicationReviewQuery,
    AccountApplicationReviewQueryVariables
  >(AccountApplicationReviewDocument, options);
}
export function useAccountApplicationReviewSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AccountApplicationReviewQuery,
    AccountApplicationReviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AccountApplicationReviewQuery,
    AccountApplicationReviewQueryVariables
  >(AccountApplicationReviewDocument, options);
}
export type AccountApplicationReviewQueryHookResult = ReturnType<
  typeof useAccountApplicationReviewQuery
>;
export type AccountApplicationReviewLazyQueryHookResult = ReturnType<
  typeof useAccountApplicationReviewLazyQuery
>;
export type AccountApplicationReviewSuspenseQueryHookResult = ReturnType<
  typeof useAccountApplicationReviewSuspenseQuery
>;
export type AccountApplicationReviewQueryResult = Apollo.QueryResult<
  AccountApplicationReviewQuery,
  AccountApplicationReviewQueryVariables
>;
export const ApplicationDocument = gql`
  query Application($id: Int!) {
    application(id: $id) {
      id
      applicationType
      countryCode
      dealStage
      createdAt
      updatedAt
      dealTypes {
        dealType
      }
      discordVoting {
        messageId
        upvotes
        downvotes
      }
      discourseUrl
      snapshotUrl
      fundingRequired
      fundingRaised
      icdCodes
      website
      synopsis
      title
      patentStatus
      phase
      priority
      nihCategories
      nihNotCovered
      answers {
        question {
          id
          title
        }
        text
      }
      workingGroup {
        id
        connected
        isShepherd
        account {
          id
          email
          contact {
            id
            fullName
            email
          }
        }
      }
      workingGroupUpdates {
        text
        blocking
        account {
          id
          role
          contact {
            fullName
          }
        }
        createdAt
      }
      applicant {
        id
        fullName
        email
        organizations {
          role
          organization {
            name
            description
            website
          }
        }
      }
      members {
        id
        fullName
        email
        organizations {
          role
          organization {
            name
            description
            website
          }
        }
      }
      referrer {
        id
        fullName
        email
        organizations {
          role
          organization {
            name
            description
            website
          }
        }
      }
      dataroom {
        dataRoomUrl
        files {
          name
          path
          type
          url
        }
      }
      reviews {
        applicationId
        completed
        evaluationSummaryScore
        teamExpertiseScore
        commercialScore
        dealTermsScore
        feasibilityScore
        ipDefensibilityScore
        hasConflict
        updatedAt
        reviewer {
          id
          contact {
            fullName
          }
        }
      }
    }
  }
`;

/**
 * __useApplicationQuery__
 *
 * To run a query within a React component, call `useApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApplicationQuery,
    ApplicationQueryVariables
  > &
    (
      | { variables: ApplicationQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApplicationQuery, ApplicationQueryVariables>(
    ApplicationDocument,
    options,
  );
}
export function useApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationQuery,
    ApplicationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApplicationQuery, ApplicationQueryVariables>(
    ApplicationDocument,
    options,
  );
}
export function useApplicationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ApplicationQuery,
    ApplicationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ApplicationQuery, ApplicationQueryVariables>(
    ApplicationDocument,
    options,
  );
}
export type ApplicationQueryHookResult = ReturnType<typeof useApplicationQuery>;
export type ApplicationLazyQueryHookResult = ReturnType<
  typeof useApplicationLazyQuery
>;
export type ApplicationSuspenseQueryHookResult = ReturnType<
  typeof useApplicationSuspenseQuery
>;
export type ApplicationQueryResult = Apollo.QueryResult<
  ApplicationQuery,
  ApplicationQueryVariables
>;
export const ApplicationReviewsDocument = gql`
  query ApplicationReviews($applicationId: Int!) {
    applicationReviews(applicationId: $applicationId) {
      applicationId
      hasConflict
      reviewDealType
      recommendation
      teamExpertiseScore
      teamExpertiseComment
      feasibilityScore
      feasibilityComment
      commercialScore
      commercialComment
      noveltyScore
      noveltyComment
      ipDefensibilityScore
      ipDefensibilityComment
      longevityRelevanceScore
      longevityRelevanceComment
      dealTermsScore
      dealTermsComment
      evaluationSummaryScore
      evaluationSummary
      returnProfile
      wouldEndorse
      completed
      reviewer {
        id
      }
    }
  }
`;

/**
 * __useApplicationReviewsQuery__
 *
 * To run a query within a React component, call `useApplicationReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationReviewsQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useApplicationReviewsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApplicationReviewsQuery,
    ApplicationReviewsQueryVariables
  > &
    (
      | { variables: ApplicationReviewsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApplicationReviewsQuery,
    ApplicationReviewsQueryVariables
  >(ApplicationReviewsDocument, options);
}
export function useApplicationReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationReviewsQuery,
    ApplicationReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApplicationReviewsQuery,
    ApplicationReviewsQueryVariables
  >(ApplicationReviewsDocument, options);
}
export function useApplicationReviewsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ApplicationReviewsQuery,
    ApplicationReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ApplicationReviewsQuery,
    ApplicationReviewsQueryVariables
  >(ApplicationReviewsDocument, options);
}
export type ApplicationReviewsQueryHookResult = ReturnType<
  typeof useApplicationReviewsQuery
>;
export type ApplicationReviewsLazyQueryHookResult = ReturnType<
  typeof useApplicationReviewsLazyQuery
>;
export type ApplicationReviewsSuspenseQueryHookResult = ReturnType<
  typeof useApplicationReviewsSuspenseQuery
>;
export type ApplicationReviewsQueryResult = Apollo.QueryResult<
  ApplicationReviewsQuery,
  ApplicationReviewsQueryVariables
>;
export const ApplicationsDocument = gql`
  query Applications($input: FilterInput!) {
    applications(input: $input) {
      applications {
        id
        title
        synopsis
        website
        countryCode
        discourseUrl
        snapshotUrl
        patentStatus
        dealStage
        phase
        priority
        icdCodes
        nihCategories
        nihNotCovered
        fundingRequired
        fundingRaised
        createdAt
        updatedAt
        applicationType
        dealTypes {
          dealType
        }
        workingGroup {
          id
          isShepherd
          connected
          account {
            id
            email
            contact {
              id
              email
              fullName
            }
          }
        }
        applicant {
          id
          fullName
          email
          vitaRoles
          areaOfExpertise
          organizations {
            role
            organization {
              name
              description
              website
            }
          }
        }
        members {
          id
          fullName
          email
          vitaRoles
          areaOfExpertise
          organizations {
            role
            organization {
              name
              description
              website
            }
          }
        }
        referrer {
          id
          fullName
          email
          vitaRoles
          areaOfExpertise
          organizations {
            role
            organization {
              name
              description
              website
            }
          }
        }
      }
      count
    }
  }
`;

/**
 * __useApplicationsQuery__
 *
 * To run a query within a React component, call `useApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplicationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApplicationsQuery,
    ApplicationsQueryVariables
  > &
    (
      | { variables: ApplicationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApplicationsQuery, ApplicationsQueryVariables>(
    ApplicationsDocument,
    options,
  );
}
export function useApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationsQuery,
    ApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApplicationsQuery, ApplicationsQueryVariables>(
    ApplicationsDocument,
    options,
  );
}
export function useApplicationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ApplicationsQuery,
    ApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ApplicationsQuery, ApplicationsQueryVariables>(
    ApplicationsDocument,
    options,
  );
}
export type ApplicationsQueryHookResult = ReturnType<
  typeof useApplicationsQuery
>;
export type ApplicationsLazyQueryHookResult = ReturnType<
  typeof useApplicationsLazyQuery
>;
export type ApplicationsSuspenseQueryHookResult = ReturnType<
  typeof useApplicationsSuspenseQuery
>;
export type ApplicationsQueryResult = Apollo.QueryResult<
  ApplicationsQuery,
  ApplicationsQueryVariables
>;
export const ContactDocument = gql`
  query Contact($contactId: Int!) {
    contact(contactId: $contactId) {
      id
      areaOfExpertise
      diversity
      description
      discordId
      email
      fullName
      location
      notes
      phoneNumber
      socialMedia
      twitterHandle
      vitaRoles
      walletAddress
      account {
        role
        isOnboarded
      }
      organizations {
        role
        department
        organization {
          id
          name
          website
          description
        }
      }
      leading {
        id
        title
        phase
        dealStage
      }
      memberOf {
        id
        title
        phase
        dealStage
      }
      referrals {
        id
        title
        phase
        dealStage
      }
      updatedAt
      createdAt
    }
  }
`;

/**
 * __useContactQuery__
 *
 * To run a query within a React component, call `useContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useContactQuery(
  baseOptions: Apollo.QueryHookOptions<ContactQuery, ContactQueryVariables> &
    ({ variables: ContactQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContactQuery, ContactQueryVariables>(
    ContactDocument,
    options,
  );
}
export function useContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContactQuery,
    ContactQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContactQuery, ContactQueryVariables>(
    ContactDocument,
    options,
  );
}
export function useContactSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ContactQuery,
    ContactQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ContactQuery, ContactQueryVariables>(
    ContactDocument,
    options,
  );
}
export type ContactQueryHookResult = ReturnType<typeof useContactQuery>;
export type ContactLazyQueryHookResult = ReturnType<typeof useContactLazyQuery>;
export type ContactSuspenseQueryHookResult = ReturnType<
  typeof useContactSuspenseQuery
>;
export type ContactQueryResult = Apollo.QueryResult<
  ContactQuery,
  ContactQueryVariables
>;
export const ContactsDocument = gql`
  query Contacts($input: ContactsFilterInput!) {
    contacts(input: $input) {
      contacts {
        description
        discordId
        email
        fullName
        phoneNumber
        id
        socialMedia
        twitterHandle
        vitaRoles
        walletAddress
        workingGroupMembership {
          workingGroup
        }
        organizations {
          role
          department
          organization {
            description
            id
            name
            website
          }
        }
        account {
          id
          role
          isOnboarded
          emailConfirmed
        }
      }
      count
    }
  }
`;

/**
 * __useContactsQuery__
 *
 * To run a query within a React component, call `useContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContactsQuery(
  baseOptions: Apollo.QueryHookOptions<ContactsQuery, ContactsQueryVariables> &
    ({ variables: ContactsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContactsQuery, ContactsQueryVariables>(
    ContactsDocument,
    options,
  );
}
export function useContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContactsQuery,
    ContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContactsQuery, ContactsQueryVariables>(
    ContactsDocument,
    options,
  );
}
export function useContactsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ContactsQuery,
    ContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ContactsQuery, ContactsQueryVariables>(
    ContactsDocument,
    options,
  );
}
export type ContactsQueryHookResult = ReturnType<typeof useContactsQuery>;
export type ContactsLazyQueryHookResult = ReturnType<
  typeof useContactsLazyQuery
>;
export type ContactsSuspenseQueryHookResult = ReturnType<
  typeof useContactsSuspenseQuery
>;
export type ContactsQueryResult = Apollo.QueryResult<
  ContactsQuery,
  ContactsQueryVariables
>;
export const KpisDocument = gql`
  query Kpis($from: Date!, $to: Date!) {
    kpis(from: $from, to: $to) {
      deals {
        date
        count
      }
      funnel {
        name
        count
      }
      shepherds {
        accountId
        fullName
        count
        funded
        rejected
      }
      flowFunnel {
        name
        count
      }
    }
  }
`;

/**
 * __useKpisQuery__
 *
 * To run a query within a React component, call `useKpisQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpisQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useKpisQuery(
  baseOptions: Apollo.QueryHookOptions<KpisQuery, KpisQueryVariables> &
    ({ variables: KpisQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KpisQuery, KpisQueryVariables>(KpisDocument, options);
}
export function useKpisLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KpisQuery, KpisQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KpisQuery, KpisQueryVariables>(
    KpisDocument,
    options,
  );
}
export function useKpisSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<KpisQuery, KpisQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KpisQuery, KpisQueryVariables>(
    KpisDocument,
    options,
  );
}
export type KpisQueryHookResult = ReturnType<typeof useKpisQuery>;
export type KpisLazyQueryHookResult = ReturnType<typeof useKpisLazyQuery>;
export type KpisSuspenseQueryHookResult = ReturnType<
  typeof useKpisSuspenseQuery
>;
export type KpisQueryResult = Apollo.QueryResult<KpisQuery, KpisQueryVariables>;
export const MyReviewsDocument = gql`
  query MyReviews {
    myReviews {
      hasConflict
      applicationId
      completed
      updatedAt
    }
  }
`;

/**
 * __useMyReviewsQuery__
 *
 * To run a query within a React component, call `useMyReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyReviewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyReviewsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyReviewsQuery,
    MyReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyReviewsQuery, MyReviewsQueryVariables>(
    MyReviewsDocument,
    options,
  );
}
export function useMyReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyReviewsQuery,
    MyReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyReviewsQuery, MyReviewsQueryVariables>(
    MyReviewsDocument,
    options,
  );
}
export function useMyReviewsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MyReviewsQuery,
    MyReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MyReviewsQuery, MyReviewsQueryVariables>(
    MyReviewsDocument,
    options,
  );
}
export type MyReviewsQueryHookResult = ReturnType<typeof useMyReviewsQuery>;
export type MyReviewsLazyQueryHookResult = ReturnType<
  typeof useMyReviewsLazyQuery
>;
export type MyReviewsSuspenseQueryHookResult = ReturnType<
  typeof useMyReviewsSuspenseQuery
>;
export type MyReviewsQueryResult = Apollo.QueryResult<
  MyReviewsQuery,
  MyReviewsQueryVariables
>;
export const PingDocument = gql`
  query Ping {
    ping {
      id
      email
      role
      isOnboarded
      emailConfirmed
      preference {
        crmPreference
        applicationPreference
      }
      contact {
        id
        email
        fullName
      }
    }
  }
`;

/**
 * __usePingQuery__
 *
 * To run a query within a React component, call `usePingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePingQuery({
 *   variables: {
 *   },
 * });
 */
export function usePingQuery(
  baseOptions?: Apollo.QueryHookOptions<PingQuery, PingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PingQuery, PingQueryVariables>(PingDocument, options);
}
export function usePingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PingQuery, PingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PingQuery, PingQueryVariables>(
    PingDocument,
    options,
  );
}
export function usePingSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PingQuery, PingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PingQuery, PingQueryVariables>(
    PingDocument,
    options,
  );
}
export type PingQueryHookResult = ReturnType<typeof usePingQuery>;
export type PingLazyQueryHookResult = ReturnType<typeof usePingLazyQuery>;
export type PingSuspenseQueryHookResult = ReturnType<
  typeof usePingSuspenseQuery
>;
export type PingQueryResult = Apollo.QueryResult<PingQuery, PingQueryVariables>;
export const ReviewDocument = gql`
  query Review($applicationId: Int!, $reviewerId: Int!) {
    review(applicationId: $applicationId, reviewerId: $reviewerId) {
      hasConflict
      commercialScore
      commercialComment
      dealTermsScore
      dealTermsComment
      evaluationSummaryScore
      evaluationSummary
      feasibilityScore
      feasibilityComment
      ipDefensibilityScore
      ipDefensibilityComment
      longevityRelevanceScore
      longevityRelevanceComment
      noveltyScore
      noveltyComment
      returnProfile
      recommendation
      reviewDealType
      teamExpertiseScore
      teamExpertiseComment
      wouldEndorse
      reviewer {
        id
        email
        role
        contact {
          fullName
        }
      }
    }
  }
`;

/**
 * __useReviewQuery__
 *
 * To run a query within a React component, call `useReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      reviewerId: // value for 'reviewerId'
 *   },
 * });
 */
export function useReviewQuery(
  baseOptions: Apollo.QueryHookOptions<ReviewQuery, ReviewQueryVariables> &
    ({ variables: ReviewQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReviewQuery, ReviewQueryVariables>(
    ReviewDocument,
    options,
  );
}
export function useReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReviewQuery, ReviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReviewQuery, ReviewQueryVariables>(
    ReviewDocument,
    options,
  );
}
export function useReviewSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ReviewQuery,
    ReviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ReviewQuery, ReviewQueryVariables>(
    ReviewDocument,
    options,
  );
}
export type ReviewQueryHookResult = ReturnType<typeof useReviewQuery>;
export type ReviewLazyQueryHookResult = ReturnType<typeof useReviewLazyQuery>;
export type ReviewSuspenseQueryHookResult = ReturnType<
  typeof useReviewSuspenseQuery
>;
export type ReviewQueryResult = Apollo.QueryResult<
  ReviewQuery,
  ReviewQueryVariables
>;
export const StaffDocument = gql`
  query Staff {
    staff {
      walletAddress
      id
    }
  }
`;

/**
 * __useStaffQuery__
 *
 * To run a query within a React component, call `useStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaffQuery(
  baseOptions?: Apollo.QueryHookOptions<StaffQuery, StaffQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StaffQuery, StaffQueryVariables>(
    StaffDocument,
    options,
  );
}
export function useStaffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StaffQuery, StaffQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StaffQuery, StaffQueryVariables>(
    StaffDocument,
    options,
  );
}
export function useStaffSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StaffQuery,
    StaffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<StaffQuery, StaffQueryVariables>(
    StaffDocument,
    options,
  );
}
export type StaffQueryHookResult = ReturnType<typeof useStaffQuery>;
export type StaffLazyQueryHookResult = ReturnType<typeof useStaffLazyQuery>;
export type StaffSuspenseQueryHookResult = ReturnType<
  typeof useStaffSuspenseQuery
>;
export type StaffQueryResult = Apollo.QueryResult<
  StaffQuery,
  StaffQueryVariables
>;
export const ValidateResetTokenDocument = gql`
  query ValidateResetToken($token: String!) {
    validateResetPasswordToken(token: $token)
  }
`;

/**
 * __useValidateResetTokenQuery__
 *
 * To run a query within a React component, call `useValidateResetTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateResetTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateResetTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useValidateResetTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateResetTokenQuery,
    ValidateResetTokenQueryVariables
  > &
    (
      | { variables: ValidateResetTokenQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ValidateResetTokenQuery,
    ValidateResetTokenQueryVariables
  >(ValidateResetTokenDocument, options);
}
export function useValidateResetTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateResetTokenQuery,
    ValidateResetTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ValidateResetTokenQuery,
    ValidateResetTokenQueryVariables
  >(ValidateResetTokenDocument, options);
}
export function useValidateResetTokenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ValidateResetTokenQuery,
    ValidateResetTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ValidateResetTokenQuery,
    ValidateResetTokenQueryVariables
  >(ValidateResetTokenDocument, options);
}
export type ValidateResetTokenQueryHookResult = ReturnType<
  typeof useValidateResetTokenQuery
>;
export type ValidateResetTokenLazyQueryHookResult = ReturnType<
  typeof useValidateResetTokenLazyQuery
>;
export type ValidateResetTokenSuspenseQueryHookResult = ReturnType<
  typeof useValidateResetTokenSuspenseQuery
>;
export type ValidateResetTokenQueryResult = Apollo.QueryResult<
  ValidateResetTokenQuery,
  ValidateResetTokenQueryVariables
>;

type Properties<T> = Required<{
  [K in keyof T]: z.ZodType<T[K], any, T[K]>;
}>;

type definedNonNullAny = {};

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny =>
  v !== undefined && v !== null;

export const definedNonNullAnySchema = z
  .any()
  .refine((v) => isDefinedNonNullAny(v));

export const AccountRoleSchema = z.nativeEnum(AccountRole);

export const ApplicationPhaseSchema = z.nativeEnum(ApplicationPhase);

export const ApplicationTypeSchema = z.nativeEnum(ApplicationType);

export const DealStageSchema = z.nativeEnum(DealStage);

export const DealTypeSchema = z.nativeEnum(DealType);

export const PatentStatusSchema = z.nativeEnum(PatentStatus);

export const ProjectPrioritySchema = z.nativeEnum(ProjectPriority);

export const ReviewDealTypeSchema = z.nativeEnum(ReviewDealType);

export const ReviewRecommendationSchema = z.nativeEnum(ReviewRecommendation);

export const ReviewReturnProfileSchema = z.nativeEnum(ReviewReturnProfile);

export const SortOrderSchema = z.nativeEnum(SortOrder);

export const StringCaseSensitivitySchema = z.nativeEnum(StringCaseSensitivity);

export const WorkingGroupNamesSchema = z.nativeEnum(WorkingGroupNames);

export function AccountEnumInputSchema(): z.ZodObject<
  Properties<AccountEnumInput>
> {
  return z.object({
    equals: AccountRoleSchema.nullish(),
    in: z.array(AccountRoleSchema.nullable()).nullish(),
  });
}

export function AccountFilterInputSchema(): z.ZodObject<
  Properties<AccountFilterInput>
> {
  return z.object({
    role: z.lazy(() => AccountEnumInputSchema().nullish()),
  });
}

export function AccountOrderInputSchema(): z.ZodObject<
  Properties<AccountOrderInput>
> {
  return z.object({
    id: SortOrderSchema.nullish(),
    isOnboarded: SortOrderSchema.nullish(),
    role: SortOrderSchema.nullish(),
  });
}

export function AnswerInputSchema(): z.ZodObject<Properties<AnswerInput>> {
  return z.object({
    questionId: z.number(),
    text: z.string(),
  });
}

export function ApplicationFilterInputSchema(): z.ZodObject<
  Properties<ApplicationFilterInput>
> {
  return z.object({
    applicant: z.lazy(() => ContactFilterInputSchema().nullish()),
    applicationType: z.lazy(() => StringFilterInputSchema().nullish()),
    assetDevelopmentStage: z.lazy(() => StringFilterInputSchema().nullish()),
    countryCode: z.lazy(() => StringFilterInputSchema().nullish()),
    createdAt: z.lazy(() => DateFilterInputSchema().nullish()),
    dealStage: z.lazy(() => StringFilterInputSchema().nullish()),
    fundingRaised: z.lazy(() => NumberFilterInputSchema().nullish()),
    fundingRequired: z.lazy(() => NumberFilterInputSchema().nullish()),
    id: z.lazy(() => NumberFilterInputSchema().nullish()),
    patentStatus: z.lazy(() => StringFilterInputSchema().nullish()),
    phase: z.lazy(() => StringFilterInputSchema().nullish()),
    priority: z.lazy(() => StringFilterInputSchema().nullish()),
    referrer: z.lazy(() => ContactFilterInputSchema().nullish()),
    title: z.lazy(() => StringFilterInputSchema().nullish()),
    updatedAt: z.lazy(() => DateFilterInputSchema().nullish()),
  });
}

export function ApplicationInputSchema(): z.ZodObject<
  Properties<ApplicationInput>
> {
  return z.object({
    answers: z.array(z.lazy(() => AnswerInputSchema())),
    applicant: z.lazy(() => ContactInputSchema()),
    assetDevelopmentStage: z.string().nullish(),
    countryCode: z.string(),
    fundingRaised: z.number(),
    fundingRequired: z.number(),
    icdCodes: z.string().nullish(),
    members: z.array(z.lazy(() => ContactInputSchema())).nullish(),
    nihCategories: z.string().nullish(),
    nihNotCovered: z.string().nullish(),
    patentStatus: PatentStatusSchema.nullish(),
    referrer: z.lazy(() => ContactInputSchema().nullish()),
    synopsis: z.string(),
    title: z.string(),
    website: z.string().nullish(),
  });
}

export function ApplicationOrderByInputSchema(): z.ZodObject<
  Properties<ApplicationOrderByInput>
> {
  return z.object({
    applicant: z.lazy(() => ContactOrderByInputSchema().nullish()),
    applicationType: SortOrderSchema.nullish(),
    countryCode: SortOrderSchema.nullish(),
    createdAt: SortOrderSchema.nullish(),
    dealStage: SortOrderSchema.nullish(),
    fundingRaised: SortOrderSchema.nullish(),
    fundingRequired: SortOrderSchema.nullish(),
    id: SortOrderSchema.nullish(),
    patentStatus: SortOrderSchema.nullish(),
    phase: SortOrderSchema.nullish(),
    priority: SortOrderSchema.nullish(),
    referrer: z.lazy(() => ContactOrderByInputSchema().nullish()),
    synopsis: SortOrderSchema.nullish(),
    title: SortOrderSchema.nullish(),
    updatedAt: SortOrderSchema.nullish(),
  });
}

export function ContactFilterInputSchema(): z.ZodObject<
  Properties<ContactFilterInput>
> {
  return z.object({
    fullName: z.lazy(() => StringFilterInputSchema().nullish()),
  });
}

export function ContactInputSchema(): z.ZodObject<Properties<ContactInput>> {
  return z.object({
    areaOfExpertise: z.string().nullish(),
    attachment: z.string().nullish(),
    description: z.string().nullish(),
    discordId: z.string().nullish(),
    diversity: z.string().nullish(),
    email: z.string(),
    fullName: z.string().nullish(),
    location: z.string().nullish(),
    notes: z.string().nullish(),
    organization: z.lazy(() => OrganizationInputSchema().nullish()),
    phoneNumber: z.string().nullish(),
    socialMedia: z.string().nullish(),
    vitaRoles: z.string().nullish(),
  });
}

export function ContactOrderByInputSchema(): z.ZodObject<
  Properties<ContactOrderByInput>
> {
  return z.object({
    account: z.lazy(() => AccountOrderInputSchema().nullish()),
    background: SortOrderSchema.nullish(),
    description: SortOrderSchema.nullish(),
    discordId: SortOrderSchema.nullish(),
    email: SortOrderSchema.nullish(),
    fullName: SortOrderSchema.nullish(),
    id: SortOrderSchema.nullish(),
    phoneNumber: SortOrderSchema.nullish(),
    twitterHandle: SortOrderSchema.nullish(),
  });
}

export function ContactWhereInputSchema(): z.ZodObject<
  Properties<ContactWhereInput>
> {
  return z.object({
    account: z.lazy(() => AccountFilterInputSchema().nullish()),
    discordId: z.lazy(() => StringFilterInputSchema().nullish()),
    email: z.lazy(() => StringFilterInputSchema().nullish()),
    fullName: z.lazy(() => StringFilterInputSchema().nullish()),
    id: z.lazy(() => NumberFilterInputSchema().nullish()),
    twitterHandle: z.lazy(() => StringFilterInputSchema().nullish()),
  });
}

export function ContactsFilterInputSchema(): z.ZodObject<
  Properties<ContactsFilterInput>
> {
  return z.object({
    orderBy: z.lazy(() => ContactOrderByInputSchema()),
    searchString: z.string().nullish(),
    skip: z.number().nullish(),
    take: z.number().nullish(),
    where: z.lazy(() => ContactWhereInputSchema().nullish()),
  });
}

export function CreateContactInputSchema(): z.ZodObject<
  Properties<CreateContactInput>
> {
  return z.object({
    areaOfExpertise: z.string().nullish(),
    description: z.string().nullish(),
    discordId: z.string().nullish(),
    diversity: z.string().nullish(),
    email: z.string(),
    fullName: z.string(),
    location: z.string().nullish(),
    notes: z.string().nullish(),
    organization: z.lazy(() => OrganizationInputSchema().nullish()),
    phoneNumber: z.string().nullish(),
    socialMedia: z.string().nullish(),
    twitterHandle: z.string().nullish(),
    vitaRoles: z.string().nullish(),
  });
}

export function CreateWgUpdateInputSchema(): z.ZodObject<
  Properties<CreateWgUpdateInput>
> {
  return z.object({
    applicationId: z.number(),
    blocking: z.boolean(),
    text: z.string(),
  });
}

export function DateFilterInputSchema(): z.ZodObject<
  Properties<DateFilterInput>
> {
  return z.object({
    equals: z.date().nullish(),
    gt: z.date().nullish(),
    lt: z.date().nullish(),
  });
}

export function FileInputSchema(): z.ZodObject<Properties<FileInput>> {
  return z.object({
    name: z.string(),
    type: z.string(),
    url: z.string(),
  });
}

export function FilterInputSchema(): z.ZodObject<Properties<FilterInput>> {
  return z.object({
    orderBy: z.lazy(() => ApplicationOrderByInputSchema()),
    searchString: z.string().nullish(),
    skip: z.number().nullish(),
    take: z.number().nullish(),
    where: z.lazy(() => ApplicationFilterInputSchema().nullish()),
  });
}

export function FloatFilterInputSchema(): z.ZodObject<
  Properties<FloatFilterInput>
> {
  return z.object({
    equals: z.number().nullish(),
    gt: z.number().nullish(),
    gte: z.number().nullish(),
    lt: z.number().nullish(),
    lte: z.number().nullish(),
  });
}

export function LoginPayloadSchema(): z.ZodObject<Properties<LoginPayload>> {
  return z.object({
    email: z.string(),
    password: z.string(),
  });
}

export function NumberFilterInputSchema(): z.ZodObject<
  Properties<NumberFilterInput>
> {
  return z.object({
    equals: z.number().nullish(),
    gt: z.number().nullish(),
    gte: z.number().nullish(),
    lt: z.number().nullish(),
    lte: z.number().nullish(),
  });
}

export function OrganizationInputSchema(): z.ZodObject<
  Properties<OrganizationInput>
> {
  return z.object({
    description: z.string().nullish(),
    name: z.string(),
    role: z.string().nullish(),
    website: z.string().nullish(),
  });
}

export function RegisterPayloadSchema(): z.ZodObject<
  Properties<RegisterPayload>
> {
  return z.object({
    email: z.string(),
    fullName: z.string().nullish(),
    password: z.string(),
  });
}

export function SavePreferenceInputSchema(): z.ZodObject<
  Properties<SavePreferenceInput>
> {
  return z.object({
    applicationPreference: definedNonNullAnySchema.nullish(),
    crmPreference: definedNonNullAnySchema.nullish(),
  });
}

export function SeniorReviewInputSchema(): z.ZodObject<
  Properties<SeniorReviewInput>
> {
  return z.object({
    applicationId: z.number(),
    commercialComment: z.string().nullish(),
    commercialScore: z.number().nullish(),
    dealTermsComment: z.string().nullish(),
    dealTermsScore: z.number().nullish(),
    evaluationSummary: z.string().nullish(),
    evaluationSummaryScore: z.number().nullish(),
    feasibilityComment: z.string().nullish(),
    feasibilityRisks: z.string().nullish(),
    feasibilityScore: z.number().nullish(),
    hasConflict: z.boolean(),
    ipDefensibilityComment: z.string().nullish(),
    ipDefensibilityScore: z.number().nullish(),
    longevityRelevanceComment: z.string().nullish(),
    longevityRelevanceScore: z.number().nullish(),
    noveltyAdvantages: z.string().nullish(),
    noveltyComment: z.string().nullish(),
    noveltyScore: z.number().nullish(),
    recommendation: ReviewRecommendationSchema.nullish(),
    returnProfile: ReviewReturnProfileSchema.nullish(),
    reviewDealType: ReviewDealTypeSchema.nullish(),
    teamExpertiseComment: z.string().nullish(),
    teamExpertiseScore: z.number().nullish(),
    wouldEndorse: z.string().nullish(),
  });
}

export function StringFilterInputSchema(): z.ZodObject<
  Properties<StringFilterInput>
> {
  return z.object({
    contains: z.string().nullish(),
    endsWith: z.string().nullish(),
    equals: z.string().nullish(),
    in: z.array(z.string()).nullish(),
    mode: StringCaseSensitivitySchema.nullish(),
    startsWith: z.string().nullish(),
  });
}

export function UpdateAccountInputSchema(): z.ZodObject<
  Properties<UpdateAccountInput>
> {
  return z.object({
    email: z.string().nullish(),
    isOnboarded: z.boolean().nullish(),
    role: z.string().nullish(),
  });
}

export function UpdateAccountPayloadSchema(): z.ZodObject<
  Properties<UpdateAccountPayload>
> {
  return z.object({
    fullName: z.string().nullish(),
    password: z.string().nullish(),
  });
}

export function UpdateApplicationInputSchema(): z.ZodObject<
  Properties<UpdateApplicationInput>
> {
  return z.object({
    applicationId: z.number(),
    backgroundInformation: z.array(z.lazy(() => FileInputSchema())).nullish(),
    dataRoomUrl: z.string().nullish(),
    dealStage: DealStageSchema.nullish(),
    dealTypes: z.array(DealTypeSchema).nullish(),
    discourseUrl: z.string().nullish(),
    presentationMaterials: z.array(z.lazy(() => FileInputSchema())).nullish(),
    priority: ProjectPrioritySchema.nullish(),
    shepherd: z.lazy(() => WgAccountInputSchema().nullish()),
    snapshotUrl: z.string().nullish(),
    supportingPatents: z.array(z.lazy(() => FileInputSchema())).nullish(),
    workingGroup: z.array(z.lazy(() => WgAccountInputSchema())).nullish(),
  });
}

export function UpdateContactInputSchema(): z.ZodObject<
  Properties<UpdateContactInput>
> {
  return z.object({
    account: z.lazy(() => UpdateAccountInputSchema().nullish()),
    areaOfExpertise: z.string().nullish(),
    background: z.string().nullish(),
    description: z.string().nullish(),
    discordId: z.string().nullish(),
    diversity: z.string().nullish(),
    email: z.string().nullish(),
    fullName: z.string().nullish(),
    id: z.number(),
    notes: z.string().nullish(),
    phoneNumber: z.string().nullish(),
    socialMedia: z.string().nullish(),
    twitterHandle: z.string().nullish(),
    vitaRoles: z.string().nullish(),
  });
}

export function WgAccountInputSchema(): z.ZodObject<
  Properties<WgAccountInput>
> {
  return z.object({
    accountId: z.number(),
    isShepherd: z.boolean(),
  });
}
