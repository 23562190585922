import React from 'react';
import { Badge } from '@chakra-ui/react';
import { DealStage } from '../../graphql/generated';
import formatEnum from '../../utils/formatEnum';

const DealStageBadge: React.FC<{ stage: DealStage }> = ({ stage }) => (
    <Badge
        bg={`dealStage.${stage}`}
        variant='solid'
        px={2}
        py={1}>
        {formatEnum(stage)}
    </Badge>
);

export default DealStageBadge;
