import {
    Button,
    ButtonGroup,
    Center,
    Divider,
    Grid,
    Heading,
    HStack,
    Icon,
    SimpleGrid,
    Spacer,
    Stack,
    Tag,
    TagLabel,
    TagLeftIcon,
    Text,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import ApplicationPhaseBadge from '../../components/Badge/ApplicationPhaseBadge';
import { AiOutlineEdit } from 'react-icons/ai';
import DealStageBadge from '../../components/Badge/DealStageBadge';
import { FaArrowDown, FaArrowUp, FaDiscord, FaMapLocation } from 'react-icons/fa6';
import { FaExternalLinkAlt, FaLongArrowAltUp, FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import lookup from 'country-code-lookup';
import { GrDocumentTest } from 'react-icons/gr';
import formatEnum from '../../utils/formatEnum';
import InfoBox from './components/InfoBox';
import CrmContact from '../../components/CrmContact';
import { GiSheep } from 'react-icons/gi';
import Folders from '../../components/Folders';
import formatCurrency from '../../utils/formatCurrency';
import QuestionAnswer from './components/QuestionAnswer';
import UpdateApplicationModal from './Modals/UpdateApplicationModal';
import EditFilesModal from './Modals/EditFilesModal';
import React, { useContext, useMemo, useState } from 'react';
import { ApplicationPhase, ApplicationQuery, DealStage, useCreateDiscordVoteMutation } from '../../graphql/generated';
import { AuthContext } from '../../components/Providers/AuthProvider';
import StaffUpdates from './StaffUpdates';
import moment from 'moment';
import DataroomLinkModal from './Modals/DataroomLinkModal';
import { HiFolderPlus } from 'react-icons/hi2';
import { TbLinkPlus } from 'react-icons/tb';
import formatDealType from '../../utils/formatDealType';

const ACTION_BG = 'yellow.100';
const REG_BG = 'gray.100';

const PRIORITY_MAP = {
    HIGH: <Tag bg='red'><Icon color='white' as={FaLongArrowAltUp} size={22} title='High'/></Tag>,
    MEDIUM: <Tag bg='orange'><Icon color='white' as={FaArrowUp} size={22} title='Medium' /></Tag>,
    LOW: <Tag bg='green'><Icon color='white' as={FaArrowDown} size={22} title='Low' /></Tag>,
}

enum FORM_FIELDS {
    DEAL_STAGE = 'dealStage',
    DEAL_TYPES = 'dealTypes',
    URLS = 'urls',
    PRIO = 'priority',
    WG = 'workingGroup',
}

interface IApplication {
    application: ApplicationQuery['application'];
    refetchApp: () => void;
}

const WG_INVALID_STATE = [DealStage.DISCORD_VOTE_LIVE, DealStage.DISCORD_VOTE_PASSED];
const WG_INVALID_PHASE = [ApplicationPhase.PHASE_0, ApplicationPhase.PHASE_4];

const ApplicationInfo: React.FC<IApplication> = ({ application, refetchApp }) => {
    const toast = useToast();
    const { account } = useContext(AuthContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenFileModal, onOpen: onOpenFileModal, onClose: onCloseFileModal } = useDisclosure()
    const {
        isOpen: isDataRoomModalOpen,
        onOpen: onOpenDataroomModal,
        onClose: onCloseDataroomModal
    } = useDisclosure()
    const [formFields, setFormFields] = useState<string[]>([]);

    const [createVoting, { loading: creatingVote }] = useCreateDiscordVoteMutation({
        onError(err) {
            toast({
                title: err.message,
                status: 'error',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
        },
        onCompleted() {
            refetchApp();
        }
    });


    const canUpdate = useMemo(() => {
        if (!application || !account) return false;
        const isAdmin = account.role === 'ADMIN';
        const isWgMember = (
            application.workingGroup ?? []
        ).some(wg => wg.account.id === account.id && wg.connected);
        return isAdmin || isWgMember;
    }, [application, account]);

    const canUpdateWg = useMemo(() => {
        if (!application || !account) return false;
        const invalidPhase = WG_INVALID_STATE.includes(application.dealStage)
            || WG_INVALID_PHASE.includes(application.phase);
        const isShepherd = (
            application.workingGroup ?? []
        ).some(wg => wg.account.id === account.id && wg.connected && wg.isShepherd);
        const isAdmin = account.role === 'ADMIN';
        return !invalidPhase && (isShepherd || isAdmin);
    }, [application, account]);

    const canUpdateUrls = useMemo(() => (
        canUpdate
        && [DealStage.PENDING_TEAM_ASSIGNMENT, DealStage.PENDING_SENIOR_REVIEWS].includes(application.dealStage)),
    [application, canUpdate]
    );

    return (
        <Stack gap={0} p={0} w='100%'>
            <Heading size='md' mt={0} textAlign='center'>
                { application.title }
            </Heading>


            <Stack px={0}>
                <HStack gap={3} textAlign='center' mt={3}>
                    <Spacer />
                    <ApplicationPhaseBadge phase={application.phase}/>
                    <Divider w={20} />
                    <Button
                        variant='actionEdit'
                        borderRadius={1}
                        color='gray.600'
                        size='xs'
                        isDisabled={!canUpdate}
                        onClick={() => {
                            setFormFields([FORM_FIELDS.DEAL_STAGE, FORM_FIELDS.PRIO]);
                            onOpen();
                        }}>
                        <Icon as={AiOutlineEdit} />
                    </Button>
                    <Divider w={20} />
                    <DealStageBadge stage={application.dealStage} />
                    <Spacer />
                </HStack>
                <Center gap={4} fontWeight='normal' w='100%' mb={2}>
                    <Text size='xs' color='gray'>
                        Created: { moment(application.createdAt).format('DD MMM YY') }
                    </Text>
                    <Text size='xs' color='gray'>
                        Last update: { moment(application.updatedAt).fromNow() }
                    </Text>
                </Center>
                <HStack my={2}>
                    <Spacer />
                    { PRIORITY_MAP[application.priority] }
                    { !application.discordVoting &&
                        <Button
                            size='xxs'
                            variant='actionEdit'
                            isLoading={creatingVote}
                            isDisabled={!canUpdate}
                            onClick={() => createVoting({ variables: { appId: application.id } })}>
                            <Icon as={FaDiscord} mr={1} />
                            Create
                        </Button>
                    }
                    { application.discordVoting &&
                        <ButtonGroup size='xxs' isAttached variant='ghost' borderWidth={1}>
                            <Button colorScheme='green' cursor='default'>
                                <Icon as={FaThumbsUp} mr={1} />
                                <Text>{ application.discordVoting.upvotes }</Text>
                            </Button>
                            <Button colorScheme='red' cursor='default'>
                                <Icon as={FaThumbsDown} mr={1} />
                                <Text>{ application.discordVoting.downvotes }</Text>
                            </Button>
                        </ButtonGroup>
                    }
                    <Tag bg='green.400' color='white' userSelect='none'>
                        <TagLeftIcon as={FaMapLocation} />
                        <TagLabel>
                            { application.countryCode.trim() != ''
                                ? lookup.byIso(application.countryCode)?.country: 'No Country' }
                        </TagLabel>
                    </Tag>
                    <Tag bgColor='blue.500' color='white' userSelect='none'>
                        <Icon as={GrDocumentTest} />
                        <Text ml={2}>
                            { formatEnum(application.patentStatus) }
                        </Text>
                    </Tag>
                    <Spacer />
                </HStack>
            </Stack>

            <HStack gap={1} mt={2}>
                <Stack alignSelf='flex-start' gap={2} p={0} w='70%' pl={5}>
                    <Grid templateColumns='repeat(3, 2fr)' gap={6} textAlign='center'>
                        <InfoBox bg={canUpdateWg ? ACTION_BG : REG_BG} title='WG'>
                            <SimpleGrid>
                                {application.workingGroup && application
                                    .workingGroup
                                    .filter(wg => wg.connected)
                                    .map(wg => (
                                        wg.connected &&
                                        <CrmContact
                                            contact={wg.account.contact}
                                            key={`wg-${wg.id}`}
                                            extraIcon={wg.isShepherd ? <Icon ml={1} as={GiSheep} /> : undefined}/>
                                    ))}
                            </SimpleGrid>
                            { application.workingGroup?.filter(wg => wg.connected).length === 0 &&
                                <Text color='gray' textAlign='center'>No people assigned</Text>
                            }
                            <Spacer />
                            <Button
                                variant='actionEdit'
                                w='full'
                                size='xs'
                                isDisabled={!canUpdateWg}
                                onClick={()=> {
                                    setFormFields([FORM_FIELDS.WG]);
                                    onOpen();
                                }}>
                                <Icon as={AiOutlineEdit} />
                            </Button>
                        </InfoBox>
                        <InfoBox bg={canUpdate ? ACTION_BG : REG_BG} title='Deal Types'>
                            { application
                                .dealTypes
                                ?.map(({ dealType }) => (
                                    <Text key={dealType}>{formatDealType(dealType)}</Text>
                                ))}
                            { (!application.dealTypes || application.dealTypes?.length === 0) &&
                                <Text textAlign='center' color='gray'>No deal types assigned</Text>
                            }
                            <Spacer />
                            <Button
                                variant='actionEdit'
                                w='full'
                                size='xs'
                                isDisabled={!canUpdate}
                                onClick={()=> {
                                    setFormFields([FORM_FIELDS.DEAL_TYPES]);
                                    onOpen();
                                }}>
                                <Icon as={AiOutlineEdit} />
                            </Button>
                        </InfoBox>
                        <InfoBox bg={canUpdate ? ACTION_BG : REG_BG} title='Data Room'>
                            { application.dataroom &&
                                <Stack gap={1}>
                                    { application.dataroom.dataRoomUrl &&
                                        <>
                                            <Text fontWeight='bold'>
                                                Link
                                            </Text>
                                            <Text
                                                as='a'
                                                color='blue.400'
                                                href={application.dataroom.dataRoomUrl}
                                                target='_blank'
                                                size='sm'>
                                                Dataroom <Icon as={FaExternalLinkAlt} />
                                            </Text>
                                        </>
                                    }
                                    <Text fontWeight='bold'>
                                        Files
                                    </Text>
                                    <Folders files={application.dataroom.files} />
                                </Stack>
                            }
                            <Spacer />
                            <HStack>
                                <Button
                                    variant='actionEdit'
                                    size='xs'
                                    isDisabled={!canUpdate}
                                    onClick={onOpenDataroomModal}>
                                    <Icon as={TbLinkPlus} />
                                </Button>
                                <Button
                                    variant='actionEdit'
                                    w='full'
                                    size='xs'
                                    isDisabled={!canUpdate}
                                    onClick={onOpenFileModal}>
                                    <Icon as={HiFolderPlus} />
                                </Button>
                            </HStack>
                        </InfoBox>
                        <InfoBox bg={REG_BG} title='Applicants'>
                            <CrmContact contact={application.applicant} extraTitle='lead' />
                            { application.members?.map(member => (
                                <CrmContact contact={member} key={`member-${member.id}`} />
                            )) }
                            <CrmContact contact={application.referrer} extraTitle='referrer' />
                        </InfoBox>
                        <InfoBox
                            bg={canUpdateUrls
                                ? ACTION_BG
                                : REG_BG}
                            title='Links'>
                            <Text
                                as='a'
                                cursor={application.website ? 'pointer' : 'forbidden'}
                                display={application.website ? 'block' : 'none'}
                                href={application.website ?? ''}
                                target='_blank'>
                                Website <Icon as={FaExternalLinkAlt} />
                            </Text>
                            <Text
                                as='a'
                                cursor={application.discourseUrl ? 'pointer' : 'forbidden'}
                                display={application.discourseUrl ? 'block' : 'none'}
                                href={application.discourseUrl ?? ''}
                                target='_blank'>
                                Discourse <Icon as={FaExternalLinkAlt} />
                            </Text>
                            <Text
                                as='a'
                                display={application.snapshotUrl ? 'block' : 'none'}
                                cursor={application.snapshotUrl ? 'pointer' : 'forbidden'}
                                href={application.snapshotUrl ?? ''}
                                target='_blank'>
                                Snapshot <Icon as={FaExternalLinkAlt} />
                            </Text>
                            <Spacer />
                            <Button
                                size='xs'
                                w='full'
                                variant='actionEdit'
                                isDisabled={!canUpdateUrls}
                                onClick={()=> {
                                    setFormFields([FORM_FIELDS.URLS]);
                                    onOpen();
                                }}>
                                <Icon as={AiOutlineEdit} />
                            </Button>
                        </InfoBox>
                        <InfoBox align='center' bg={REG_BG} title='Finances'>
                            <Grid
                                gap={1}
                                textAlign='left'
                                templateRows='repeat(2, 1fr)'
                                templateColumns='repeat(2, 1fr)'
                                fontSize='lg'>
                                <Text>Asking</Text>
                                <Text>{ formatCurrency(application.fundingRequired) }</Text>
                                <Text>Raised</Text>
                                <Text>{ formatCurrency(application.fundingRaised) }</Text>
                            </Grid>
                        </InfoBox>
                    </Grid>
                    <InfoBox bg={REG_BG} title='SYNOPSIS'>
                        <Text size='sm' mt={3}>{ application.synopsis }</Text>
                    </InfoBox>
                    <InfoBox bg={REG_BG} title='CATEGORIZATION' align={undefined}>
                        <HStack justify='space-evenly' textTransform='uppercase' textAlign='center'>
                            <Text w='33%' size='xs'>ICD Codes</Text>
                            <Text w='33%' size='xs'>NIH Categories</Text>
                            <Text w='33%' size='xs'>NIH not covered</Text>
                        </HStack>
                        <HStack justify='space-evenly' textAlign='center'>
                            <Text w='33%'>
                                { application.icdCodes ?? 'None' }
                            </Text>
                            <Text w='33%'>
                                { application.nihCategories ?? 'None' }
                            </Text>
                            <Text w='33%'>
                                { application.nihNotCovered ?? 'None' }
                            </Text>
                        </HStack>
                    </InfoBox>
                    <Divider my={2} />
                    <InfoBox bg={REG_BG} title='QUESTIONS'>
                        { application.answers?.map(answer => (
                            <QuestionAnswer
                                key={`answer-${answer.question.id}`}
                                question={answer.question.title}
                                answer={answer.text}/>
                        ))}
                        { (!application.answers || application.answers.length === 0) &&
                            <Text color='gray' textAlign='center'>No answers provided.</Text>
                        }
                    </InfoBox>
                </Stack>
                <Divider orientation='vertical' />
                <StaffUpdates application={application} canUpdate={canUpdate} />
            </HStack>

            <UpdateApplicationModal application={application} fields={formFields} isOpen={isOpen} onClose={onClose} />
            <EditFilesModal application={application} isOpen={isOpenFileModal} onClose={onCloseFileModal} />
            <DataroomLinkModal application={application} isOpen={isDataRoomModalOpen} onClose={onCloseDataroomModal} />
        </Stack>
    )
};

export default ApplicationInfo;
