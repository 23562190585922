import { Stack, StackDirection, Text } from '@chakra-ui/react';
import React from 'react';
import { SystemProps } from '@chakra-ui/system';

interface InfoBoxProps {
    align?: SystemProps['alignItems'];
    bg: string;
    children: React.ReactNode;
    direction?: StackDirection;
    title?: string;
}

const InfoBox: React.FC<InfoBoxProps> = ({
    align,
    children,
    bg,
    direction = 'column',
    title
}) => {
    return (
        <Stack
            align={align ?? undefined}
            bg={bg}
            borderRadius='md'
            border='1px solid'
            borderColor='gray.200'
            color='gray.600'
            direction={direction}
            fontSize='sm'
            minH={120}
            p={3}>
            { title &&
                <Text size='xs' textTransform='uppercase' w='100%' textAlign='center'>
                    {title}
                </Text>
            }
            {children}
        </Stack>
    )
}

export default InfoBox;
