import { AbsoluteCenter, HStack, Link, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import ViewContainer from '../../components/View/ViewContainer';
import { PATHS } from '../../config';
import { useApplicationQuery } from '../../graphql/generated';
import ApplicationInfo from './ApplicationInfo';

const Application = () => {
    const { applicationId } = useParams();

    const { data, loading, error, refetch } = useApplicationQuery({
        variables: {
            id: Number(applicationId) ?? -1
        },
        skip: !applicationId || !Number(applicationId)
    });

    const application  = data?.application;

    return (
        <ViewContainer maxW='100vw' p={0}>
            { !data && loading && <AbsoluteCenter><Loader /></AbsoluteCenter> }
            { !data && !loading && error &&
                <AbsoluteCenter>
                    <Stack textAlign='center'>
                        <Text size='xl' color='red.400'>{ error.message }</Text>
                        <Link href={PATHS.HOME}>
                            Back to home
                        </Link>
                    </Stack>
                </AbsoluteCenter>
            }
            { data && !loading && application &&
                <HStack>
                    <ApplicationInfo application={application} refetchApp={refetch} />
                </HStack>
            }
            { !Number(applicationId) &&
                <AbsoluteCenter>
                    <Text size='xl' color='red.400'>Incorrect application selected</Text>
                </AbsoluteCenter>
            }
        </ViewContainer>
    )
};

export default Application;
