import { Box, Divider, Heading, HStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { ApplicationsQuery, DealStage, useApplicationsQuery } from '../../../graphql/generated';
import ApplicationEntry from './ApplicationEntry';

type Applications = ApplicationsQuery['applications']['applications'];

const noScroll = {
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    scrollbarWidth: 'none',
}

type IGroup = { [key: string]: Applications };

const ApplicantHome: React.FC = () => {
    const { data: appData, } = useApplicationsQuery({
        variables: {
            input: { orderBy: {} }
        },
    });
    const [grouped, setGrouped] = useState<IGroup>(() => ({
        PENDING: [],
        ARCHIVED: [],
    }));

    useEffect(() => {
        const newGroup: IGroup = {
            PENDING: [],
            ARCHIVED: [],
        };
        if (appData) {
            appData
                .applications
                .applications
                .forEach(application => {
                    const isEndState: boolean = [
                        DealStage.ARCHIVED,
                        DealStage.FUNDED,
                        DealStage.DISCORD_VOTE_FAILED,
                        DealStage.DISCOURSE_VOTE_FAILED,
                        DealStage.SNAPSHOT_VOTE_FAILED
                    ].includes(application.dealStage);

                    if (isEndState) {
                        newGroup.ARCHIVED.push(application);
                    } else {
                        newGroup.PENDING.push(application);
                    }
                });
        }
        setGrouped(newGroup);
    }, [appData]);

    const sectionMaxH = 'calc(100vh - 150px - 30px)';

    return (
        <HStack height='calc(100vh - 150px)' align='center' justify='center' w='full'>
            <Box w='70%' h='100%'>
                <Heading size='xs' color='yellow.700' textAlign='center' h={30}>
                    In Progress
                </Heading>
                <Divider my={1} />
                <Box overflowY='scroll' maxHeight={sectionMaxH} sx={noScroll}>
                    { grouped.PENDING.map((application: Applications[number]) => (
                        <ApplicationEntry
                            application={application}
                            key={`applicant-app-${application.id}`} />
                    )) }
                </Box>
            </Box>
            <Divider orientation='vertical' />
            <Box w='30%' h='100%'>
                <Heading size='xs' color='green.700' textAlign='center' h={30}>
                    Done
                </Heading>
                <Divider my={1} />
                <Box overflowY='scroll' maxHeight={sectionMaxH} sx={noScroll}>
                    { grouped.ARCHIVED.map((application: Applications[number]) => (
                        <ApplicationEntry
                            application={application}
                            key={`applicant-app-${application.id}`} />
                    )) }
                </Box>
            </Box>
        </HStack>
    )
}

export default ApplicantHome;
