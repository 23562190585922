import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Stack,
    Textarea,
    useToast
} from '@chakra-ui/react';

import React from 'react';
import { ContactQuery, CreateContactInputSchema, useCreateContactMutation, useUpdateContactMutation }
    from '../../../graphql/generated';
import { z } from 'zod';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { nullifyEmptyStringsAndObjects } from '../../../utils/formatEmptyString';
import FormInput from '../../../components/Input/FormInput';

interface ContactModalProps {
    isOpen: boolean;
    onClose: () => void;
    contact?: ContactQuery['contact'];
}

const schema = CreateContactInputSchema();

export type FormSchema = z.infer<typeof schema>;

const CreateContactModal: React.FC<ContactModalProps> = ({ isOpen, onClose, contact }) => {
    const toast = useToast();
    const form = useForm<FormSchema>({
        resolver: zodResolver(schema),
        ...(contact ?  { defaultValues: {
            areaOfExpertise: contact?.areaOfExpertise,
            description: contact?.description,
            discordId: contact?.discordId,
            diversity: contact?.diversity,
            email: contact?.email,
            fullName: contact?.fullName ?? '',
            notes: contact?.notes,
            phoneNumber: contact?.phoneNumber,
            location: contact?.location,
            twitterHandle: contact?.twitterHandle,
            socialMedia: contact?.socialMedia,
            vitaRoles: contact?.vitaRoles
        } } : {} )
    });
    const { handleSubmit, reset, register, formState: { errors } } = form;

    const [updateContact, { loading: updateLoading }] = useUpdateContactMutation({
        onError(e) {
            toast({
                title: 'Failed to update',
                description: e.message,
                status: 'warning',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
        },
        onCompleted() {
            toast({
                title: 'Contact Updated',
                description: 'Contact has been updated successfully',
                status: 'success',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
        }
    });

    const [createContact, { loading }] = useCreateContactMutation({
        onError(e) {
            toast({
                title: 'Failed to create',
                description: e.message,
                status: 'warning',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
        },
        onCompleted() {
            toast({
                title: 'Contact Created',
                description: 'Contact has been created successfully',
                status: 'success',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
        }
    });

    const onSubmit = async (formData: FormSchema) => {
        const data = nullifyEmptyStringsAndObjects(formData)
        if (contact) {
            await updateContact({
                variables: {
                    input: {
                        id: contact.id,
                        ...data
                    }
                }
            })
        } else {
            await createContact({
                variables: {
                    input: {
                        ...data,
                        email: data?.email,
                        fullName: data?.fullName
                    },
                },
            });
        }
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormProvider {...form}>
                        <ModalHeader>{contact ? 'Edit Contact' : 'Create Contact'}</ModalHeader>
                        <ModalCloseButton/>
                        <ModalBody>
                            <Stack spacing={4}>
                                <FormControl isInvalid={!!errors.fullName}>
                                    <FormLabel size='sm' textAlign='center'>
                                        Full name
                                    </FormLabel>
                                    <Input
                                        {...form.register('fullName')}
                                        name='fullName'
                                        defaultValue={contact?.fullName ?? ''}
                                        placeholder='Full Name' />
                                    {errors.fullName && <FormErrorMessage>{errors.fullName.message}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={!!errors.email}>
                                    <FormLabel size='sm' textAlign='center'>
                                        Email
                                    </FormLabel>
                                    <Input
                                        {...form.register('email')}
                                        name='email'
                                        type='email'
                                        placeholder='Email'
                                        defaultValue={contact?.email ?? ''}/>
                                    {errors.email
                                        && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
                                </FormControl>
                                {!contact && (
                                    <HStack spacing={4} mb={6} align='stretch'>
                                        <FormControl isInvalid={!!errors?.organization?.name}>
                                            <FormLabel size='sm'>Institution</FormLabel>
                                            <FormInput form={form} name='organization.name' />
                                        </FormControl>
                                        <FormControl isInvalid={!!errors?.organization?.website}>
                                            <FormLabel size='sm'>Institution Link</FormLabel>
                                            <FormInput form={form} name='organization.website' />
                                        </FormControl>
                                    </HStack>
                                )}
                                <FormControl isInvalid={!!errors.socialMedia}>
                                    <FormLabel size='sm'>
                                        Google Scholar, ORCID, or LinkedIn link
                                    </FormLabel>
                                    <FormInput 
                                        form={form} name='socialMedia'
                                        defaultValue={contact?.socialMedia ?? ''}/>
                                </FormControl>
                                <FormControl isInvalid={!!errors.twitterHandle}>
                                    <FormLabel size='sm'>
                                        Twitter Handle
                                    </FormLabel>
                                    <FormInput
                                        form={form} 
                                        name='twitterHandle' 
                                        defaultValue={contact?.twitterHandle ?? ''} />
                                </FormControl>
                                <FormControl isInvalid={!!errors.areaOfExpertise}>
                                    <FormLabel size='sm' textAlign='center'>
                                        Area of Expertise
                                    </FormLabel>
                                    <Input
                                        {...form.register('areaOfExpertise')}
                                        name='areaOfExpertise'
                                        placeholder='Comma separated values. E.g. Funding,Research,Policy'
                                        defaultValue={contact?.areaOfExpertise ?? ''}/>
                                    {errors.areaOfExpertise
                                        && <FormErrorMessage>{errors.areaOfExpertise.message}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={!!errors.vitaRoles}>
                                    <FormLabel size='sm' textAlign='center'>
                                        Vita Roles
                                    </FormLabel>
                                    <Input
                                        {...form.register('vitaRoles')}
                                        name='vitaRoles'
                                        placeholder='Comma separated values. E.g. Senior Reviewer,
                                        Dealflow WG Member,Builder Squad EIR'
                                        defaultValue={contact?.vitaRoles ?? ''}/>
                                    {errors.vitaRoles
                                        && <FormErrorMessage>{errors.vitaRoles.message}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={!!errors.diversity}>
                                    <FormLabel size='sm'>Diversity</FormLabel>
                                    <Select
                                        {...register('diversity')}
                                        placeholder=''
                                        defaultValue={contact?.diversity ?? 'male'}>
                                        <option value='male'>Male</option>
                                        <option value='female'>Female</option>
                                        <option value='other'>Other</option>
                                    </Select>
                                </FormControl>
                                <FormControl isInvalid={!!errors.phoneNumber}>
                                    <FormLabel size='sm' textAlign='center'>
                                        Phone Number
                                    </FormLabel>
                                    <Input 
                                        {...form.register('phoneNumber')} 
                                        name='phoneNumber'
                                        defaultValue={contact?.phoneNumber ?? ''}/>
                                    {errors.phoneNumber
                                        && <FormErrorMessage>{errors.phoneNumber.message}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={!!errors.discordId}>
                                    <FormLabel size='sm' textAlign='center'>
                                        Discord Id
                                    </FormLabel>
                                    <Input 
                                        {...form.register('discordId')} 
                                        name='discordId'
                                        defaultValue={contact?.discordId ?? ''}/>
                                    {errors.discordId
                                        && <FormErrorMessage>{errors.discordId.message}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={!!errors.notes}>
                                    <FormLabel size='sm' textAlign='center'>
                                        Notes
                                    </FormLabel>
                                    <Textarea 
                                        {...form.register('notes')} 
                                        name='notes' 
                                        defaultValue={contact?.notes ?? ''} />
                                    {errors.notes
                                        && <FormErrorMessage>{errors.notes.message}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={!!errors.description}>
                                    <FormLabel size='sm' textAlign='center'>
                                        Description
                                    </FormLabel>
                                    <Textarea
                                        {...form.register('description')}
                                        name='description'
                                        defaultValue={contact?.description ?? ''}/>
                                    {errors.description
                                        && <FormErrorMessage>{errors.description.message}</FormErrorMessage>}
                                </FormControl>
                            </Stack>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='black' type='submit' isLoading={loading || updateLoading} w='full'>
                                {contact ? 'Update' : 'Create'}</Button>
                        </ModalFooter>
                    </FormProvider>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default CreateContactModal;
