import { Box, Container, Flex } from '@chakra-ui/react';
import React from 'react';
import Header from './Header';
import Footer from './Footer';

interface ViewContainerProps {
    maxW?: string | number;
    children: React.ReactNode | React.ReactNode[];
    p?: string | number;
}

const ViewContainer: React.FC<ViewContainerProps> = ({ p, children, maxW = '1280px' }) => {
    return (
        <Container maxW={maxW} p={p}>
            <Flex direction='column' minHeight='100vh'>
                <Box flex='1'>
                    <Header />
                    { children }
                </Box>
                <Footer />
            </Flex>
        </Container>
    );
}

export default ViewContainer;
