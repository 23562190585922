import { Modal, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import React from 'react';
import SeniorReviewForm from '../../SeniorReview/SeniorReviewForm';
import { ApplicationQuery, useReviewQuery } from '../../../graphql/generated';
import Loader from '../../../components/Loader';

type Review = ApplicationQuery['application']['reviews'][0];

interface ReviewModalProps {
    review: Review | null;
    isOpen: boolean;
    onClose: () => void;
}

const ReviewModal: React.FC<ReviewModalProps> = ({ review, isOpen, onClose }) => {
    if (!review || !review.reviewer?.id) return null;
    const { applicationId, reviewer: { id } } = review;

    const { data: fullReview, loading } = useReviewQuery({
        variables: { applicationId, reviewerId: id },
        skip: !isOpen,
    });

    return (
        <Modal isOpen={isOpen} onClose={onClose} size='2xl'>
            <ModalOverlay />
            <ModalContent px={10} pb={10}>
                <ModalHeader>
                    Review from {review.reviewer?.contact?.fullName}
                </ModalHeader>
                { loading && <Loader /> }
                { !loading && fullReview?.review && (
                    <SeniorReviewForm applicationId={applicationId} seniorReview={fullReview.review} readonly />
                )}
            </ModalContent>
        </Modal>
    );
};

export default ReviewModal;
