import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import {
    Button,
    FormControl,
    FormLabel,
    Heading,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Text,
    useToast
} from '@chakra-ui/react';
import StarInput from '../../components/Input/StarInput';
import FormInput from '../../components/Input/FormInput';
import HelperText from '../../components/Input/HelperText';
import {
    AccountApplicationReviewQuery,
    ReviewDealType,
    ReviewRecommendation,
    ReviewReturnProfile,
    SeniorReviewInputSchema,
    useUpsertReviewMutation
} from '../../graphql/generated';
import formatEnum from '../../utils/formatEnum';

const reviewInputSchema = SeniorReviewInputSchema()
type FormValues = z.infer<typeof reviewInputSchema>;

interface SeniorReviewFormProps {
    applicationId: number;
    seniorReview: AccountApplicationReviewQuery['accountApplicationReview'] | undefined;
    refetch?: any;
    readonly?: boolean;
}

const SeniorReviewForm: React.FC<SeniorReviewFormProps> = ({
    applicationId,
    seniorReview,
    refetch,
    readonly = false
}) => {
    const toast = useToast();
    const { __typename, reviewer: _, ...rest } = seniorReview || {};

    const form = useForm<FormValues>({
        resolver: zodResolver(SeniorReviewInputSchema()),
        defaultValues: {
            ...rest,
            applicationId: Number(applicationId),
            reviewDealType: ReviewDealType.EQUITY_DEAL_UNDER_100 // TODO we get this from the application
        }
    });
    const {
        control,
        getValues,
        register,
        reset,
        formState: { errors }
    } = form;

    useEffect(() => {
        reset();
    }, [applicationId]);

    const [review, { loading: submitting }] = useUpsertReviewMutation({
        onError(e) {
            toast({
                title: 'Failed to submit',
                description: e.message,
                status: 'warning',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
        },
        onCompleted() {
            toast({
                title: 'Review submitted',
                description: 'Your review has been submitted successfully',
                status: 'success',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
            if (refetch) {
                refetch();
            }
        }
    });

    const onSubmit = async () => {
        const values = getValues();
        await review({
            variables: {
                input: {
                    ...values,
                    returnProfile: values?.returnProfile?.toString() !== '' ? values.returnProfile : undefined,
                    recommendation: values?.recommendation?.toString() !== '' ? values.recommendation : undefined,
                }
            }
        });
    }

    return (
        <form>
            <FormProvider {...form}>
                <Stack gap={readonly ? 10 : 20}>
                    <Stack gap={0} mt={3}>
                        <Heading size='sm' my={0}>Declaration of Conflict of Interest</Heading>
                        { !readonly && (
                            <Text mb={4} size='sm' color='gray'>
                                In order to maintain a fair selection process, please flag a conflict of interest
                                where you feel you will not be able to remain impartial.
                                <Text fontWeight='bold' textDecoration='underline' mb={1}>
                                    We trust you to be honest and to mark where appropriate.
                                </Text>
                                <Text>
                                    A conflict of interest includes (but is not limited to):
                                </Text>
                                <Text my={2} pl={2}>
                                    1. If you have a stake in a similar company, idea, patent, or have insider
                                    knowledge that would preclude you from scoring the team fairly – either overly
                                    positively or overly negatively.
                                </Text>
                                <Text pl={2}>
                                    2. If you have any advisor/mentor relationship or financial upside
                                </Text>
                            </Text>
                        )}
                        <FormLabel fontWeight='bold'>
                            Declare conflict. (If yes, we cannot accept your review)
                        </FormLabel>
                        <Controller
                            name='hasConflict'
                            control={control}
                            defaultValue={true}
                            render={({ field }) => (
                                <RadioGroup
                                    isDisabled={readonly}
                                    onChange={x => field.onChange(JSON.parse(x))}
                                    value={field?.value?.toString()}>
                                    <Stack>
                                        <Radio size='lg' colorScheme='red' value='true'>
                                            I have a Conflict of Interest
                                        </Radio>
                                        <Radio size='lg' colorScheme='green' value='false'>
                                            I have no Conflict of Interest
                                        </Radio>
                                    </Stack>
                                </RadioGroup>
                            )}/>
                    </Stack>
                    <Stack>
                        <FormControl isInvalid={!!errors.teamExpertiseScore}>
                            <FormLabel fontWeight='bold'>Team Expertise</FormLabel>
                            <Text>
                                Do you think the team has the expertise,
                                partners and/or advisors to develop the project successfully?
                            </Text>
                            <Controller
                                name='teamExpertiseScore'
                                control={control}
                                defaultValue={0}
                                render={({ field }) => (
                                    <StarInput
                                        readonly={readonly}
                                        value={field.value}
                                        onChange={field.onChange}/>
                                )}/>
                        </FormControl>
                        <FormControl isInvalid={!!errors.teamExpertiseComment}>
                            <FormLabel size='sm'>
                                What are your comments and/or questions about the team?
                                Do they miss any critical competences?
                            </FormLabel>
                            <HelperText mb='4'>Please justify your score. Guideline: 1-2 sentences</HelperText>
                            <FormInput isDisabled={readonly} type='rich' form={form} name='teamExpertiseComment' />
                        </FormControl>
                    </Stack>

                    <Stack>
                        <FormControl isInvalid={!!errors.feasibilityScore}>
                            <FormLabel fontWeight='bold'>Feasibility and Data</FormLabel>
                            <Text>
                                Considering the available scientific data,
                                is this project scientifically and technically viable?
                            </Text>
                            <Controller
                                name='feasibilityScore'
                                control={control}
                                defaultValue={0}
                                render={({ field }) => (
                                    <StarInput
                                        readonly={readonly}
                                        value={field.value}
                                        onChange={field.onChange}/>
                                )}/>
                        </FormControl>
                        <FormControl isInvalid={!!errors.feasibilityComment}>
                            <FormLabel size='sm'>
                                What are your comments and/or questions about
                                the scientific and technical viability of the project?
                            </FormLabel>
                            <HelperText mb='4'>Please justify your score. Guideline: 1-2 sentences</HelperText>
                            <FormInput isDisabled={readonly} type='rich' form={form} name='feasibilityComment' />
                        </FormControl>
                    </Stack>
                    <Stack>
                        <FormControl isInvalid={!!errors.commercialScore}>
                            <FormLabel fontWeight='bold'>Commercial Potential and Impact</FormLabel>
                            <Text>
                                How big is the market for the proposed solution?
                                Does this project have the potential to become a blockbuster
                                intervention (over $1B/year revenues)?
                                Will it significantly improve people’s lives?
                            </Text>
                            <Controller
                                name='commercialScore'
                                control={control}
                                defaultValue={0}
                                render={({ field }) => (
                                    <StarInput
                                        readonly={readonly}
                                        value={field.value}
                                        onChange={field.onChange}/>
                                )}/>
                        </FormControl>
                        <FormControl isInvalid={!!errors.commercialComment}>
                            <FormLabel size='sm'>
                                What are your comments and/or questions concerning
                                the commercial potential and impact of the project?
                            </FormLabel>
                            <HelperText mb='4'>Please justify your score. Guideline: 1-2 sentences</HelperText>
                            <FormInput isDisabled={readonly} type='rich' form={form} name='commercialComment' />
                        </FormControl>
                    </Stack>
                    <Stack>
                        <FormControl isInvalid={!!errors.noveltyScore}>
                            <FormLabel fontWeight='bold'>Novelty and Market Advantage</FormLabel>
                            <Text>
                                Is this project a clear improvement compared to standard of care or other
                                solutions in development? Is the project expected to have a significant edge in the
                                market with respect to competing solutions?
                            </Text>
                            <Controller
                                name='noveltyScore'
                                control={control}
                                defaultValue={0}
                                render={({ field }) => (
                                    <StarInput
                                        readonly={readonly}
                                        value={field.value}
                                        onChange={field.onChange}/>
                                )}/>
                        </FormControl>
                        <FormControl isInvalid={!!errors.noveltyComment}>
                            <FormLabel size='sm'>
                                What are your questions and/or comments about the novelty and market
                                advantage? Do you think this company will make money?
                            </FormLabel>
                            <HelperText mb='4'>Please justify your score. Guideline: 1-2 sentences</HelperText>
                            <FormInput isDisabled={readonly} type='rich' form={form} name='noveltyComment' />
                        </FormControl>
                    </Stack>
                    <Stack>
                        <FormControl isInvalid={!!errors.ipDefensibilityScore}>
                            <FormLabel fontWeight='bold'>IP Defensibility</FormLabel>
                            <Text>
                                How defensible is the technology? Do they have strong and recent patents or the
                                potential to file them within the budget of the project? <br/>
                                Do they have other strong barriers to protect themselves?
                            </Text>
                            <Controller
                                name='ipDefensibilityScore'
                                control={control}
                                defaultValue={0}
                                render={({ field }) => (
                                    <StarInput
                                        readonly={readonly}
                                        value={field.value}
                                        onChange={field.onChange}/>
                                )}/>
                        </FormControl>
                        <FormControl isInvalid={!!errors.ipDefensibilityComment}>
                            <FormLabel size='sm'>
                                What are your comments and/or questions concerning the IP potential of the project?
                            </FormLabel>
                            <HelperText mb='4'>Please justify your score. Guideline: 1-2 sentences</HelperText>
                            <FormInput isDisabled={readonly} type='rich' form={form} name='ipDefensibilityComment' />
                        </FormControl>
                    </Stack>
                    <Stack>
                        <FormControl isInvalid={!!errors.longevityRelevanceScore}>
                            <FormLabel fontWeight='bold'>Relevance to Longevity Mission</FormLabel>
                            <Text>
                                Is this project well aligned with VitaDAO&apos;s mission to achieve substantial
                                lifespan and/or healthspan extension?
                            </Text>
                            <Controller
                                name='longevityRelevanceScore'
                                control={control}
                                defaultValue={0}
                                render={({ field }) => (
                                    <StarInput
                                        readonly={readonly}
                                        value={field.value}
                                        onChange={field.onChange}/>
                                )}/>
                        </FormControl>
                        <FormControl isInvalid={!!errors.longevityRelevanceComment}>
                            <FormLabel size='sm'>
                                What are your comments and/or questions concerning the relevance to
                                the longevity mission?
                            </FormLabel>
                            <HelperText mb='4'>Please justify your score. Guideline: 1-2 sentences</HelperText>
                            <FormInput isDisabled={readonly} type='rich' form={form} name='longevityRelevanceComment' />
                        </FormControl>
                    </Stack>
                    <Stack>
                        <FormControl isInvalid={!!errors.dealTermsScore}>
                            <FormLabel fontWeight='bold'>Deal terms</FormLabel>
                            <Text>
                                Can we capture value optimally or would another structure be more appropriate?
                            </Text>
                            <Controller
                                name='dealTermsScore'
                                control={control}
                                defaultValue={0}
                                render={({ field }) => (
                                    <StarInput
                                        readonly={readonly}
                                        value={field.value}
                                        onChange={field.onChange}/>
                                )}/>
                        </FormControl>
                        <FormControl isInvalid={!!errors.dealTermsComment}>
                            <FormLabel size='sm'>
                                What are your comments and/or questions concerning the deal terms?
                            </FormLabel>
                            <HelperText mb='4'>Please justify your score. Guideline: 1-2 sentences</HelperText>
                            <FormInput isDisabled={readonly} type='rich' form={form} name='dealTermsComment'/>
                        </FormControl>
                    </Stack>
                    <Stack>
                        <FormControl isInvalid={!!errors.returnProfile}>
                            <FormLabel fontWeight='bold' size='sm'>
                                Is this project more of a moonshot or do you think we will see
                                more near-term value?
                            </FormLabel>
                            <HelperText mb='4'>Token holders must take this into consideration when voting</HelperText>
                            <Select
                                {...register('returnProfile')}
                                isDisabled={readonly}
                                placeholder='Select return profile'
                                defaultValue={undefined}>
                                { Object.values(ReviewReturnProfile)
                                    .map((option: string) => (
                                        <option key={option} value={option}>
                                            {formatEnum(option)}
                                        </option>
                                    ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack>
                        <FormControl isInvalid={!!errors.evaluationSummaryScore}>
                            <FormLabel fontWeight='bold'>Evaluation Summary</FormLabel>
                            <Text>Overall Conviction Score: To summarise, how would you rank this project? </Text>
                            <Controller
                                name='evaluationSummaryScore'
                                control={control}
                                defaultValue={0}
                                render={({ field }) => (
                                    <StarInput
                                        readonly={readonly}
                                        value={field.value}
                                        onChange={field.onChange}
                                        helperText={'Historically, aggregate scores of 3.6 and ' +
                                            'above have resulted in a positive voting outcome'}/>
                                )}/>
                        </FormControl>
                        <FormControl isInvalid={!!errors.evaluationSummary}>
                            <FormLabel size='sm'>
                                What are the key pros and cons of this project?
                            </FormLabel>
                            <HelperText mb='4'>Guideline: 1-3 pros and 1-3 cons</HelperText>
                            <FormInput isDisabled={readonly} type='rich' form={form} name='evaluationSummary' />
                        </FormControl>
                    </Stack>
                    <FormControl isInvalid={!!errors.wouldEndorse}>
                        <FormLabel fontWeight='bold' size='sm'>
                            Would you endorse this project?
                        </FormLabel>
                        <HelperText mb='4'>
                            Provide a brief summary of whether you would endorse VitaDAO
                            funding the project and the rationale behind your recommendation
                            (i.e. publicly supporting, investing and/or actively participating in it).
                        </HelperText>
                        <FormInput isDisabled={readonly} type='rich' form={form} name='wouldEndorse'/>
                    </FormControl>
                    <FormControl isInvalid={!!errors.recommendation}>
                        <FormLabel fontWeight='bold'>Recommended Next Step</FormLabel>
                        <HelperText mb='4'>How should we proceed with the opportunity?</HelperText>
                        <Select
                            {...register('recommendation')}
                            placeholder='Recommendation'
                            isDisabled={readonly}
                            defaultValue={undefined}>
                            {Object
                                .values(ReviewRecommendation)
                                .map((option: string) => (
                                    <option key={option} value={option}>
                                        {formatEnum(option)}
                                    </option>
                                ))}
                        </Select>
                    </FormControl>
                </Stack>
                <Button
                    display={readonly ? 'none' : 'block'}
                    isLoading={submitting}
                    isDisabled={submitting}
                    mt={8}
                    size='lg'
                    w='full'
                    variant='black'
                    onClick={onSubmit}>
                    Submit
                </Button>
            </FormProvider>
        </form>
    )
}

export default SeniorReviewForm;
