import { Button, HStack, Icon, List, ListItem, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { FaFileAlt, FaFilePdf, FaFilePowerpoint, FaFileWord, FaImage } from 'react-icons/fa';
import { IFile } from './Input/FileUploadInput';
import { ApplicationQuery } from '../graphql/generated';
import formatStringEllipsis from '../utils/formatStringEllipsis';

type DataroomFiles = NonNullable<ApplicationQuery['application']['dataroom']>['files'];

interface FileListProps {
    files: File[] | IFile[] | DataroomFiles;
    isLink?: boolean;
    onRemove?: (file: File | IFile | DataroomFiles[number] ) => void;
    size?: 'sm' | 'md' | 'lg';
}

const FileList: React.FC<FileListProps> = ({
    files = [],
    onRemove,
    isLink = false,
    size = 'sm'
}) => {
    const getIconForMimeType = (mimeType: string) => {
        let FileIcon = FaFileAlt;
        let color = 'gray';
        if (mimeType.startsWith('image/')) {
            FileIcon = FaImage;
            color = 'blue.200';
        } else if (
            mimeType.startsWith('application/msword')
            || mimeType.startsWith('application/vnd.openxmlformats-officedocument.wordprocessingml.document')
        ) {
            FileIcon = FaFileWord;
            color = 'blue.500';
        } else if (mimeType.startsWith('application/pdf')) {
            FileIcon = FaFilePdf;
            color = 'red';
        } else if (
            mimeType.startsWith('application/vnd.ms-powerpoint')
            || mimeType.startsWith('application/vnd.openxmlformats-officedocument.presentationml.presentation')
        ) {
            FileIcon = FaFilePowerpoint;
            color = 'red.400';
        }
        return (
            <Icon
                as={FileIcon}
                size={size === 'sm' ? 'xs' : size === 'md' ? 'sm' : 'md'}
                color={color} />
        );
    };

    return (
        <List textAlign='center'>
            {files.map((file, index) => (
                <ListItem key={index} m={1} px={2} py={1} textAlign='center'>
                    <HStack gap={2}>
                        { onRemove &&
                            <Button
                                size={size === 'sm' ? 'xs' : size === 'md' ? 'sm' : 'md'}
                                variant='link'
                                onClick={() => onRemove(file)}>
                                x
                            </Button>
                        }
                        { getIconForMimeType(file.type) }
                        <Text
                            as={isLink ? 'a' : undefined}
                            color='gray.600'
                            href={isLink ? (file as DataroomFiles[number]).url : undefined}
                            size={size}
                            target='_blank'
                            textAlign='left'
                            _hover={{ fontWeight: '600' }}>
                            <Tooltip label={file.name} hasArrow isDisabled={file.name.length <= 20}>
                                { formatStringEllipsis(file.name, 20) }
                            </Tooltip>
                        </Text>
                    </HStack>
                </ListItem>
            ))}
        </List>
    )
}

export default FileList;
