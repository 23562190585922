import { Checkbox, FormErrorMessage, Input, NumberInput, NumberInputField } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import React from 'react';
import ReactQuill from 'react-quill';


interface IFormInput {
    name: string;
    type?: 'text' | 'number' | 'check' | 'email' | 'password' | 'rich' | 'radio';
    form: any;
    placeholder?: string;
    autoComplete?: string;
    valueAsNumber?: boolean;
    defaultValue?: string | null | boolean;
    isDisabled?: boolean;
}

const FormInput: React.FC<IFormInput> = ({
    name,
    placeholder,
    autoComplete = 'off',
    type = 'text',
    form,
    defaultValue,
    ...props
}) => {
    const { control, register } = form;
    const fieldProps = { ...props, ...register(name), autoComplete, placeholder };

    const richModules = props.isDisabled
        ? { toolbar: false }
        : {
            toolbar: [
                [{ header: '1' }, { header: '2' }, { font: [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' },
                    { indent: '-1' }, { indent: '+1' }],
                ['link', 'image', 'video'],
                ['clean']
            ],
        }

    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue ?? ''}
            render={({ field, fieldState: { error } }) => (
                <>
                    { type === 'number' &&
                        <NumberInput
                            value={field.value}
                            defaultValue={defaultValue}
                            {...props}
                            {...register(name)}
                            autoComplete={autoComplete}
                            placeholder={placeholder}
                            onChange={valueString => {
                                const value = parseFloat(valueString);
                                field.onChange(isNaN(value) ? undefined : value);
                            }}>
                            <NumberInputField />
                        </NumberInput>
                    }
                    { type === 'check' &&
                        <Checkbox
                            defaultValue={defaultValue}
                            {...fieldProps}
                            onChange={e => {
                                field.onChange(e.target.checked);
                            }}>
                            {placeholder}
                        </Checkbox>
                    }
                    {type === 'rich' && (
                        <ReactQuill
                            readOnly={props.isDisabled}
                            value={field.value}
                            modules={richModules}
                            onChange={e => {
                                const value = e.trim() === '' ? undefined : e;
                                field.onChange(value);
                            }}/>
                    )}
                    { (type !== 'check' && type !== 'number' && type !== 'rich') &&
                        <Input
                            value={field.value}
                            {...props}
                            {...register(name)}
                            type={type}
                            autoComplete={autoComplete}
                            placeholder={placeholder}
                            onChange={e => {
                                const value = e.target.value.trim() === '' ? undefined : e.target.value;
                                field.onChange(value);
                            }} />
                    }
                    {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </>
            )} />
    );
}

export default FormInput;
