import lookup from 'country-code-lookup';
import DealStageBadge from '../../components/Badge/DealStageBadge';
import { TruncatedCell } from '../../components/VitaTable/Cells';
import CommaStringCell from '../../components/VitaTable/Cells/CommaStringCell';
import NullableCell from '../../components/VitaTable/Cells/NullableCell';
import TeamCell from '../../components/VitaTable/Cells/TeamCell';
import UrlCell from '../../components/VitaTable/Cells/UrlCell';
import { Application, ApplicationType, DealStage, PatentStatus, ProjectPriority } from '../../graphql/generated';
import formatCurrency from '../../utils/formatCurrency';
import PriorityBadge from '../../components/Badge/PriorityBadge';
import { ColumnConfig } from '../../components/VitaTable/types';
import moment from 'moment';
import formatDealType from '../../utils/formatDealType';

const TableConfig: { columns: ColumnConfig[] } = {
    columns: [
        {
            key: 'id',
            label: 'ID',
            filterable: true,
            sortable: true,
            editable: false,
            type: 'number',
            render: (app: Application) => <UrlCell
                isExternal={false}
                value={app.id}
                url={`/application/${app.id}`} />
        },
        {
            key: 'title',
            label: 'Title',
            sortable: false,
            editable: false,
            render: (app: Application) => <UrlCell
                maxW={'15em'}
                isExternal={false}
                value={app.title}
                url={`/application/${app.id}`} />
        },
        {
            key: 'synopsis',
            label: 'Synopsis',
            sortable: false,
            editable: false,
            render: (app: Application) => <TruncatedCell content={app.synopsis} maxW='20em' />
        },
        {
            key: 'applicationType',
            label: 'Type',
            filterable: true,
            sortable: true,
            editable: false,
            type: 'set',
            options: Object.values(ApplicationType).map(type => ({
                label: type, value: type
            })),
            render: (app: Application) => <NullableCell value={app.applicationType} />
        },
        {
            key: 'patentStatus',
            label: 'Patent',
            filterable: true,
            sortable: true,
            editable: false,
            type: 'set',
            options: Object.values(PatentStatus).map(status => ({
                label: status, value: status
            })),
            render: (app: Application) => <NullableCell value={app.patentStatus.split('_').join(' ')} />
        },
        {
            key: 'countryCode',
            label: 'Country',
            filterable: true,
            sortable: true,
            editable: false,
            render: (app: Application) => <NullableCell value={app.countryCode && app.countryCode.trim() != ''
                ? lookup.byIso(app.countryCode)?.country: 'No Country'} />
        },
        {
            key: 'dealStage',
            label: 'Deal Stage',
            filterable: true,
            sortable: true,
            editable: false,
            type: 'set',
            options: Object.values(DealStage).map(stage => ({
                label: stage.split('_').join(' '), value: stage
            })),
            render: (app: Application) => <DealStageBadge stage={app.dealStage} />
        },
        {
            key: 'priority',
            label: 'Priority',
            filterable: true,
            sortable: true,
            editable: false,
            type: 'set',
            options: Object.values(ProjectPriority).map(priority => ({
                label: priority, value: priority
            })),
            render: (app: Application) => <PriorityBadge priority={app.priority} />
        },
        {
            key: 'discourseUrl',
            label: 'Discourse',
            filterable: false,
            sortable: false,
            editable: false,
            render: (app: Application) => <UrlCell url={app.discourseUrl} value={'link'}/>
        },
        {
            key: 'snapshotUrl',
            label: 'Snapshot',
            filterable: false,
            sortable: false,
            editable: false,
            render: (app: Application) => <UrlCell url={app.snapshotUrl} value={'link'}/>
        },
        {
            key: 'fundingRequired',
            label: 'Funding',
            filterable: true,
            sortable: true,
            editable: false,
            type: 'number',
            render: (app: Application) => formatCurrency(app.fundingRequired)
        },
        {
            key: 'fundingRaised',
            label: 'Raised',
            filterable: true,
            sortable: true,
            editable: false,
            type: 'number',
            render: (app: Application) => formatCurrency(app.fundingRaised)
        },
        {
            key: 'Team',
            label: 'Project Team',
            filterable: false,
            sortable: false,
            editable: false,
            render: (app: Application) => <TeamCell contacts={[
                app.applicant,
                ...(app.members ?? []),
            ]} />
        },
        {
            key: 'workingGroup',
            label: 'Deal Squad',
            filterable: false,
            sortable: false,
            editable: false,
            render: (app: Application) => (
                <TeamCell contacts={app.workingGroup?.filter(member => !member.isShepherd)
                    .map(wg => wg.account.contact) ?? []} />)
        },
        {
            key: 'shepherd',
            label: 'Shepherd',
            filterable: false,
            sortable: false,
            editable: false,
            render: (app: Application) => (
                <TeamCell contacts={app.workingGroup?.filter(member => member.isShepherd)
                    .map(wg => wg.account.contact) ?? []} />)
        },
        {
            key: 'dealTypes',
            label: 'Deal Types',
            filterable: false,
            sortable: false,
            editable: false,
            type: 'set',
            render: (app: Application) => (
                <CommaStringCell
                    value={app
                        .dealTypes
                        .map(dt => formatDealType(dt.dealType))
                        .join(',')
                    } />
            )
        },
        {
            key: 'referrer',
            label: 'Referrer',
            filterable: false,
            sortable: false,
            editable: false,
            render: (app: Application) => <TeamCell contacts={app.referrer ? [app.referrer] : []} />
        },
        {
            key: 'createdAt',
            label: 'Created',
            filterable: false,
            sortable: true,
            editable: false,
            type: 'date',
            render: (app: Application) => moment(app.createdAt).format('DD/MM/YYYY')
        },
        {
            key: 'updatedAt',
            label: 'Updated',
            filterable: false,
            sortable: true,
            editable: false,
            type: 'date',
            render: (app: Application) => moment(app.updatedAt).format('DD/MM/YYYY')
        }
    ]
};

export default TableConfig;
