import { Flex, Icon, Input, Text } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { FaUpload } from 'react-icons/fa6';

export interface IFile { url: string; name: string; type: string; }

interface FileInputProps {
    onFilesSelected: (files: File[]) => void;
}
const allowedMimeTypes = [
    'image/*',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
];
const FileUploadInput: React.FC<FileInputProps> = ({ onFilesSelected }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files) {
            return;
        }
        const validFiles = Array.from(files).filter(file => {
            const isValidType = allowedMimeTypes.some(allowedType => {
                if (allowedType.endsWith('/*')) {
                    return file.type.startsWith(allowedType.slice(0, -1));
                } else {
                    return file.type === allowedType;
                }
            });
            return isValidType;
        });
        onFilesSelected(validFiles);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (!files) {
            return;
        }
        const validFiles = Array.from(files).filter(file => {
            const isValidType = allowedMimeTypes.some(allowedType => {
                if (allowedType.endsWith('/*')) {
                    return file.type.startsWith(allowedType.slice(0, -1));
                } else {
                    return file.type === allowedType;
                }
            });
            return isValidType;
        });
        onFilesSelected(validFiles);
    };

    const handleClick = () => {
        fileInputRef.current?.click();
    };

    return (
        <Flex
            alignItems='center'
            borderWidth={2}
            borderStyle='dashed'
            borderColor='gray.200'
            bg='gray.50'
            cursor='pointer'
            direction='column'
            rounded='md'
            justifyContent='center'
            onDrop={handleDrop}
            onDragOver={event => event.preventDefault()}
            onClick={handleClick}
            p={5}
            textAlign='center'>
            <Icon as={FaUpload} w={6} h={6} color='gray.500' />
            <Text fontSize='sm' color='gray.500' my={2} userSelect='none'>
                Drop files here or click to upload
            </Text>
            <Input
                type='file'
                hidden
                onChange={handleFileChange}
                ref={fileInputRef}
                multiple />
        </Flex>
    );
};

export default FileUploadInput;
