import {
    Box,
    Button,
    ButtonGroup,
    Center,
    Container,
    Divider,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    Image,
    Link,
    ListItem,
    Select,
    Spacer,
    Stack,
    Text,
    UnorderedList,
    useToast
} from '@chakra-ui/react';
import { Select as MultiSelect } from 'chakra-react-select';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MdOutlineRefresh } from 'react-icons/md';
import { z } from 'zod';
import LogoType from '../assets/logos/logotype.svg';
import FileList from '../components/FileList';
import FileUploadInput from '../components/Input/FileUploadInput';
import Overlay from '../components/View/Overlay';
import { PATHS } from '../config';
import { PatentStatus, useApplyMutation } from '../graphql/generated';
import { nullifyEmptyStringsAndObjects } from '../utils/formatEmptyString';
import FormInput from '../components/Input/FormInput';
import HelperText from '../components/Input/HelperText';
import lookup from 'country-code-lookup';
import { uploadFiles } from '../utils/uploadFiles';

const schema = z.object({
    applicant: z.object({
        fullName: z.string(),
        email: z.string(),
        socialMedia: z.string(),
        organization: z.object({
            name: z.string(),
            website: z.string().nullable(),
        }).nullable(),
    }),
    title: z.string(),
    synopsis: z.string().max(300),
    countryCode: z.string(),
    additionalMember: z.object({
        fullName: z.string().nullable(),
        email: z.string(),
        socialMedia: z.string().nullable(),
        organization: z.object({
            name: z.string(),
        }).nullable()
    }),
    nihCategories: z.string(),
    icdCodes: z.string().nullable(),
    nihNotCovered: z.string().nullable(),
    patentStatus: z.string().nullable(),
    assetDevelopmentStage: z.string().nullable(),
    problemToSolve: z.string().nullable(),
    projectImpact: z.string().nullable(),
    roadMap: z.string().nullable(),
    distinction: z.string().nullable(),
    fundingRequired: z.number(),
    fundingRaised: z.number(),
    referrer: z.object({
        fullName: z.string().nullable(),
        email: z.string(),
    }).nullable(),
});

const refinedSchema = schema.refine(data => {
    const {
        fullName,
        organization,
        socialMedia,
        email
    } = data.additionalMember;

    const isAnyRequiredFieldProvided: boolean = !!fullName || !!organization?.name || !!socialMedia;

    if (!isAnyRequiredFieldProvided) {
        return email === undefined || email === null || email.trim() === '';
    }

    return email.trim() !== '';
}, { message: 'Email is required.' });

type FormSchema = z.infer<typeof refinedSchema>;

const ApplyView = () => {
    const toast = useToast();
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const form = useForm<FormSchema>({
        resolver: zodResolver(refinedSchema),
    });

    const [presentationFiles, setPresentationFiles] = useState<File[]>([]);
    const [bgInfoFiles, setBgInfoFiles] = useState<File[]>([]);
    const [patentFiles, setPatentFiles] = useState<File[]>([]);
    const {
        handleSubmit,
        formState: { errors },
        reset,
        register
    } = form;

    const [apply, { loading }] = useApplyMutation();

    async function uploadFilesForApplication(applicationId: number) {
        const allFiles = [...presentationFiles, ...bgInfoFiles, ...patentFiles];
        const allPaths = [
            ...Array(presentationFiles.length).fill('presentationFiles'),
            ...Array(bgInfoFiles.length).fill('backgroundInformation'),
            ...Array(patentFiles.length).fill('patentDocuments')
        ];
        if (allFiles.length > 0) {
            return uploadFiles(
                allFiles,
                allPaths,
                applicationId.toString()
            );
        }
        return {};
    }

    const onSubmit = async (formData: FormSchema) => {
        try {
            setSubmitting(true);
            const cleaned: any = nullifyEmptyStringsAndObjects(formData);
            const {
                problemToSolve,
                projectImpact,
                roadMap,
                distinction ,
                additionalMember,
                ...rest
            } = cleaned as FormSchema;
            const answers = [
                ...(problemToSolve ? [{ questionId: 1, text: problemToSolve }] : []),
                ...(projectImpact ? [{ questionId: 2, text: projectImpact }] : []),
                ...(roadMap ? [{ questionId: 3, text: roadMap }] : []),
                ...(distinction ? [{ questionId: 4, text: distinction }] : []),
            ] ?? [];

            const application = await apply({
                variables: {
                    input: {
                        ...rest,
                        answers,
                        patentStatus: formData.patentStatus as PatentStatus,
                        website: formData.applicant.organization?.website,
                        ...(additionalMember && {
                            members: [additionalMember]
                        }),
                    }
                }
            })
            try {
                if (application.data?.apply.id) {
                    await uploadFilesForApplication(application.data?.apply.id);
                }
            } catch (error) {
                console.log(error)
                toast({
                    description: 'Error uploading Files',
                    status: 'error',
                    position: 'bottom',
                    duration: 9000,
                    isClosable: true,
                });
            }
            toast({
                description: 'Application submitted successfully',
                status: 'success',
                position: 'top',
                duration: 9000,
                isClosable: true,
            });
            setSubmitted(true);
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Something went wrong',
                status: 'error',
                position: 'top',
                duration: 9000,
                isClosable: true,
            })
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Container
            maxW='1280px'
            fontFamily='Roboto'
            sx={{
                Input: {
                    boxShadow: 'md',
                    _focus: {
                        border: '1px solid gray',
                        boxShadow: 'md',
                    },
                },
            }}>
            { submitted && (
                <Center w='100%' h='100vh'>
                    <Stack w='100%' alignItems='center' spacing={4}>
                        <Image src={LogoType} w={200} mb={3} />
                        <Heading size='md'>Thank you for your application!</Heading>
                        <Text color='gray.400' size='sm'>
                            A member of the VitaDAO Dealflow Working Group will be in touch.
                        </Text>
                        <Text>
                            <Link href={PATHS.REGISTER} textDecoration='underline'>
                                Register to keep track of your application here
                            </Link>
                        </Text>
                    </Stack>
                </Center>
            )}
            <Center w='100%' display={submitted ? 'none' : ''}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormProvider {...form}>
                        <Stack mt={10} w='100%' gap={3} boxShadow='lg' p={10}>
                            <Image src={LogoType} w={200} mb={5} />
                            <Heading size='md'>
                                Apply for VitaDAO Funding
                            </Heading>
                            <Spacer />
                            <Box fontSize='.8rem' boxSizing='border-box' color='gray'>
                                <Box>
                                    <Text as='p' fontStyle='italic' mb={4}>
                                        Completion time: 60-90 minutes
                                    </Text>
                                    <Spacer />
                                    <Text as='p' mb={4}>
                                        Welcome to the VitaDAO funding application form. Please complete
                                        each of the following sections to provide us with the information
                                        to assess your research project or startup concept.
                                    </Text>
                                    <Text as='p' mb={4}>
                                        Once your application is submitted, a member of the VitaDAO
                                        Dealflow Working Group will get in touch about next steps and
                                        follow-on information. If your project is suitable, it will be
                                        recommended to the wider Dealflow community before undergoing a
                                        final vote across the entire DAO.
                                    </Text>
                                    <Text as='p' mb='4'>
                                        If you have any questions about the application process or want to
                                        find out what kinds of research we fund, please read the&nbsp;
                                        <Link
                                            href='https://gov.vitadao.com/t/vdp-106-governance-proposal
                                            -eligibility-criteria-to-enter-the-vitadao-deal-flow-funnel/1343'
                                            target='_blank'
                                            textDecoration='underline'>
                                            VitaDAO project eligibility criteria
                                        </Link>
                                        .
                                    </Text>
                                </Box>
                            </Box>
                            <Divider orientation='horizontal'/>
                            <FormControl isInvalid={!!errors.applicant?.fullName} isRequired>
                                <FormLabel size='sm'>Project Lead or Founder Full Name</FormLabel>
                                <FormInput form={form} name='applicant.fullName' />
                            </FormControl>

                            <FormControl isInvalid={!!errors.applicant?.email} isRequired>
                                <FormLabel size='sm'>Project Lead or Founder Email</FormLabel>
                                <FormInput form={form} name='applicant.email' type='email' />
                            </FormControl>

                            <HStack spacing={4} mb={6} align='stretch'>
                                <FormControl isInvalid={!!errors.applicant?.organization?.name}>
                                    <FormLabel size='sm'>Project Lead or Founder Institution Name</FormLabel>
                                    <FormInput form={form} name='applicant.organization.name'/>
                                </FormControl>
                                <FormControl isInvalid={!!errors.applicant?.organization?.website}>
                                    <FormLabel size='sm'>Project Lead or Founder Institution Link</FormLabel>
                                    <FormInput form={form} name='applicant.organization.website'/>
                                </FormControl>
                            </HStack>

                            <FormControl isInvalid={!!errors.applicant?.socialMedia}>
                                <FormLabel size='sm'>
                                    Project Lead or Founder Google Scholar, ORCID, or LinkedIn link
                                </FormLabel>
                                <FormInput form={form} name='applicant.socialMedia'/>
                            </FormControl>

                            <FormControl isInvalid={!!errors.title} isRequired>
                                <FormLabel size='sm'>Project Title</FormLabel>
                                <HelperText>Enter your project title here</HelperText>
                                <FormInput form={form} name='title' />
                            </FormControl>

                            <FormControl isInvalid={!!errors.synopsis} isRequired>
                                <FormLabel size='sm'>Project Synopsis</FormLabel>
                                <HelperText>
                                    Share an ‘elevator pitch’ in 300 characters or less.
                                    You will be able to describe the project plan in detail in the following steps.
                                </HelperText>
                                <FormInput form={form} name='synopsis' />
                            </FormControl>

                            <FormControl isInvalid={!!errors.countryCode} isRequired>
                                <FormLabel size='sm'>Location</FormLabel>
                                <MultiSelect
                                    {...register('countryCode')}
                                    name='countryCode'
                                    options={lookup.countries.map(c => ({
                                        label: c.country,
                                        value: c.iso2
                                    }))}
                                    onChange={
                                        option => form.setValue(
                                            'countryCode',
                                            option?.value ?? ''
                                        )
                                    } />
                                {errors.countryCode &&
                                    <FormErrorMessage>{errors.countryCode.message}</FormErrorMessage>
                                }
                            </FormControl>

                            <FormControl isInvalid={!!errors.additionalMember?.fullName}>
                                <FormLabel size='sm'>Additional Team Member Name</FormLabel>
                                <FormInput form={form} name='additionalMember.fullName' />
                            </FormControl>
                            <HStack spacing={4} mb={6} align='stretch'>
                                <FormControl isInvalid={!!errors.additionalMember?.email}>
                                    <FormLabel size='sm'>Additional Team Member Email</FormLabel>
                                    <FormInput form={form} type='email' name='additionalMember.email' />
                                </FormControl>
                                <FormControl isInvalid={!!errors.additionalMember?.organization?.name}>
                                    <FormLabel size='sm'>Additional Team Member Institution</FormLabel>
                                    <FormInput form={form} name='additionalMember.organization.name' />
                                </FormControl>
                            </HStack>
                            <FormControl isInvalid={!!errors.additionalMember?.socialMedia}>
                                <FormLabel size='sm'>
                                    Additional Team Member Google Scholar, ORCID, or Linkedin profile link
                                </FormLabel>
                                <FormInput form={form} name='additionalMember.socialMedia' />
                            </FormControl>
                            <HStack spacing={4} mb={6} align='stretch'>
                                <FormControl isInvalid={!!errors.nihCategories}>
                                    <FormLabel size='sm'>Relevant MeSH Tree Number(s)</FormLabel>
                                    <HelperText>
                                        Please separate numbers with a comma.{' '}
                                        <Link href='https://meshb.nlm.nih.gov/search' isExternal>
                                            Search for MeSH Tree Numbers here.
                                        </Link>
                                    </HelperText>
                                    <FormInput form={form} name='nihCategories' />
                                </FormControl>
                                <FormControl isInvalid={!!errors.icdCodes}>
                                    <FormLabel size='sm'>Relevant ICD codes</FormLabel>
                                    <HelperText>
                                        Please separate codes with a comma.{' '}
                                        <Link href='https://icd.who.int/browse10/2019/en' isExternal>
                                            Browse the list of ICD Codes here.
                                        </Link>
                                    </HelperText>
                                    <FormInput form={form} name='icdCodes' />
                                </FormControl>
                            </HStack>
                            <FormControl isInvalid={!!errors.nihCategories}>
                                <FormLabel size='sm'>
                                    Describe your research area if it does not have a relevant a MeSH Tree Number
                                </FormLabel>
                                <FormInput form={form} name='nihNotCovered' />
                            </FormControl>
                            <FormControl isInvalid={!!errors.patentStatus}>
                                <FormLabel size='sm'>Patent Status</FormLabel>
                                <Select
                                    {...register('patentStatus')}
                                    placeholder=''
                                    defaultValue={PatentStatus.NOT_APPLICABLE}>
                                    {Object
                                        .values(PatentStatus)
                                        .map(status => (
                                            <option key={status} value={status}>
                                                {status.split('_').join(' ')}
                                            </option>
                                        ))}
                                </Select>
                            </FormControl>
                            <FormControl>
                                <FormLabel size='sm'>Development stage of asset(s)</FormLabel>
                                <HelperText>
                                    E.g. Screen, Hit-to-Lead, Lead Optimisation, Pre-Clinical, Phase I
                                </HelperText>
                                <FormInput form={form} name='assetDevelopmentStage' />
                            </FormControl>
                            <FormControl isInvalid={!!errors.problemToSolve}>
                                <FormLabel size='sm'>What problem is this project working to solve?</FormLabel>
                                <HelperText>
                                    <UnorderedList>
                                        <ListItem>
                                            What is the primary focus of the team&apos;s R&D efforts?
                                        </ListItem>
                                        <ListItem>
                                            What is the computational approach to identify novel targets?
                                            Does the data support this?
                                        </ListItem>
                                        <ListItem>
                                            What existing strategies have been taken to solve the problem,
                                            how does this project differ?
                                        </ListItem>
                                        <ListItem>
                                            How do you plan to address the risks associated with your research?
                                        </ListItem>
                                        <ListItem>
                                            How does this project stand out in the field of longevity science?
                                        </ListItem>
                                    </UnorderedList>
                                </HelperText>
                                <FormInput form={form} name='problemToSolve' />
                            </FormControl>
                            <FormControl isInvalid={!!errors.projectImpact}>
                                <FormLabel size='sm'>
                                    How will this project impact human longevity, healthspan, or lifespan?
                                </FormLabel>
                                <FormInput form={form} name='projectImpact' />
                            </FormControl>
                            <FormControl isInvalid={!!errors.roadMap}>
                                <FormLabel size='sm'>
                                    What is your plan and/or roadmap to IP? Tell us about your path to
                                    commercialisation
                                </FormLabel>
                                <HelperText>
                                    It may be helpful to break this down into a solution with stages,
                                    objectives and a timeline. Consider the potential applications of your
                                    technology
                                    and path to commercialisation
                                </HelperText>
                                <FormInput form={form} name='roadMap' />
                            </FormControl>
                            <FormControl isInvalid={!!errors.distinction}>
                                <FormLabel size='sm'>
                                    How does your approach and expertise set your project apart from
                                    other research in the same field?
                                </FormLabel>
                                <HelperText>
                                    What specific strengths does your project or company bring to the table?
                                </HelperText>
                                <FormInput form={form} name='distinction' />
                            </FormControl>
                            <HStack spacing={4} mb={6} align='stretch'>
                                <FormControl isInvalid={!!errors.fundingRequired} isRequired>
                                    <FormLabel size='sm'>
                                        How much capital do you plan to raise from VitaDAO? (USD)
                                    </FormLabel>
                                    <FormInput type='number' form={form} name='fundingRequired' />
                                </FormControl>
                                <FormControl isInvalid={!!errors.fundingRaised} isRequired>
                                    <FormLabel size='sm'>
                                        How much capital did you previously raise for this project? (USD)
                                    </FormLabel>
                                    <FormInput form={form} type='number' name='fundingRaised' />
                                </FormControl>
                            </HStack>
                            <FormControl id='presentation'>
                                <FormLabel size='sm'>Supporting Presentation Materials</FormLabel>
                                <HelperText>
                                    Upload a presentation or files that support your project submission.
                                    We suggest you to keep this information high level until
                                    an NDA has been signed
                                </HelperText>
                                <FileUploadInput
                                    onFilesSelected={files => setPresentationFiles([
                                        ...presentationFiles,
                                        ...files
                                    ])} />
                                <FileList
                                    size='lg'
                                    files={presentationFiles}
                                    onRemove={fl => setPresentationFiles(presentationFiles.filter(f => f !== fl))} />
                            </FormControl>
                            <FormControl id='background'>
                                <FormLabel size='sm'>Supporting Background Information</FormLabel>
                                <HelperText>
                                    Upload up to 10 scientific papers or other documents that will provide
                                    reviewers with information to help them evaluate your project. We suggest you to
                                    keep this information high level until an NDA has been signed.
                                    Make sure you are copyright compliant and submit only references.
                                </HelperText>
                                <FileUploadInput
                                    onFilesSelected={files => setBgInfoFiles([...bgInfoFiles, ...files])}/>
                                <FileList
                                    size='lg'
                                    files={bgInfoFiles}
                                    onRemove={fl => setBgInfoFiles(bgInfoFiles.filter(f => f !== fl))} />
                            </FormControl>
                            <FormControl id='patents' isRequired={false}>
                                <FormLabel size='sm'>Supporting Patents</FormLabel>
                                <HelperText>
                                    Upload any patents that are relevant to your work.
                                    Please note whether you or your team are inventors or not.
                                </HelperText>
                                <FileUploadInput
                                    onFilesSelected={files => setPatentFiles([...patentFiles, ...files])}/>
                                <FileList
                                    size='lg'
                                    files={patentFiles}
                                    onRemove={fl => setPatentFiles(patentFiles.filter(f => f !== fl))} />
                            </FormControl>
                            <HStack spacing={4} mb={6} align='stretch'>
                                <FormControl isInvalid={!!errors.referrer?.email}>
                                    <FormLabel size='sm'>Referrer Email</FormLabel>
                                    <HelperText>If applicable</HelperText>
                                    <FormInput form={form} type='email' name='referrer.email' />
                                </FormControl>
                                <FormControl isInvalid={!!errors.referrer?.fullName}>
                                    <FormLabel size='sm'>Referrer Full Name</FormLabel>
                                    <HelperText>If applicable</HelperText>
                                    <FormInput form={form} name='referrer.fullName' />
                                </FormControl>
                            </HStack>
                            <ButtonGroup spacing='auto'>
                                <Button
                                    colorScheme='blue'
                                    variant='ghost'
                                    mt={4}
                                    size='auto'
                                    type='reset'
                                    onClick={() => reset()}
                                    leftIcon={<MdOutlineRefresh style={{ transform: 'scaleX(-1)' }} />}>
                                    Clear Form
                                </Button>
                                <Button
                                    isLoading={submitting}
                                    isDisabled={submitting}
                                    mt={4}
                                    size='auto'
                                    type='submit'
                                    variant='black'
                                    w='20%'>
                                    Submit Application
                                </Button>
                            </ButtonGroup>
                        </Stack>
                    </FormProvider>
                </form>
            </Center>
            <Overlay show={loading} />
        </Container>
    );
}

export default ApplyView;
