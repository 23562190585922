const Heading = {
    baseStyle: {
        color: 'primary',
    },
    sizes: {
        '2xl': {
            fontSize: '72px',
            letterSpacing: '-0.02em',
            lineHeight: '1.25',
        },
        xl: {
            fontSize: '40px',
            letterSpacing: '-0.02em',
            lineHeight: '1.2',
        },
        lg: {
            fontSize: '36px',
            letterSpacing: '-0.02em',
            lineHeight: '1.25',
        },
        md: {
            fontSize: '30px',
            letterSpacing: '-0.02em',
            lineHeight: '1.22',
        },
        sm: {
            fontSize: '24px',
            letterSpacing: '-0.02em',
            lineHeight: '1.27',
            fontStyle: 'italic'
        },
        xs: {
            fontSize: '20px',
            letterSpacing: '-0.02em',
            lineHeight: '1.33',
            fontStyle: 'italic'
        },
        xxs: {
            fontSize: '16px',
            letterSpacing: '-0.02em',
            lineHeight: '1.24',
            fontStyle: 'italic'
        },
    },
};

export default Heading;