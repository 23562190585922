import { DealStage, } from '../graphql/generated';

const filterFromTab = (locationTab: string) => {
    const tab = locationTab.toUpperCase();
    const inactiveDealStages = [
        DealStage.ARCHIVED,
        DealStage.DISCORD_VOTE_FAILED,
        DealStage.DISCOURSE_VOTE_FAILED,
        DealStage.SNAPSHOT_VOTE_FAILED
    ];
    const activeDealStages = Object.values(DealStage).filter(value => !inactiveDealStages.includes(value));
    if (tab.includes('PHASE_')) {
        return { phase: { equals: tab }, dealStage: { in: activeDealStages } };
    } else {
        if (tab === 'REJECTED') {
            return {
                dealStage: {
                    in: [
                        DealStage.DISCORD_VOTE_FAILED,
                        DealStage.DISCOURSE_VOTE_FAILED,
                        DealStage.SNAPSHOT_VOTE_FAILED
                    ]
                }
            };
        }
        if (tab === 'NEW') {
            return { dealStage: { equals: DealStage.NEW } };
        }
        if (tab === 'FUNDED') {
            return {
                dealStage: {
                    in: [DealStage.FUNDED, DealStage.PENDING_FUNDING]
                }
            };
        }
        if (tab === 'ARCHIVED') {
            return { dealStage: { equals: DealStage.ARCHIVED } };
        }
    }
    return {};
};

export default filterFromTab;