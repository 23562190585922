import { Flex, Heading, Link, Text } from '@chakra-ui/react';
import React from 'react';

const ReviewFormDisclaimer = () => (
    <Flex bg='white' direction='column' alignItems='flex-start' gap={4} color='gray.500' mb={4} fontSize='sm'>
        <Heading size='sm'> VitaDAO Deal Size &gt;$100K: Senior Review Form</Heading>
        <Text mb={1}>This is the Senior Review Form for the VitaDAO Dealflow Working Group.</Text>
        <Text mb={1}>
            This form is for projects asking for &gt;$100K.
            This is a full review that requires a dive deep into the proposal.
            Compensation for such a review is 400 $VITA or 400 USDC.
        </Text>
        <Text mb={1}>
            Before completing the project evaluation,
            please read the disclaimers below:
        </Text>
        <Text mb={1}>Disclaimers:</Text>
        <Text mb={1}>
            * The opinions expressed in this Senior Review Form are for general
            informational purposes only. They should not be considered investment advice,
            a recommendation nor solicitation to buy or sell any securities or
            other financial instruments.
        </Text>
        <Text mb={1}>
            * The information on this form may contain third-party
            information over which we have no control.
        </Text>
        <Text mb={1}>
            * For FAQs about the Senior Review process:
        </Text>
        <Link
            href='https://vitadao.notion.site/Senior-Reviewer-FAQs-3ae04cd579584658aaeade9583c98dfd'
            isExternal
            textDecoration='underline'>
            https://vitadao.notion.site/Senior-Reviewer-FAQs-3ae04cd579584658aaeade9583c98dfd
        </Link>
        <Text mb={1} mt='4'>
            * This information will be aggregated anonymously
            for the community&apos;s consideration.
        </Text>
        <Text mb={1}>
            If you have any questions or feedback, please get in touch with the
            touch with the Project Shepherd or the Dealflow Working Group Steward
            at eleanor@vitadao.com
        </Text>
        <Text mb={1}>
            Thanks!
        </Text>
    </Flex>
);

export default ReviewFormDisclaimer;
