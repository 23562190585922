import VitaTable from '../../components/VitaTable/VitaTable';
import {
    ApplicationPhase,
    DealStage,
    FilterInput,
    useApplicationsQuery,
    useSavePreferenceMutation,
} from '../../graphql/generated';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AbsoluteCenter, Flex, Spacer, Tab, TabList, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import TableConfig from './ApplicationsTable.config';
import filterFromTab from '../../utils/filterFromTab';
import mergeColPreference from '../../utils/mergeColPreference';
import { ColumnConfig } from '../../components/VitaTable/types';
import { AuthContext } from '../../components/Providers/AuthProvider';

const ORDERED_PHASES = [
    'NEW',
    'PHASE_1',
    'PHASE_2',
    'PHASE_3',
    'FUNDED',
    'REJECTED',
    'ARCHIVED',
];

const APPLICATIONS_PER_PAGE = 20;

const MemberHome: React.FC = () => {
    const { account } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const baseFilter = filterFromTab(location.hash.slice(1).toUpperCase() || DealStage.NEW);
    const [activeTab, setActiveTab] = useState<string>(
        location.hash.slice(1).toUpperCase() || DealStage.NEW
    );
    const [colConfig, setColConfig] = useState<ColumnConfig[]>(TableConfig.columns);

    const [input, setInput] = useState<FilterInput>({
        orderBy: {},
        where: { ...baseFilter },
        take: APPLICATIONS_PER_PAGE
    });

    const { data, refetch, loading, error } = useApplicationsQuery({
        variables: { input },
    });

    const [savePreference] = useSavePreferenceMutation();

    const onFilter = useCallback((filters: any) => {
        setInput({
            ...input,
            where: { ...filters, }
        });
    }, [input]);

    const onSort = (colKey: string, order: string | undefined) => {
        const keys = colKey.split('.');
        const orderBy = keys.reduceRight((acc, key, index) => {
            if (index === keys.length - 1) {
                return { [key]: order };
            }
            return { [key]: acc };
        }, {});

        setInput({
            ...input,
            orderBy,
        });
    };

    const onSearch = (searchString: string) => {
        setInput({
            ...input,
            searchString: searchString !=='' ? searchString : undefined
        });
    };

    const onSelectTab = (index: number) => {
        setActiveTab(ORDERED_PHASES[index]);
        navigate('/#' + ORDERED_PHASES[index].toLowerCase());
    }

    const onPageChange = (page: number) => {
        setInput({
            ...input,
            skip: (page - 1) * APPLICATIONS_PER_PAGE
        });
    }

    const onPreferenceSave = async (key: string, value: any) => {
        const result = await savePreference({
            variables: {
                input: {
                    [key]: value
                }
            }
        });
        if (result.data?.savePreference) {
            setColConfig(mergeColPreference(TableConfig.columns, result.data.savePreference.applicationPreference));
        }
    }

    useEffect(() => {
        if (location.hash) {
            const tab = location.hash.slice(1).toUpperCase();
            const filters = { ...input.where };
            const tabFilter = filterFromTab(tab);
            filters['phase'] = tabFilter['phase'];
            filters['dealStage'] = tabFilter['dealStage'];
            setInput({
                ...input,
                where: { ...filters }
            });
        } else {
            navigate('#new')
        }
    }, [location.hash]);

    useEffect(() => {
        refetch();
    }, [input]);

    useEffect(() => {
        if (account?.preference?.applicationPreference) {
            setColConfig(mergeColPreference(TableConfig.columns, account?.preference?.applicationPreference));
        }
    }, [account]);

    return (
        <Tabs colorScheme='gray' defaultIndex={ORDERED_PHASES.indexOf(activeTab)} onChange={onSelectTab}>
            <TabList>
                {ORDERED_PHASES.map((key: string) => (
                    <React.Fragment key={key}>
                        <Tab color={`dealStage.${key}`}>
                            {key.split('_').join(' ')}
                        </Tab>
                        { key === ApplicationPhase.PHASE_3 && <Spacer /> }
                    </React.Fragment>
                ))}
            </TabList>
            <TabPanels h='100%'>
                <Flex mt={4} direction='column' flex='1'>
                    <VitaTable
                        colConfig={colConfig}
                        pageSize={APPLICATIONS_PER_PAGE}
                        totalItems={data?.applications.count}
                        onPageChange={onPageChange}
                        data={data?.applications.applications || []}
                        onSavePreference={onPreferenceSave}
                        preferenceKey='applicationPreference'
                        loading={loading}
                        onFilter={onFilter}
                        onSort={onSort}
                        onSearch={onSearch}
                        sort={input.orderBy} />
                </Flex>
                { !loading && error &&
                    <AbsoluteCenter>
                        <Text size='lg' color='red'>{ error.message }</Text>
                    </AbsoluteCenter>
                }
            </TabPanels>
        </Tabs>
    )
};

export default MemberHome;
