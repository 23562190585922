import { ApplicationsQuery, DealStage, useApplicationsQuery, useMyReviewsQuery } from '../../graphql/generated';
import React, { useEffect, useState } from 'react';
import { Box, Divider, Heading, HStack } from '@chakra-ui/react';
import ApplicationCard from './components/ApplicationCard';

type Applications = ApplicationsQuery['applications']['applications'];

const noScroll = {
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    scrollbarWidth: 'none',
}

type IGroup = { [key: string]: Applications }

const ReviewerHome: React.FC = () => {
    const { data } = useMyReviewsQuery();
    const { data: appData  } = useApplicationsQuery({
        variables: {
            input: { orderBy: {} }
        },
    });
    const [grouped, setGrouped] = useState<IGroup>(() => ({
        PENDING: [],
        IN_PROGRESS: [],
        DONE: [],
    }));

    useEffect(() => {
        const newGroup: IGroup = {
            PENDING: [],
            IN_PROGRESS: [],
            DONE: [],
        };
        if (data && appData) {
            appData
                .applications
                .applications
                .forEach(application => {
                    const review = data
                        .myReviews
                        .find(r => r.applicationId === application.id);
                    const hasStartedReview: boolean = !!review && !review.completed;
                    const hasCompletedReview: boolean = review?.completed ?? false;
                    const pendingSenior: boolean = application.dealStage === DealStage.PENDING_SENIOR_REVIEWS;

                    if (pendingSenior && !(hasStartedReview || hasCompletedReview)) {
                        newGroup.PENDING.push(application);
                    }
                    if (hasStartedReview && !hasCompletedReview) {
                        newGroup.IN_PROGRESS.push(application);
                    }
                    if (hasCompletedReview) {
                        newGroup.DONE.push(application);
                    }
                });
        }
        setGrouped(newGroup);
    }, [data, appData]);

    const sectionMaxH = 'calc(100vh - 150px - 30px)';

    return (
        <HStack height='calc(100vh - 150px)' align='center' justify='center' w='full'>
            <Box w='30%' h='100%'>
                <Heading size='xs' color='yellow.700' textAlign='center' h={30}>
                    To Review
                </Heading>
                <Divider my={1} />
                <Box overflowY='scroll' maxHeight={sectionMaxH} sx={noScroll}>
                    { grouped.PENDING.map((application: Applications[number]) => (
                        <ApplicationCard application={application} key={`app-card-${application.id}`} />
                    )) }
                </Box>
            </Box>
            <Divider orientation='vertical' />
            <Box w='30%' h='100%'>
                <Heading size='xs' color='blue.700' textAlign='center' h={30}>
                    In Progress
                </Heading>
                <Divider my={1} />
                <Box overflowY='scroll' maxHeight={sectionMaxH} sx={noScroll}>
                    { grouped.IN_PROGRESS.map((application: Applications[number]) => (
                        <ApplicationCard
                            review={data?.myReviews.find(r => r.applicationId === application.id)}
                            application={application}
                            key={`app-card-${application.id}`} />
                    )) }
                </Box>
            </Box>
            <Divider orientation='vertical' />
            <Box w='30%' h='100%'>
                <Heading size='xs' color='green.700' textAlign='center' h={30}>
                    Done
                </Heading>
                <Divider my={1} />
                <Box overflowY='scroll' maxHeight={sectionMaxH} sx={noScroll}>
                    { grouped.DONE.map((application: Applications[number]) => (
                        <ApplicationCard
                            review={data?.myReviews.find(r => r.applicationId === application.id)}
                            application={application}
                            key={`app-card-${application.id}`} />
                    )) }
                </Box>
            </Box>
        </HStack>
    )
}

export default ReviewerHome;
