import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
    Button,
    Center,
    Container,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Image,
    Input,
    Link,
    Stack,
    useToast
} from '@chakra-ui/react';
import { useSendResetPasswordMutation } from '../../graphql/generated';
import LogoType from '../../assets/logos/logotype.svg';

const schema = z.object({
    email: z.string().email(),
});

type FormSchema = z.infer<typeof schema>;

const ForgotView = () => {
    const toast = useToast();
    const { getValues, register, handleSubmit, formState: { errors } } = useForm<FormSchema>({
        resolver: zodResolver(schema),
    });

    const [sendForgotPw, { loading, data }] = useSendResetPasswordMutation({
        onError: error => {
            toast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                position: 'top',
                isClosable: true,
            })
        }
    });

    const onSubmit = async () => {
        await sendForgotPw({
            variables: {
                email: getValues('email')
            }
        });
    };

    return (
        <Container maxW='1280px' py={10}>
            <Center w='100%'>
                { !data &&
                    <Flex direction='column' alignItems='center' maxW={520} w='40vw'>
                        <Image src={LogoType} w={250} />
                        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                            <Stack mt={10} w='100%' gap={3} boxShadow='lg' p={10}>
                                <FormControl id='email' isInvalid={!!errors.email}>
                                    <FormLabel>Email address</FormLabel>
                                    <Input type='email' {...register('email')} placeholder='xyz@vitadao.com'/>
                                    <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                                </FormControl>
                                <Button isLoading={loading} mt={4} variant='black' type='submit' w='100%'>
                                    Submit
                                </Button>
                            </Stack>
                        </form>
                    </Flex>
                }

                {data &&
                    <Stack gap={2}>
                        <Heading color='grean.400'>
                            If an account exists you will receive an email with instructions
                        </Heading>
                        <Link href='/site/src/views/authViews/Login'>
                            Back to login
                        </Link>
                    </Stack>
                }
            </Center>
        </Container>
    );
}

export default ForgotView;