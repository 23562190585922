import React, { useContext, useMemo } from 'react';
import {
    Badge, Box,
    Button, ButtonGroup,
    Divider,
    HStack,
    Icon,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Stack,
    Text,
    useDisclosure
} from '@chakra-ui/react';
import { AiFillThunderbolt } from 'react-icons/ai';
import { AccountRole, ApplicationsQuery, Contact } from '../graphql/generated';
import formatCompany from '../utils/formatCompany';
import LinkIcon from './Icon/LinkIcon';
import { BsPerson } from 'react-icons/bs';
import { AuthContext } from './Providers/AuthProvider';
import { useNavigate } from 'react-router-dom';

type ApplicationQ = ApplicationsQuery['applications']['applications'][number];

interface ContactCellProps {
    contact: ApplicationQ['applicant'] | ApplicationQ['referrer'] | Contact;
    extraTitle?: string;
    extraIcon?: React.ReactElement;
    extension?: React.ReactElement;
}

const CrmContact: React.FC<ContactCellProps> = ({ contact, extraTitle, extraIcon, extension }) => {
    const { account } = useContext(AuthContext);
    const { onOpen, onClose, isOpen} = useDisclosure();
    const navigate = useNavigate();

    const nameDisplay = useMemo(() => {
        if (!contact) {
            return 'N/A';
        }
        return contact.fullName && contact.fullName.trim() != ''
            ? contact.fullName.split(' ')[contact.fullName.split(' ').length - 1]
            : contact.email.slice(0, 10);
    }, [contact]);

    return (
        <>
            { contact &&
                <Popover isOpen={isOpen} onClose={onClose}>
                    <PopoverTrigger>
                        <ButtonGroup isAttached m={1}>
                            <Button size='xs' variant='person' onClick={onOpen} borderWidth={1}>
                                <Icon as={BsPerson} mr={1} color='blue.400' />
                                { nameDisplay }
                                {extraTitle && ` (${extraTitle})`}
                                {extraIcon && extraIcon}
                            </Button>
                            { extension && extension }
                        </ButtonGroup>
                    </PopoverTrigger>
                    <PopoverContent >
                        <PopoverCloseButton />
                        <PopoverHeader textAlign='center'>
                            <Badge colorScheme='blue' userSelect='none'>
                                <HStack>
                                    <Icon as={AiFillThunderbolt} />
                                    <Text>
                                        CRM
                                    </Text>
                                </HStack>
                            </Badge>
                        </PopoverHeader>
                        <PopoverBody p={3} boxShadow='xl'>
                            <Stack fontFamily='Roboto' textTransform='none'>
                                <Text size='md'>
                                    { contact.fullName ?? 'Not Set' }
                                </Text>
                                <Text>
                                    { contact.email }
                                </Text>
                                { account?.role === AccountRole.ADMIN &&
                                    <Button
                                        size='xs'
                                        variant='link'
                                        onClick={() => navigate(`/crm/contacts/${contact.id}`)}>
                                        see more
                                    </Button>
                                }
                                <Divider />
                                <Text>
                                    ORGANIZATIONS
                                </Text>
                                <Stack fontSize='sm'>
                                    { contact
                                        .organizations
                                        ?.map((org: any) => (
                                            <Text key={`org-${org.id}`} size='xs'>
                                                { formatCompany(org) }
                                                <LinkIcon href={org.organization.website} ml={1} fontSize={8} />
                                            </Text>
                                        ))
                                    }
                                    { (!contact.organizations || contact.organizations.length === 0) &&
                                        <Text size='xxs' color='gray'>No affiliations</Text>
                                    }
                                </Stack>
                                <Divider />
                                <Text>
                                    EXPERTISE
                                </Text>
                                <Text
                                    size='xxs'
                                    color='gray'
                                    overflowX='auto'
                                    whiteSpace='nowrap'
                                    textOverflow='ellipsis'>
                                    <Stack p={1} gap={1}>
                                        { contact.areaOfExpertise?.split(',').map(expertise => (
                                            <Badge variant='outline' key={expertise}>{ expertise }</Badge>
                                        )) }
                                        { !contact.areaOfExpertise && <Text color='gray.400' size='xxs'>N/A</Text> }
                                    </Stack>
                                </Text>
                                <Divider />
                                <Text>
                                    Vita Roles
                                </Text>
                                <Text
                                    size='xxs'
                                    color='gray'
                                    overflowX='auto'
                                    whiteSpace='nowrap'
                                    textOverflow='ellipsis'>
                                    <Stack fontSize='sm' gap={1}>
                                        { contact.vitaRoles?.split(',').map(role => (
                                            <Badge variant='outline' key={role}>{ role }</Badge>
                                        )) }
                                        { !contact.vitaRoles &&
                                            <Text size='xxs' color='gray'>N/A</Text>
                                        }
                                    </Stack>
                                </Text>
                            </Stack>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            }
        </>
    );
}

export default CrmContact;
