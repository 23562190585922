import { HStack, Icon, Stack, Text } from '@chakra-ui/react';
import moment from 'moment';
import React, { useMemo } from 'react';
import { ImBlocked } from 'react-icons/im';
import { MdOutlineTipsAndUpdates } from 'react-icons/md';
import { ApplicationQuery } from '../../graphql/generated';

type WorkingGroupUpdate = NonNullable<ApplicationQuery['application']['workingGroupUpdates']>[number];
type WorkingGroup = ApplicationQuery['application']['workingGroup'];

interface IWgUpdateProps {
    update: WorkingGroupUpdate;
    workingGroup: WorkingGroup;
}

const WorkingGroupUpdate: React.FC<IWgUpdateProps> = ({ update, workingGroup }) => {
    const { account, blocking, text, createdAt } = update;
    const fromAdmin = useMemo(() => account.role === 'ADMIN', [account]);
    const fromShepherd = useMemo(() => workingGroup?.find(member =>
        member.account.id === account.id && member.connected && member.isShepherd), [workingGroup, account]);

    const isCurrentMember = useMemo(() => {
        return fromAdmin
            || fromShepherd
            || !!workingGroup?.find(member => member.account.id === account.id && member.connected);
    }, [workingGroup, fromAdmin, fromShepherd]);

    return (
        <Stack gap={0}>
            <HStack>
                <Icon
                    color={blocking ? 'red' : 'green'}
                    as={blocking ? ImBlocked : MdOutlineTipsAndUpdates}
                    size={22} title='Medium' />
                <Stack gap={0}>
                    <Text
                        color='gray.600'
                        fontWeight='bold'
                        size='sm'
                        textDecoration={!isCurrentMember ? 'line-through' : undefined}>
                        { account.contact?.fullName }
                        { fromAdmin && ' (Steward)' }
                        { !fromAdmin && fromShepherd && ' (Shepherd)'}
                    </Text>
                    <Text size='xs' color='gray'>
                        { moment(createdAt).format('DD MMM YY HH:MM') }
                    </Text>
                </Stack>
            </HStack>
            <Text ml={22} mb={2} size='md' color='gray.900'>{text}</Text>
        </Stack>
    )};

export default WorkingGroupUpdate;
