import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { Suspense } from 'react';
import Home from './views/Home';
import Login from './views/authViews/Login';
import Forgot from './views/authViews/Forgot';
import { PATHS } from './config';
import Register from './views/authViews/Register';
import Loader from './components/Loader';
import Application from './views/Application';
import Apply from './views/Apply';
import Confirm from './views/authViews/Confirm';
import SeniorReview from './views/SeniorReview';
import Crm from './views/Crm';
import NotOnboarded from './views/authViews/NotOnboarded';
import Account from './views/Account';
import Contact from './views/Crm/Contact';
import Reset from './views/authViews/Reset';
import Kpi from './views/Kpi';

const AppRoutes = () => (
    <BrowserRouter>
        <Suspense fallback={<Loader />}>
            <Routes>
                {/* ADMIN */}
                <Route path={PATHS.CRM} element={<Crm />} />
                <Route path={PATHS.CONTACT} element={<Contact />} />
                <Route path={PATHS.KPI} element={<Kpi />} />
                {/* PRIVATE */}
                <Route path={PATHS.HOME} element={<Home />} />
                <Route path={PATHS.ACCOUNT} element={<Account />} />
                <Route path={PATHS.APPLICATION} element={<Application />} />
                <Route path={PATHS.REVIEW} element={<SeniorReview />} />
                <Route path={PATHS.CONFIRM_EMAIL} element={<Confirm />} />
                <Route path={PATHS.ONBOARD} element={<NotOnboarded />} />
                {/* PUBLIC VIEWS */}
                <Route path={PATHS.APPLY} element={<Apply />} />
                <Route path={PATHS.REGISTER} element={<Register />} />
                <Route path={PATHS.LOGIN} element={<Login />} />
                <Route path={PATHS.RESET} element={<Reset />} />
                <Route path={PATHS.FORGOT_PASSWORD} element={<Forgot />} />
            </Routes>
        </Suspense>
    </BrowserRouter>
);

export default AppRoutes;
