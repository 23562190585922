import React from 'react';
import { Badge, Button, HStack, Stack, Text } from '@chakra-ui/react';
import { ApplicationQuery } from '../../../graphql/generated';
import moment from 'moment';
import StarScore from '../../../components/StarScore';

type Review = ApplicationQuery['application']['reviews'][number];

interface ReviewPreviewProps {
    seniorReview: Review;
    onViewReview: (review: Review) => void;
}

const ReviewPreview: React.FC<ReviewPreviewProps> = ({ seniorReview, onViewReview }) => (
    <Stack borderBottomWidth={1} borderTopWidth={1} my={1}>
        <Stack gap={0}>
            <Text>{seniorReview.reviewer?.contact?.fullName}</Text>
            <Text size='xxs' color='gray'>
                last Updated { moment.utc(seniorReview.updatedAt).fromNow() }
            </Text>
        </Stack>
        <HStack userSelect='none'>
            <Badge bg={seniorReview.hasConflict ? 'red' : 'green'} color='white' w='11em' textAlign='center'>
                { seniorReview.hasConflict ? 'Conflict' : 'No Conflict' }
            </Badge>
            <Badge bg={seniorReview.completed ? 'green' : 'orange'} color='white' w='11em' textAlign='center'>
                { seniorReview.completed ? 'Completed' : 'Not Completed' }
            </Badge>
        </HStack>

        <HStack>
            <Text size='sm'>Summary Score:</Text>
            <StarScore rating={seniorReview.evaluationSummaryScore} size={4}/>
        </HStack>
        <Button size='xs' onClick={() => onViewReview(seniorReview)}>
            View Review
        </Button>
    </Stack>
);

export default ReviewPreview;
