import { ColumnConfig } from '../components/VitaTable/types';


const mergeColPreference = (preference: ColumnConfig[], savedPreference?: any[]): ColumnConfig[] => {
    if (savedPreference && savedPreference.length > 0) {
        const preferenceMap = new Map(preference.map(col => [col.key, col]));
        return savedPreference.map(pref => {
            if (preferenceMap.has(pref.key)) {
                return {
                    ...preferenceMap.get(pref.key),
                    ...pref,
                };
            }
            return pref;
        });
    }
    return preference;
};

export default mergeColPreference;
