import ViewContainer from '../../components/View/ViewContainer';
import React from 'react';
import {
    Badge,
    Box,
    Button,
    Heading,
    HStack,
    Icon,
    SimpleGrid,
    Spacer,
    Stack,
    Text,
    useDisclosure
} from '@chakra-ui/react';
import { ApplicationPhase, ContactQuery, DealStage, useContactQuery } from '../../graphql/generated';
import { useParams } from 'react-router-dom';
import ApplicationPhaseBadge from '../../components/Badge/ApplicationPhaseBadge';
import DealStageBadge from '../../components/Badge/DealStageBadge';
import CreateContactModal from './Modals/CreateContactModal';
import { FaEdit } from 'react-icons/fa';

interface ContactProps {
    header: string;
    applications: ContactQuery['contact']['leading'];
}

const ApplicationSection: React.FC<ContactProps> = ({ applications, header }) => (
    <Stack bg='gray.100' p={2}>
        <Text fontWeight='bold' mb={0}>{ header }</Text>
        <Stack bg='gray.100' p={1}>
            { applications?.map(app => (
                <HStack key={`appentry-${app.id}`} borderBottomWidth={1} mb={1} pb={1}>
                    <Text size='md' as='a' href={`/application/${app.id}`} target='_blank'>
                        {app.id}. { app.title }
                    </Text>
                    <Spacer />
                    <ApplicationPhaseBadge phase={app.phase as ApplicationPhase} />
                    <DealStageBadge stage={app.dealStage as DealStage} />
                </HStack>

            )) }
            { (!applications || applications.length === 0) &&
                <Text color='gray.400' size='sm'>
                    No applications
                </Text> }
        </Stack>
    </Stack>
);

const Contact = () => {
    const { contactId } = useParams();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { data } = useContactQuery({
        skip: !contactId,
        variables: {
            contactId: Number(contactId)
        }
    });

    const contact = data?.contact;

    return (
        <ViewContainer maxW='100vw' p={0}>
            { contact &&
                <HStack w='100%' h='100vh' px={2}>
                    <Stack w={450} gap={5} borderRightWidth={1} h='100%' overflowY='auto'>
                        <Stack gap={0}>
                            <Heading size='sm'>
                                { contact.fullName }
                            </Heading>
                            <Button onClick={() => onOpen()} size='xs' variant='black'>
                                <Icon as={FaEdit} mr={2} />
                                <Text>Edit Contact</Text>
                            </Button>
                        </Stack>

                        <Stack>
                            <Text fontWeight='bold' mb={1}>Details</Text>
                            <SimpleGrid columns={2} gap={2}>
                                <Text variant='gridLabel'>Email</Text>
                                <Text size='sm'>{ contact.email }</Text>
                                <Text variant='gridLabel'>Phone number</Text>
                                <Text size='sm'>{ contact.phoneNumber ?? '-' }</Text>
                                <Text variant='gridLabel'>Role</Text>
                                <Text size='sm'>
                                    { contact.account?.role ?? '-' }
                                </Text>
                                <Text variant='gridLabel'>Location</Text>
                                <Text size='sm'>{ contact.location ?? '-' }</Text>
                                <Text variant='gridLabel'>Socials</Text>
                                <Text size='sm'>{ contact.socialMedia ?? '-' }</Text>
                                <Text variant='gridLabel'>Diversity</Text>
                                <Text size='sm'>{ contact.diversity ?? '-' }</Text>
                            </SimpleGrid>
                        </Stack>

                        <Stack>
                            <Text fontWeight='bold' mb={1}>Notes</Text>
                            <Box bg='gray.100' p={2}>
                                { !contact.notes && <Text color='gray.400' size='sm'>No notes</Text> }
                                { contact.notes && <Text>{ contact.notes }</Text> }
                            </Box>
                        </Stack>

                        <Stack>
                            <Text fontWeight='bold' mb={1}>Vita Roles</Text>
                            <Box bg='gray.100' p={2}>
                                { contact.vitaRoles?.split(',').map(role => (
                                    <Badge m={1} variant='outline' key={role}>{ role }</Badge>
                                )) }
                                { !contact.vitaRoles && <Text color='gray.400' size='sm'>None assigned</Text> }
                            </Box>
                        </Stack>

                        <Stack>
                            <Text fontWeight='bold' mb={1}>Area of expertise</Text>
                            <Box bg='gray.100' p={1}>
                                { contact.areaOfExpertise?.split(',').map(expertise => (
                                    <Badge m={1} variant='outline' key={expertise}>{ expertise }</Badge>
                                )) }
                                { !contact.areaOfExpertise && <Text color='gray.400' size='sm'>None</Text> }
                            </Box>
                        </Stack>

                        <Stack>
                            <Text fontWeight='bold' mb={1}>Organizations/Affiliations</Text>
                            <Box bg='gray.100' p={2}>
                                { contact.organizations?.map(aff => (
                                    <Stack key={aff.organization.id}>
                                        <Text size='md' color='gray.600'>
                                            { aff.organization.name }
                                        </Text>
                                        <Text>
                                            { aff.role && <Badge>{ aff.role }</Badge> }
                                        </Text>
                                    </Stack>
                                )) }

                                { (!contact.organizations || contact.organizations?.length === 0) &&
                                    <Text color='gray.400' size='sm'>
                                        No affiliations
                                    </Text>
                                }
                            </Box>
                        </Stack>
                    </Stack>

                    <Stack h='100%'  w='100%' overflowY='scroll'>
                        <ApplicationSection header='Applied' applications={contact.leading} />
                        <ApplicationSection header='Member' applications={contact.memberOf} />
                        <ApplicationSection header='Referred' applications={contact.referrals} />
                    </Stack>
                </HStack>
            }
            <CreateContactModal isOpen={isOpen} onClose={onClose} contact={contact} />
        </ViewContainer>
    )
};

export default Contact;
