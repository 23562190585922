import ViewContainer from '../components/View/ViewContainer';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../components/Providers/AuthProvider';
import { Button, Center, Input, Stack, Text, useToast } from '@chakra-ui/react';
import formatEnum from '../utils/formatEnum';
import { useSendResetPasswordMutation, useUpdateAccountMutation } from '../graphql/generated';

const Account = () => {
    const toast = useToast();
    const { account, setAuthValues } = useContext(AuthContext);
    const [fullname, setFullname] = useState('');

    const [updateAccount, { loading }] = useUpdateAccountMutation({
        onCompleted: () => {
            toast({
                title: 'Account updated',
                status: 'success',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
        },
    });

    const [sendForgotPw] = useSendResetPasswordMutation({
        onCompleted: () => {
            toast({
                title: 'Password reset email sent',
                description: 'Check your emails for a link to reset your password',
                status: 'success',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
        },
        onError: error => {
            toast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                position: 'top',
                isClosable: true,
            })
        }
    });

    const onSendForgotPw = useCallback(() => {
        if (!account) return;
        sendForgotPw({
            variables: { email: account.email }
        });
    }, [account]);

    const onUpdateAccount = useCallback(async () => {
        if (!account) return;
        const updatedAccount = await updateAccount({
            variables: {
                input: {
                    fullName: fullname
                }
            }
        });
        if (updatedAccount.data?.updateAccount) {
            setAuthValues(updatedAccount.data.updateAccount, true);
        }
    }, [account, fullname]);

    useEffect(() => {
        if (account) {
            setFullname(account.contact?.fullName ?? '');
        }
    }, [account]);

    return (
        <ViewContainer>
            <Center>
                {account &&
                    <Stack textAlign='center' gap={5}>
                        <Stack gap={0}>
                            <Text fontSize='2xl'>Account</Text>
                            <Text fontSize='sm' color='gray.400'>Edit your account settings</Text>
                        </Stack>
                        <Stack gap={0}>
                            <Text fontWeight='bold'>Full Name</Text>
                            <Input value={fullname} onChange={e => setFullname(e.target.value)} />
                        </Stack>
                        <Stack gap={0}>
                            <Text fontWeight='bold'>Email</Text>
                            <Text userSelect='none'>
                                { account.email }
                            </Text>
                        </Stack>
                        <Stack gap={0}>
                            <Text fontWeight='bold'>Role</Text>
                            <Text userSelect='none'>
                                { formatEnum(account.role ?? '') }
                            </Text>
                        </Stack>

                        <Stack>
                            <Text fontWeight='bold'>Password: ********</Text>
                            <Button size='sm' variant='link' onClick={onSendForgotPw}>change</Button>
                        </Stack>
                        <Button
                            variant='black'
                            isDisabled={fullname === '' || account.contact?.fullName === fullname}
                            isLoading={loading}
                            loadingText='Updating'
                            onClick={onUpdateAccount}>
                            Save
                        </Button>
                    </Stack>
                }
            </Center>
        </ViewContainer>
    )
}

export default Account;
