import { ApolloClient, InMemoryCache } from '@apollo/client';

export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:9000';
export const WEBSOCKET_URL = 'wss://' + (process.env.REACT_API_URL ?? '')
    .replace(/^https?:\/\//, '') || 'ws://localhost:9000';

export const apolloClient = new ApolloClient({
    uri: `${API_URL}/graphql`,
    credentials: 'include',
    cache: new InMemoryCache({
        addTypename: true
    }),
});

export const PATHS = {
    ACCOUNT: '/account',
    APPLY: '/apply',
    APPLICATION: '/application/:applicationId',
    CRM: '/crm/contacts',
    CONTACT: '/crm/contacts/:contactId',
    CONFIRM_EMAIL: '/confirm-email',
    HOME: '/',
    FORGOT_PASSWORD: '/forgot-password',
    KPI: '/kpi',
    LOGIN: '/login',
    ONBOARD: '/not-onboard',
    REGISTER: '/register',
    RESET: '/reset',
    REVIEW: '/review/:applicationId',

};
