import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Stack,
    Textarea,
    useToast
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { ApplicationQuery, useCreateWgUpdateMutation } from '../../../graphql/generated';
import FormInput from '../../../components/Input/FormInput';

type ApplicationQ = ApplicationQuery['application'];

interface Props {
    application: ApplicationQ;
    isOpen: boolean;
    onClose: () => void;
}

const schema = z.object({
    text: z.string().min(10, { message: 'Update should at least be 10 letters long' }),
    blocking: z.boolean(),
});

export type FormSchema = z.infer<typeof schema>;

const NewWgUpdateModal: React.FC<Props> = ({ application, isOpen, onClose }) => {
    const toast = useToast();

    const form = useForm<FormSchema>({
        resolver: zodResolver(schema),
        defaultValues: {
            text: '',
            blocking: false,
        }
    });
    const { handleSubmit, reset, formState: { errors } } = form;

    useEffect(() => {
        reset({
            text: '',
            blocking: false
        });
    }, [application, reset]);

    const [createWgUpdate, { loading }] = useCreateWgUpdateMutation({
        update: (cache, { data }) => {
            cache.modify({
                id: cache.identify(application),
                fields: {
                    workingGroupUpdates(existingWorkingGroupUpdates = []) {
                        return [...existingWorkingGroupUpdates, data];
                    }
                }
            });
        },
        onError: error => {
            toast({
                title: 'Could not post update',
                description: error.message,
                status: 'error',
                position: 'top',
                duration: 5000,
                isClosable: true,
            });
        }
    });

    const onSubmit = async (formData: FormSchema) => {
        await createWgUpdate({
            variables: {
                input: {
                    ...formData,
                    applicationId: application.id
                },
            },
        });
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormProvider {...form}>
                        <ModalCloseButton />
                        <ModalBody>
                            <Stack spacing={4}>
                                <FormControl
                                    isInvalid={!!errors.text}>
                                    <FormLabel size='sm' textAlign='center'>
                                        Add an application update
                                    </FormLabel>
                                    <Textarea
                                        {...form.register('text')}
                                        name='text'
                                        placeholder='Write an update' />
                                    {errors.text && <FormErrorMessage>{errors.text.message}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={!!errors.blocking}>
                                    <FormInput
                                        form={form}
                                        type='check'
                                        placeholder='Blocker'
                                        defaultValue={false}
                                        name='blocking'/>
                                </FormControl>
                            </Stack>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='black' type='submit' isLoading={loading} w='full'>Post</Button>
                        </ModalFooter>
                    </FormProvider>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default NewWgUpdateModal;
