import { Button, HStack, Icon, Stack, Text, useDisclosure } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import WorkingGroupUpdate from './WorkingGroupUpdate';
import { AiOutlineEdit } from 'react-icons/ai';
import NewWgUpdateModal from './Modals/NewWgUpdateModal';
import { ApplicationQuery } from '../../graphql/generated';
import ReviewPreview from './components/ReviewPreview';
import ReviewModal from './Modals/ReviewModal';

const ACTION_BG = 'yellow.100';
const REG_BG = 'gray.100';

interface StaffUpdatesProps {
    application: ApplicationQuery['application'];
    canUpdate: boolean;
}

type Review = ApplicationQuery['application']['reviews'][0];

const StaffUpdates: React.FC<StaffUpdatesProps> = ({ application, canUpdate }) => {
    const { isOpen: isOpenWgUpdate, onOpen: onOpenWgUpdate, onClose: onCloseWgUpdate } = useDisclosure();
    const { isOpen: isReviewOpen, onOpen: onOpenReview, onClose: onCloseReview } = useDisclosure();
    const [activeReview, setActiveReview] = useState<Review | null>(null);

    const wgUpdates = useMemo(() => {
        if (!application) return [];
        const updates = [...(application.workingGroupUpdates ?? [])];
        updates.reverse();
        return updates;
    }, [application]);

    const onViewReview = (review: Review) => {
        onOpenReview();
        setActiveReview(review)
    }

    const onCloseReviewModal = () => {
        onCloseReview();
        setActiveReview(null);
    }

    const finishedReviews = application.reviews.filter(r => r.completed);

    return (
        <Stack alignSelf='flex-start' w='29%' gap={0}>
            <Stack minH={120} bg={REG_BG} p={2}>
                <Text size='xs' textTransform='uppercase' w='100%' textAlign='center'>
                    Senior Reviews
                </Text>
                { application.reviews.length === 0 &&
                    <Text color='gray' textAlign='center'>
                        No reviews started.
                    </Text>
                }
                { finishedReviews.length > 0 &&
                    <Stack>
                        <Text size='xs' textAlign='center'>
                            Average Scores
                        </Text>
                        <HStack>
                            <Text size='xs' textAlign='center' fontWeight='bold'>
                                Summary
                            </Text>
                            <Text size='xs' textAlign='center'>
                                { finishedReviews
                                    .map(r => r.evaluationSummaryScore)
                                    .reduce((acc, score) => acc + score, 0) / finishedReviews.length }
                            </Text>
                        </HStack>
                        <HStack>
                            <Text size='xs' textAlign='center' fontWeight='bold'>
                                Team Expertise
                            </Text>
                            <Text size='xs' textAlign='center'>
                                { finishedReviews
                                    .map(r => r.teamExpertiseScore)
                                    .reduce((acc, score) => acc + score, 0) / finishedReviews.length }
                            </Text>
                        </HStack>
                        <HStack>
                            <Text size='xs' textAlign='center' fontWeight='bold'>
                                Commercial Potential and Impact
                            </Text>
                            <Text size='xs' textAlign='center'>
                                { finishedReviews
                                    .map(r => r.commercialScore)
                                    .reduce((acc, score) => acc + score, 0) / finishedReviews.length }
                            </Text>
                        </HStack>
                        <HStack>
                            <Text size='xs' textAlign='center' fontWeight='bold'>
                                Deal Terms
                            </Text>
                            <Text size='xs' textAlign='center'>
                                { finishedReviews
                                    .map(r => r.dealTermsScore)
                                    .reduce((acc, score) => acc + score, 0) / finishedReviews.length }
                            </Text>
                        </HStack>
                        <HStack>
                            <Text size='xs' textAlign='center' fontWeight='bold'>
                                Feasibility
                            </Text>
                            <Text size='xs' textAlign='center'>
                                { finishedReviews
                                    .map(r => r.feasibilityScore)
                                    .reduce((acc, score) => acc + score, 0) / finishedReviews.length }
                            </Text>
                        </HStack>
                        <HStack>
                            <Text size='xs' textAlign='center' fontWeight='bold'>
                                IP Defensibility
                            </Text>
                            <Text size='xs' textAlign='center'>
                                { finishedReviews
                                    .map(r => r.ipDefensibilityScore)
                                    .reduce((acc, score) => acc + score, 0) / finishedReviews.length }
                            </Text>
                        </HStack>
                    </Stack>
                }
                { application.reviews.map((review, idx) => (
                    <ReviewPreview seniorReview={review} key={`review-${idx}`} onViewReview={onViewReview}/>
                )) }
            </Stack>
            <Stack minH={120} bg={canUpdate ? ACTION_BG: REG_BG} p={2}>
                <Text size='xs' textTransform='uppercase' w='100%' textAlign='center'>
                    WG UPDATES
                </Text>
                <Button
                    variant='actionEdit'
                    w='full'
                    size='xs'
                    isDisabled={!canUpdate}
                    onClick={onOpenWgUpdate}>
                    <Icon as={AiOutlineEdit} />
                </Button>
                { wgUpdates
                    .map(((update: any, index: number) => (
                        <WorkingGroupUpdate
                            key={`wgupdate-${index}`}
                            update={update}
                            workingGroup={application.workingGroup} />
                    )))}
                { wgUpdates.length === 0 &&
                    <Text color='gray' textAlign='center'>
                        No updates provided.
                    </Text>
                }
            </Stack>
            <NewWgUpdateModal application={application} isOpen={isOpenWgUpdate} onClose={onCloseWgUpdate} />
            <ReviewModal review={activeReview} isOpen={isReviewOpen} onClose={onCloseReviewModal} />
        </Stack>
    )
}

export default StaffUpdates;
