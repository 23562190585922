import {
    Button,
    Center,
    Divider,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    SimpleGrid,
    Stack,
    Text
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { ApplicationReviewsQuery } from '../../../graphql/generated';
import StarScore from '../../../components/StarScore';
import SeniorReviewForm from '../../SeniorReview/SeniorReviewForm';
import formatEnum from '../../../utils/formatEnum';

type Reviews = ApplicationReviewsQuery['applicationReviews']

interface ReviewsPreviewModalProps {
    reviews: Reviews;
    onClose: () => void;
    isOpen: boolean;
}

const ReviewsPreviewModal = ({ reviews, onClose, isOpen }: ReviewsPreviewModalProps) => {
    const [selectedReview, setSelectedReview] = useState<Reviews[number] | null>(null);

    const onCloseModal = () => {
        setSelectedReview(null);
        onClose();
    }

    return (
        <Modal isOpen={isOpen} onClose={onCloseModal} size='lg'>
            <ModalOverlay />
            <ModalContent>
                <ModalBody pb={2}>
                    { reviews
                        .filter((review: Reviews[number]) => !selectedReview
                            ? true
                            : review.reviewer?.id === selectedReview.reviewer?.id)
                        .map((review: Reviews[number], idx: number) => (
                            <Stack key={`review-${idx}`} borderWidth={1}>
                                <Text p={2}>Review #{ idx + 1 }</Text>
                                <Divider />
                                <SimpleGrid columns={2} p={2}>
                                    <Text>Summary Score</Text>
                                    <StarScore rating={review.evaluationSummaryScore} size={4}  />

                                    <Text>Completed</Text>
                                    <Text>{ review.completed ? 'YES' : 'NO' }</Text>
                                    <Text>Conflict of Interest</Text>
                                    <Text>{ review.hasConflict ? 'YES' : 'NO' }</Text>

                                    <Text>Recommendation</Text>
                                    <Text>
                                        {review.recommendation && formatEnum(review.recommendation) }
                                    </Text>
                                </SimpleGrid>
                                <Button
                                    variant='outline'
                                    size='sm'
                                    borderRadius={0}
                                    onClick={() => setSelectedReview(selectedReview ? null : review)}>
                                    { selectedReview ? 'Close' : 'View' }
                                </Button>
                            </Stack>
                        ))}
                    { reviews.length === 0 && <Center>No reviews completed</Center> }
                    { selectedReview &&
                        <SeniorReviewForm
                            applicationId={selectedReview.applicationId}
                            seniorReview={selectedReview}
                            readonly />
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default ReviewsPreviewModal;
