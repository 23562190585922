import React, { useContext, useEffect } from 'react';
import { Button, Center, Heading, Icon, Image, Stack, Text, useToast } from '@chakra-ui/react';
import {
    useConfirmEmailMutation,
    useLogoutMutation,
    useResendConfirmationEmailMutation
} from '../../graphql/generated';
import LogoType from '../../assets/logos/logotype.svg';
import { useSearchParams } from 'react-router-dom';
import { PATHS } from '../../config';
import { AuthContext } from '../../components/Providers/AuthProvider';
import { GrLogout } from 'react-icons/gr';

const ConfirmEmailView = () => {
    const { account, setAuthValues } = useContext(AuthContext);
    const toast = useToast();
    const [params, setSearchParams] = useSearchParams();

    const [resendEmail, { data, loading }] = useResendConfirmationEmailMutation({
        onError: error => {
            toast({
                title: 'Something went wrong',
                description: error.message,
                status: 'error',
                duration: 5000,
                position: 'top',
                isClosable: true,
            })
        }
    });

    const [logout] = useLogoutMutation({
        onCompleted: () => {
            setAuthValues(null, false);
        }
    });

    const [confirmEmail] = useConfirmEmailMutation();


    useEffect(() => {
        const confirmToken = params.get('confirmToken');
        if (confirmToken && confirmToken !== '') {
            confirmUserEmail(confirmToken);
        }
    }, []);

    useEffect(() => {
        if (account && account.emailConfirmed) {
            setSearchParams({});
            window.location.replace(PATHS.HOME);
        }
    }, [account]);

    const confirmUserEmail = async (confirmToken: string) => {
        try {
            await confirmEmail({
                variables: { token: confirmToken }
            });
            toast({
                title: 'Email Confirmed',
                description: 'Your email has been confirmed successfully.',
                status: 'success',
                position: 'top',
                duration: 9000,
                isClosable: true,
            });
            params.delete('confirmToken');
            setSearchParams(params);
            window.location.replace(PATHS.HOME);
        } catch (e) {
            console.log(e);
        }
    }


    const onResend = async () => {
        await resendEmail();
    };

    return (
        <Center w='full' h='100vh' p={10}>
            <Stack spacing={10} textAlign='center' alignItems='center'>
                <Image src={LogoType} w={250} />
                { account && !account.emailConfirmed &&
                    <>
                        {!data
                            ? (
                                <>
                                    <Text>
                                        <b>You have not confirmed your email yet.</b>
                                        <br/>
                                        Please check your email for a confirmation link, including the spam folder.
                                    </Text>
                                    <Stack gap={1}>
                                        <Button
                                            variant='black'
                                            onClick={onResend}
                                            isLoading={loading}
                                            size='sm'
                                            w='full'>
                                            Resend confirmation email
                                        </Button>
                                        <Button size='xxs' colorScheme='red' onClick={() => logout()}>
                                            <Icon as={GrLogout} mr={2} />
                                            Logout
                                        </Button>
                                    </Stack>
                                </>
                            )
                            : (
                                <>
                                    <Heading size='sm' color='green.500'>Email sent!</Heading>
                                    <Text fontWeight='bold'>
                                        Please check your email for a confirmation link, including the spam folder.
                                    </Text>
                                </>
                            )}
                    </>
                }
            </Stack>
        </Center>
    );
}

export default ConfirmEmailView;