import React, { useCallback, useContext, useEffect, useState } from 'react';
import ViewContainer from '../../components/View/ViewContainer';
import {
    ContactsFilterInput,
    ContactsQuery,
    SortOrder,
    useContactsQuery,
    useSavePreferenceMutation
} from '../../graphql/generated';
import VitaTable from '../../components/VitaTable/VitaTable';
import TableConfig from './CrmTable.config';
import { AbsoluteCenter, Button, Text, useDisclosure } from '@chakra-ui/react';
import { AuthContext } from '../../components/Providers/AuthProvider';
import mergeColPreference from '../../utils/mergeColPreference';
import { ColumnConfig } from '../../components/VitaTable/types';
import CreateContactModal from './Modals/CreateContactModal';

type Contacts = ContactsQuery['contacts'];
const CONTACTS_PER_PAGE = 30;

const Crm = () => {
    const { account } = useContext(AuthContext);
    const [colConfig, setColConfig] = useState<ColumnConfig[]>(TableConfig.columns);
    const [contacts, setContacts] = useState<Contacts>({ contacts: [], count: 0 });
    const [input, setInput] = useState<ContactsFilterInput>({
        orderBy: { id: SortOrder.asc },
        take: CONTACTS_PER_PAGE,
        skip: 0
    });
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { data, loading, error, refetch } = useContactsQuery({
        variables: { input }
    });

    const [savePreference] = useSavePreferenceMutation();

    useEffect(() => {
        if (data && data.contacts) {
            setContacts(data.contacts);
        }
    }, [data]);

    const onSort = (colKey: string, order: string | undefined) => {
        const keys = colKey.split('.');
        const orderBy = keys.reduceRight((acc, key, index) => {
            if (index === keys.length - 1) {
                return { [key]: order };
            }
            return { [key]: acc };
        }, {});

        setInput({
            ...input,
            orderBy,
        });
    };

    const onFilter = useCallback((filters: any) => {
        setInput({
            ...input,
            where: { ...filters, }
        });
    }, [input]);

    const onPageChange = (page: number) => {
        setInput({
            ...input,
            skip: (page - 1) * CONTACTS_PER_PAGE
        });
    }

    const onPreferenceSave = async (key: string, value: any) => {
        const result = await savePreference({
            variables: {
                input: {
                    [key]: value
                }
            }
        });
        if (result.data?.savePreference) {
            setColConfig(mergeColPreference(TableConfig.columns, result.data.savePreference.crmPreference));
        }
    }

    const onSearch = (searchString: string) => {
        setInput({
            ...input,
            searchString: searchString !=='' ? searchString : undefined
        });
    };

    useEffect(() => {
        refetch();
    }, [input, refetch]);

    useEffect(() => {
        if (account?.preference?.crmPreference) {
            setColConfig(mergeColPreference(TableConfig.columns, account?.preference?.crmPreference));
        }
    }, [account]);

    return (
        <ViewContainer maxW='100vw'>
            { error &&
                <AbsoluteCenter>
                    <Text color='red'>{ error.message }</Text>
                </AbsoluteCenter>
            }
            <Button onClick={onOpen} variant='black' size='sm'>
                Create Contact
            </Button>
            <VitaTable
                colConfig={colConfig}
                data={contacts.contacts}
                loading={loading}
                onPageChange={onPageChange}
                onFilter={onFilter}
                onSavePreference={onPreferenceSave}
                onSort={onSort}
                preferenceKey='crmPreference'
                sort={input.orderBy}
                pageSize={CONTACTS_PER_PAGE}
                totalItems={contacts.count}
                onSearch={onSearch}/>
            <CreateContactModal isOpen={isOpen} onClose={onClose} />
        </ViewContainer>
    )
}

export default Crm;
