import { Flex, Link, Spacer, Text } from '@chakra-ui/react';
import React from 'react';

const Footer = () => {
    return (
        <Flex dir='row' w='100%' mt={10} mb={4} pt={10} borderTopColor='gray.200' borderTopWidth={1}>
            <Flex fontSize='sm' gap={3} color='black' fontWeight='bold'>
                <Link href='mailto:eleanor@vitadao.com'>Contact Support</Link>
            </Flex>
            <Spacer />
            <Text size='sm'>© {new Date().getFullYear()} VitaDAO</Text>
        </Flex>
    );
}

export default Footer;
