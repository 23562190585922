import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    HStack,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputLeftAddon,
    Spinner,
    Text,
    VStack
} from '@chakra-ui/react';
import { MdAdd, MdPerson } from 'react-icons/md';
import { Account, SearchAccountMutation, useSearchAccountMutation } from '../graphql/generated';
import CrmContact from './CrmContact';
import { debounce } from 'lodash';

type AccountResults = SearchAccountMutation['searchAccount'];

interface SearchAccountProps {
    onSelect: (account: Account) => void;
    onlyStaff?: boolean;
    isFlat?: boolean;
    isDisabled?: boolean;
    ignore?: number[];
}

const SearchAccount: React.FC<SearchAccountProps> = ({
    onSelect,
    isDisabled,
    ignore = [],
    isFlat = false,
    onlyStaff = true
}) => {
    const [results, setResults] = useState<AccountResults>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [searchContact, { loading }] = useSearchAccountMutation();

    const debouncedSearchContacts = useCallback(
        debounce(async (searchText: string) => {
            const { data } = await searchContact({
                variables: { searchText, onlyStaff }
            });
            if (data?.searchAccount) {
                setResults(data.searchAccount.filter(account => !ignore?.includes(account.id)));
            }
        }, 300),
        [searchContact, onlyStaff, ignore]
    );

    useEffect(() => {
        if (searchText.trim().length > 1) {
            debouncedSearchContacts(searchText.trim());
        }

        return () => {
            debouncedSearchContacts.cancel();
        };
    }, [searchText, debouncedSearchContacts]);


    const showResults = results && results.length > 0 && !loading;
    return (
        <Box position='relative'>
            <InputGroup mb={0}>
                <InputLeftAddon>
                    { loading && <Icon as={Spinner} /> }
                    { !loading && <Icon as={MdPerson} /> }
                </InputLeftAddon>
                <Input
                    value={searchText}
                    isDisabled={isDisabled}
                    placeholder='Search contacts...'
                    onChange={e => setSearchText(e.target.value)}/>
            </InputGroup>
            <Box
                bg='white'
                borderBottomRadius={3}
                borderWidth={isFlat ? 0 : 1}
                minH={isFlat ? 30 : 'auto'}
                maxH='300px'
                overflowY='auto'
                position={!isFlat ? 'absolute' : undefined}
                w='full'
                shadow={isFlat ? 'none' : 'md'}
                zIndex={isFlat ? 0 : 'dropdown'}>
                { !isFlat && showResults &&
                    <VStack align='stretch'>
                        {results?.map(account => (
                            <Text
                                key={account.id}
                                p={2}
                                cursor='pointer'
                                _hover={{ bg: 'gray.100' }}
                                onClick={() => {
                                    setResults([]);
                                    onSelect(account);
                                }}>
                                { account.contact?.fullName ?? account.email }
                            </Text>
                        ))}
                        { searchText !== ''
                            && searchText.length > 2
                            && (!results || loading || results.length === 0)
                            && (
                                <Text size='sm' color='gray.400' p={2}>
                                    { loading ? 'Searching...' : 'No results' }
                                </Text>
                            )
                        }
                    </VStack>
                }
                {isFlat &&
                    <HStack align='stretch' mt={3}>
                        {results?.map(account => (
                            <CrmContact
                                contact={account.contact}
                                key={account.id}
                                extension={
                                    <IconButton
                                        borderWidth={1}
                                        aria-label='remove'
                                        size='xs'
                                        colorScheme='green'
                                        icon={<MdAdd />}
                                        onClick={() => {
                                            onSelect(account);
                                        }} />
                                } />
                        ))}
                        {results?.length === 0 && <Text size='sm' color='gray.400' p={2}>No results</Text> }
                    </HStack>
                }
            </Box>
        </Box>
    )
}

export default SearchAccount;
