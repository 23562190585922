import { Stack, Text } from '@chakra-ui/react';

const QuestionAnswer = ({ question, answer }: { question: string, answer: string }) => (
    <Stack gap={0}>
        <Text fontWeight='bold'>{question}</Text>
        <Text mb={2} size='sm' color='gray'>{answer}</Text>
    </Stack>
);

export default QuestionAnswer;
