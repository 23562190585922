import { Box, Text } from '@chakra-ui/react';
import { ResponsiveFunnel } from '@nivo/funnel';
import React from 'react';
import { KpisQuery } from '../../graphql/generated';

const Funnel = ({ data }: { data: KpisQuery['kpis']['funnel'] }) => {
    const funnelData = data
        .filter(fn => fn.name !== 'REJECTED')
        .map(fn => ({
            id: fn.name,
            value: fn.count,
            label: fn.name.split('_').join(' ')
        })) ?? [];
    return (
        <Box w={400} h={200} p={3} pt={1} borderWidth={1}>
            <Text fontWeight='bold' color='gray.700'>
                Funnel
            </Text>
            <ResponsiveFunnel
                data={funnelData}
                margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                valueFormat='>-.1s'
                colors={{ scheme: 'pastel1' }}
                borderWidth={10}
                labelColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            3
                        ]
                    ]
                }}
                beforeSeparatorLength={10}
                beforeSeparatorOffset={20}
                afterSeparatorLength={10}
                afterSeparatorOffset={20}
                currentPartSizeExtension={10}
                currentBorderWidth={10}
                motionConfig='stiff'
                shapeBlending={0.40} />
        </Box>
    )
}

export default Funnel;