import { createStandaloneToast } from '@chakra-ui/react';
import {
    CheckCell,
    CommaStringCell,
    EditableTextCell,
    OptionCell,
    OrganizationCell,
    UrlCell
} from '../../components/VitaTable/Cells';
import React from 'react';
import { AccountRole, ContactsQuery, UpdateContactDocument, UpdateContactInput } from '../../graphql/generated';
import { apolloClient } from '../../config';
import { ColumnConfig } from '../../components/VitaTable/types';

type Contact = ContactsQuery['contacts']['contacts'][0];
const toast = createStandaloneToast();

const updateContact = async (contact: Contact, input: Omit<UpdateContactInput, 'id'>) => {
    if (!validateInput(input)) return;

    await apolloClient.mutate({
        mutation: UpdateContactDocument,
        variables: {
            input: {
                id: contact.id,
                ...input
            }
        },
        update: (cache, { data }) => {
            if (data && data.updateContact) {
                cache.modify({
                    id: cache.identify({id: contact.id, __typename: 'Contact'}),
                    fields: {
                        account(existingAccount) {
                            return {
                                ...existingAccount,
                                ...data.updateContact
                            };
                        }
                    }
                });
            }
        }
    })
}

const validateInput = (input: Omit<UpdateContactInput, 'id'>): boolean => {
    if (input.account?.role === AccountRole.ADMIN) {
        toast.toast({
            title: 'Cannot change role to admin',
            description: 'Contact support to change a role of a person to Admin.',
            status: 'error',
            position: 'top',
            duration: 9000,
            isClosable: true
        });
        return false;
    }
    return true;
}

const TableConfig: { columns: ColumnConfig[] } = {
    columns: [
        {
            key: 'id',
            label: 'ID',
            filterable: true,
            sortable: true,
            editable: false,
            type: 'number',
            render: (contact: Contact) => (
                <UrlCell
                    isExternal={false}
                    url={`/crm/contacts/${contact.id}`}
                    value={contact.id} />
            )
        },
        {
            key: 'fullName',
            label: 'Name',
            filterable: true,
            sortable: true,
            editable: true,
            render: (contact: Contact) => <EditableTextCell
                pad='20em'
                value={contact.fullName ?? '-'}
                onChange={name => updateContact(contact, { fullName: name.trim() }) } />
        },
        {
            key: 'email',
            label: 'Email',
            filterable: true,
            sortable: true,
            editable: true,
            render: (contact: Contact) => <EditableTextCell
                pad='20em'
                value={contact.email}
                onChange={email => updateContact(contact,
                    { email: email.trim(), account: { email: email.trim() } }
                ) } />
        },
        {
            key: 'account.role',
            label: 'Role',
            filterable: true,
            sortable: true,
            editable: true,
            type: 'set',
            options: Object.values(AccountRole).map(role => ({
                label: role.split('_').join(' '), value: role
            })),
            render: (contact: Contact) => (
                <OptionCell
                    isDisabled={contact.account?.role === AccountRole.ADMIN || !contact.account}
                    value={contact.account?.role}
                    options={Object.values(AccountRole)}
                    onChange={role => updateContact(contact, { account: { role } })} />
            )
        },
        {
            key: 'account.id',
            label: 'Has Account',
            sortable: true,
            editable: false,
            render: (contact: Contact) => <CheckCell isChecked={!!contact.account} isDisabled />
        },
        {
            key: 'account.isOnboarded',
            label: 'Onboarded',
            sortable: true,
            editable: true,
            render: (contact: Contact) => (
                <CheckCell
                    isDisabled={!contact.account}
                    isChecked={!!contact.account?.isOnboarded}
                    onChange={() => updateContact(contact, {
                        account: { isOnboarded: !contact.account?.isOnboarded }
                    })} />)
        },
        {
            key: 'phoneNumber',
            label: 'Phone',
            filterable: false,
            sortable: false,
            editable: true,
            render: (contact: Contact) => <EditableTextCell
                value={contact.phoneNumber}
                onChange={phoneNumber => updateContact(contact, { phoneNumber: phoneNumber.trim() })}/>
        },
        {
            key: 'institution',
            label: 'Organization',
            filterable: false,
            sortable: false,
            editable: false,
            render: (contact: Contact) => <OrganizationCell organization={contact.organizations?.[0]} />
        },
        {
            key: 'description',
            label: 'Description',
            sortable: true,
            editable: true,
            render: (contact: Contact) => <EditableTextCell
                pad='20em'
                value={contact.description}
                onChange={description => updateContact(contact, { description: description.trim() }) }/>
        },
        {
            key: 'discordId',
            label: 'Discord',
            filterable: true,
            sortable: true,
            editable: true,
            render: (contact: Contact) => <EditableTextCell
                value={contact.discordId}
                onChange={discordId => updateContact(contact, { discordId: discordId.trim() }) }/>
        },
        { // TODO fix editable
            key: 'twitterHandle',
            label: 'Twitter',
            filterable: true,
            sortable: true,
            editable: false,
            render: (contact: Contact) => (
                <UrlCell
                    value={contact.twitterHandle ? '@' + contact.twitterHandle : '-'}
                    url={contact.twitterHandle ? `https://x.com/${contact.twitterHandle}` : ''} />
            )
        },
        { // TODO fix editable
            key: 'vitaRoles',
            label: 'Vita Roles',
            sortable: false,
            editable: false,
            render: (contact: Contact) => <CommaStringCell value={contact.vitaRoles} />
        },
    ]
};

export default TableConfig;

